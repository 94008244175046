<template>
    <div
        v-loading="loading" class="table-content"
        element-loading-spinner="el-icon-loading"
    >
        <el-table
            ref="elTable" :data="tableData"
            :style="Object.assign({}, {width: '100%'}, tableStyle)" :size="size"
            :stripe="stripe" :max-height="maxHeight"
            :header-row-class-name="headerClass" :highlight-current-row="highlight"
            :show-summary="showSummary" :summary-method="getSummaries"
            :cell-class-name="cellClassName" :row-class-name="rowClassName"
            :default-sort="defaultSort" :row-key="handleReserve"
            :border="border" :class="{'table-border': border, 'new-table-style': newTableClass}"
            :fit="true"
            @sort-change="handleSortChange" @selection-change="handleSelectionChange"
            @select="handleSelect" @select-all="handleSelectAll"
        >
            <span slot="empty">{{ emptyText || $t("msn.infos.emptyText") }}</span>
            <template v-for="(head, index) in headers">
                <template v-if="head.field">
                    <template v-if="head.type">
                        <el-table-column
                            v-if="head.type === 'index'" :key="index"
                            :type="head.type" :fixed="head.fixed"
                            :index="head.indexMethod" :align="head.align || 'left'"
                            :label="useTranslate && head.title ? $t(head.title) : head.title"
                            :min-width="head.minWidth || '60px'"
                            :width="head.width"
                        />
                        <el-table-column
                            v-if="head.type === 'selection'" :key="'selection_' + index"
                            :fixed="head.fixed" :align="head.align || 'left'"
                            :reserve-selection="reserveSelection" :type="head.type"
                            :selectable="checkSelectable"
                        />
                        <el-table-column
                            v-if="head.type === 'expand'" :key="'expand_' + index"
                            :align="head.align || 'left'" type="expand"
                        >
                            <template slot-scope="{row}">
                                <div class="expand-container">
                                    <component
                                        :is="head.component" v-if="!!head.component"
                                        :row="row" :info="head"
                                    />
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column
                        v-else :key="head.field + index"
                        :fixed="head.fixed"
                        :align="head.align || 'left'"
                        :header-align="head.headerAlign || 'left'"
                        :label="useTranslate && head.title ? $t(head.title) : head.title" :sortable="head.sortable"
                        :prop="head.field"
                        :min-width="head.minWidth || '60px'"
                        :width="head.width"
                    >
                        <template slot="header">
                            <component
                                :is="head.headerComponent" v-if="!!head.headerComponent"
                                :info="head" :handler="(head.headerHandler && head.headerHandler()) || function () {}"
                            />
                        </template>
                        <template slot-scope="{row}">
                            <component
                                :is="head.component" v-if="!!head.component"
                                :row="row" :info="head"
                                :disabled="typeOf(head.disabled) === 'function' ? head.disabled(row) : false" :handler="(head.handler && head.handler()) || function () {}"
                            />
                            <div v-else :class="head.overflow ? 'overflow-eclipse' : ''">
                                <template v-if="!!head.format">
                                    <template v-if="head.translate">
                                        {{ $t(head.format(row)) }}
                                    </template>
                                    <template v-else>
                                        <!--bca-disable-->
                                        <div v-html="head.format(row)"></div>
                                        <!--bca-enable-->
                                    </template>
                                </template>
                                <span v-else>
                                    <template v-if="head.number">{{ row[head.field] | numberFormat(head.decimals || 2) }}</template>
                                    <template v-else>{{ row[head.field] }}</template>
                                </span>
                            </div>
                            <operation-panel
                                v-if="head.actions" :row="row"
                                :head="head"
                            />
                        </template>
                    </el-table-column>
                </template>
                <el-table-column
                    v-else
                    :key="head.field + head.title + index"
                    :fixed="head.fixed"
                    :label="head.title && head.title.includes('.') ? $t(head.title) : head.title"
                    :width="head.width || '80px'"
                    :min-width="head.width || '80px'"
                >
                    <template slot-scope="{row, $index}">
                        <div class="btn-wrapper">
                            <template v-for="action in filterActions(head.format(row))">
                                <span
                                    :key="action.name" class="btn-wrap"
                                    :style="action.style || null"
                                >
                                    <span v-if="action.type === 'tipIcon'" class="table-opt-btn">
                                        <el-popover
                                            :key="$index" :placement="action.placement || top - start"
                                            title :popper-class="action.popoverClass || 'max-height-500'"
                                            :visible-arrow="false" :width="action.popoverWidth || 150"
                                            :trigger="action.trigger || 'hover'"
                                        >
                                            <template v-if="action.component">
                                                <component
                                                    :is="action.component" v-if="!!action.component"
                                                    :row="row" :info="action"
                                                />
                                            </template>
                                            <!--bca-disable-->
                                            <div v-else v-html="action.content"></div>
                                            <!--bca-enable-->
                                            <template v-if="action.btnType === 'text'">
                                                <span
                                                    slot="reference" :style="action.iconStyle || null"
                                                    class="btn-text"
                                                >{{ $t(action.name) }}</span>
                                            </template>
                                            <template v-else>
                                                <i
                                                    slot="reference" :style="action.iconStyle || null"
                                                    :class="`msn-fa msn-fa-${action.icon}`"
                                                ></i>
                                            </template>
                                        </el-popover>
                                    </span>
                                    <template v-else>
                                        <el-tooltip
                                            effect="light" :content="$t(action.name)"
                                            placement="bottom-start" :visible-arrow="false"
                                            :disabled="action.icon === ''" popper-class="msn-icon-popertip"
                                        >
                                            <span class="table-opt-btn" @click="action.click(row)">
                                                <template v-if="action.btnType === 'text'">
                                                    <span
                                                        slot="reference" :style="action.iconStyle || null"
                                                        class="btn-text"
                                                    >{{ $t(action.name) }}</span>
                                                </template>
                                                <template v-else>
                                                    <i :style="action.iconStyle || null" :class="`msn-fa msn-fa-${action.icon}`"></i>
                                                </template>
                                            </span>
                                        </el-tooltip>
                                    </template>
                                </span>
                            </template>
                        </div>
                    </template>
                </el-table-column>
            </template>
        </el-table>
        <table-footer ref="tableFooter" :data="footerData"/>
        <div class="table-footer-bottom">
            <div>
                <div v-if="showDataRemind && total > 0" class="data-remind">
                    <!--bca-disable-->
                    <span v-html="$t('msn.dashboard.dateRemind',{...dataRemind})"></span>
                    <!--bca-enable-->
                </div>
            </div>
            <div v-if="showPagination && total > 0" class="pagination">
                <p style="font-size:12px">
                    <slot name="footerMsg"></slot>
                </p>
                <el-pagination
                    :current-page.sync="pageIndex" :page-size="pageSize"
                    :page-sizes="pageSizes" :layout="layout"
                    popper-class="pagination-select" :total="total"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                >
                    <span style="font-weight: normal; margin: 0 10px">
                        {{ $t("msn.button.total") }}&nbsp;
                        <b class="total">{{ total }}</b>
                        {{ ($t("msn.button.total") === "共" && " 条") || "" }}
                    </span>
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { Message } from 'element-ui';
import filters from '@/common/filters';
import TableFooter from '../TableFooter';
import OperationPanel from './operationPanel';
import { mapGetters } from 'vuex';
import api from '@/constants/api';
export default {
    name: 'PaginationTable',
    components: {
        TableFooter,
        OperationPanel
    },
    props: {
        border: Boolean,
        // 翻页是否启用select记忆
        clearReserveSelection: {
            required: false,
            default: false
        },
        pageSizes: {
            default() {
                return [10, 20, 50];
            }
        },
        isFinancial: {
            type: String,
            default: ''
        },
        // 请求地址
        url: {
            required: false
        },
        // 表头配置
        headers: {
            default() {
                return [];
            }
        },
        // 最大高度
        maxHeight: {
            default: 'auto'
        },
        // 过滤参数
        filterData: {
            default: null,
            type: Object
        },
        // 展示合计
        showSummary: {
            type: Boolean,
            default: false
        },
        // 展示高亮
        highlight: {
            type: Boolean,
            default: false
        },
        // 是否展示分页
        showPagination: {
            type: Boolean,
            default: true
        },
        // 是否呈现条纹
        stripe: {
            type: Boolean,
            default: false
        },
        // 表头样式
        headerClass: {
            default: ''
        },
        // 无数据文案
        emptyText: {
            default: ''
        },
        // 单元格样式
        cellClassName: {
            type: [Function, String],
            default: ''
        },
        // 是否需要翻译表头
        useTranslate: {
            default: true
        },
        // 是否支持多选
        multiple: {
            type: Boolean,
            default: false
        },
        // 是否使用选中
        checkable: {
            type: Boolean,
            default: false
        },
        // 默认选中行
        selected: {
            default: null
        },
        defaultSort: {
            default: () => ({
                prop: '',
                order: ''
            })
        },
        // 单选时，选择一个行字段作为选中的比较依据,开启单选功能时必填
        singleSelectKey: {
            type: String,
            default: ''
        },
        // 保留上次勾选状态
        reserveSelection: {
            type: Boolean,
            default: false
        },
        rowKey: {
            type: String,
            default: ''
        },
        // 表格样式
        tableStyle: {
            default: null,
            type: Object
        },
        rowClassName: {
            type: [Function, String],
            default: ''
        },
        size: {
            default: 'small'
        },
        newTableClass: {
            type: Boolean,
            default: false
        },
        showDataRemind: {
            type: Boolean,
            default: false
        },
        selectAble: {
            type: Function,
            default: (row) => true
        },
        isCurrentChangeGetData: {
            type: Boolean,
            default: true
        },
        layout: {
            type: String,
            default: 'prev, pager, next, jumper, slot, sizes'
        },
        // 默认单页展示数量
        defaultPageSize: {
            type: Number,
            default: 10
        }
    },
    data() {
        // 判断初始化时是否传入 defaultSort
        let isInitDefaultSort = false;
        if (this.defaultSort
            && typeof this.defaultSort.order === 'string'
            && typeof this.defaultSort.prop === 'string'
            && this.defaultSort.order !== ''
            && this.defaultSort.prop !== '') {
            isInitDefaultSort = true;
        }
        return {
            isInitDefaultSort,
            isGetData: false,
            // 表格数据
            tableData: [],
            // 数据条数
            total: 0,
            // 单页展示数量
            pageSize: 10,
            // 页码
            pageIndex: 1,
            // 排序key
            sortField_s: '',
            // 排序值
            sortVal_s: '',
            loading: false,
            // 合计数据
            summaryData: {},
            // 单选时，记录当前选择的行
            curSelectedRow: null,
            // 数据更新时间
            dataUpdateTime: '',
            // 全部ids数组
            allIds: [],
            footerData: [],
            dataRemind: '',
            selectedRows: [],
            isFullCheck: true
        };
    },

    computed: {
        ...mapGetters('common', {
            timezone: 'getTimezone'
        }),
        sortField: {
            get() {
                return this.sortField_s || this.defaultSort.prop;
            },
            set(val) {
                this.sortField_s = val;
            }
        },
        // 排序值
        sortVal: {
            get() {
                return this.sortVal_s || this.defaultSort.order;
            },
            set(val) {
                this.sortVal_s = val;
            }
        },
        // 暂存选中的行数据
        selectedRow: {
            get() {
                return this.selected;
            },
            set(val) {
                this.$emit('rowSwitch', val);
            }
        }
    },
    methods: {
        checkSelectable(row) {
            return this.selectAble(row);
        },
        // footerData
        getCreditFooterData() {
            console.log(this.isFinancial);
            if(this.isFinancial === 'income' || this.isFinancial === 'pay'){
                const summary = this.summaryData.map((item, index) => {
                    return {
                        label: `总计(${item.currency})`,
                        data: {
                            ...this.summaryData[index]
                        }
                    };
                });
                filters.currencyFinanceFormat(summary);
                return summary;
            } else if(this.isFinancial === 'creditLine'){
                const summaryKeys = Object.keys(this.summaryData);
                const summary = summaryKeys.map(key => {
                    const {totalCreditLine, totalCreditBalance, totalCouponBalance, totalCouponSpend, totalBalance, totalQuota} = this.summaryData[key];
                    return {
                        label: `总计(${key})`,
                        data: {
                            currency: key,
                            ...this.summaryData[key],
                            creditLine: totalCreditLine,
                            // creditLine: filter.getCurrencyDataFormat(key, twoDecimalPlaces(totalCreditLine),
                            creditBalance: totalCreditBalance,
                            couponBalance: totalCouponBalance,
                            couponSpend: totalCouponSpend,
                            balance: totalBalance,
                            availableBalance: totalQuota
                        }
                    };
                });
                // console.log(summary, '修改前');
                filters.currencyFinanceFormat(summary);
                // console.log(summary, '修改后');
                return summary;
            }
        },
        // 修改单条数据
        updateData(data, id) {
            if (data && id) {
                let index = this.tableData.findIndex(item => item[id] === data[id]);
                this.$set(this.tableData, index, data);
            }
        },
        getData(index, timeout) {
            this.isGetData = true;
            this.pageIndex = !isNaN(index) ? index : this.pageIndex;
            const pagination = {
                currentPage: this.pageIndex,
                pageSize: this.pageSize,
                // 没有排序不能传sortField，兼容服务端接口
                sortField: this.sortVal ? this.sortField : '',
                sortVal: this.sortVal ? (this.sortVal.includes('desc') ? 'desc' : 'asc') : ''
            };
            let params = Object.assign({}, this.filterData, pagination);
            this.loading = true;
            return new Promise((resolve, reject) => {
                this.$request(
                    {
                        url: this.url,
                        method: 'get',
                        timeout,
                        success(res) {
                            this.loading = false;
                            const data = res.data;
                            this.tableData = data?.detail || [];
                            this.dataUpdateTime = data?.dataUpdateTime || '';
                            this.total = +data?.total;
                            this.allIds = data?.all_detail || [];
                            if (this.showSummary) {
                                this.summaryData = data.summary || {};
                            }
                            if (this.isFinancial) {
                                // 财务页面
                                this.footerData = this.getCreditFooterData();
                            }
                            this.$emit('tableData', data);
                            this.$emit('getTotal', this.total);
                            // 每个列表结果添加请求耗时
                            this.tableData.wait_seconds = res.wait_seconds;
                            resolve(this.tableData);
                        },
                        error(err) {
                            this.loading = false;
                            // 获取数据失败后，将所有数据重置
                            this.tableData = [];
                            this.total = null;
                            this.$emit('getTotal', this.total);
                            if (this.showSummary) {
                                this.summaryData = {};
                            }
                            this.$emit('tableData', []);
                            if (err) {
                                err.wait_seconds = (err && err.data && err.data.wait_seconds) || 0;
                            }
                            reject(err);
                            // 先关闭所有的Message
                            Message.closeAll();
                            this.$message.warning(this.$t('msn.requestMsg.requestError'));
                        }
                    },
                    params
                );
            }).catch(err => {
                console.log(err);
            });
        },
        // 当前页码更新
        handleCurrentChange(index) {
            this.pageIndex = index;
            if(!this.clearReserveSelection) {
                this.clearSelection();
            }
            this.$emit('currenChange', index);
            if (!this.isCurrentChangeGetData) return;
            this.getData(index, 300000);
        },
        handlePrevClick(index){
            this.$emit('handlePrevClick', index);
        },
        handleNextClick(index){
            this.$emit('handleNextClick', index);
        },
        // 每页展示条数更新
        handleSizeChange(size) {
            this.pageIndex = 1;
            this.pageSize = size;
            this.clearSelection();
            if (!this.isCurrentChangeGetData) {
                return this.$emit('sizeChange', size);
            };
            this.getData(this.pageIndex, 300000);
        },
        // 排序
        handleSortChange({ column, prop, order }) {
            this.sortField = prop;
            this.sortVal = order;
            this.pageIndex = 1;
            this.clearSelection();
            // 【bug】运营分析页面进入后请求两次导致超时
            // 该组件设置在 defaultSort props 时 开发环境表现正常 但是部署后 会自动调用一次 handleSortChange 方法 导致请求2次接口。所以设定当实例没有执行过getData 方法时 handleSortChange 方法无法调用 getData 方法
            // 判断初始化时是否传入 defaultSort 如果不传入维持原装 传入才做处理
            if (this.isInitDefaultSort) {
                this.isGetData && this.getData(1, 300000);
            } else {
                this.getData(1, 300000);
            }
            this.$emit('sort', {sortField: this.sortField, sortVal: this.sortVal});
        },
        // 合计
        getSummaries({ columns }) {
            if (!this.showSummary) {
                return [];
            }
            // 财务以外 单条总计
            let sum = columns.map((col, index) => {
                // 根据第一列是否是index/selection，设置合计的展示列
                if (columns[0].type !== 'default' && index === 1) {
                    return this.$t('msn.infos.total');
                } else if (columns[0].type === 'default' && index === 0) {
                    return this.$t('msn.infos.total');
                }
                let curIndex = this.headers.findIndex(head => head.field === col.property);
                if (curIndex > -1 && this.headers[curIndex].showSummary) {
                    // 处理需要转换的数据
                    if (typeof this.headers[curIndex].showSummary === 'function') {
                        return this.headers[curIndex].showSummary(this.summaryData[col.property], this.summaryData);
                    }
                    return filters.numberFormat(this.summaryData[col.property], this.headers[curIndex].decimals || 2);
                }
                return '';
            });
            return sum;
        },
        typeOf(val) {
            return typeof val;
        },
        handleSelectAll(selection) {
            this.$emit('select-all', selection);
        },
        // 处理复选框勾选
        handleSelectionChange(val) {
            this.$emit('selection-change', val);
            this.selectedRows = val;
            const {curType, cookieKey} = this.$parent;
            // 只针对new dashboard
            if(curType && cookieKey) {
                const len = this.selectedRows.length;
                const checked = len === this.pageSize ? 1 : len === 0 ? -1 : 0;
                this.handleGa({action: 'full_check', label: `${curType}|${checked}|${len}` }, {cookieKey});
            }
            this.isFullCheck = true;
        },
        // 处理手动复选框勾选
        handleSelect(selection, row) {
            this.$emit('select', selection, row);
            const {curType, cookieKey} = this.$parent;
            // 只针对new dashboard
            if(curType && cookieKey) {
                const checked = selection ? 1 : -1;
                const len = this.selectedRows.length;
                this.handleGa({action: 'check', label: `${curType}|${checked}|${len}` }, {cookieKey});
            }
        },
        // 处理分页/排序 清除选中
        clearSelection() {
            if (this.reserveSelection) {
                this.$refs.elTable.clearSelection();
            }
        },
        handleReserve(row) {
            if (this.rowKey.length > 0) {
                if (this.rowKey.includes('+')) {
                    const arg = this.rowKey.replace(/ /g, '');
                    const arr = arg.split('+');
                    let vueKey = '';
                    for (let key of arr) {
                        vueKey += row[key];
                    }
                    return vueKey;
                } else {
                    return row[this.rowKey];
                }
            } else {
                return null;
            }
        },
        // filter show actions
        filterActions(actions) {
            const btns = (actions || []).filter(action => action.show);
            return btns;
        },
        handleGetTimezone(timezone){
            this.$request({
                method: 'get',
                url: api.getConversionUpdateTime,
                contentType: 'application/json; charset=UTF-8',
                success(res) {
                    console.log(res);
                    const data = res.data.detail || '';
                    // this.dataRemind = `${data.year + '/' + data.month + '/' + data.day + ' ' + data.hour + ':' + data.minute + ' ' + data.meridian + ' (' + data.timezone + ')'}`
                    this.dataRemind = {
                        year: data.year,
                        month: data.month,
                        data: data.day,
                        hour: data.hour,
                        minute: data.minute,
                        meridian: data.meridian,
                        timezone: data.timezone
                    };
                },
                error(err) {
                    console.log(err);
                }
            }, {timezone});
        }
    },
    mounted() {
        const {elTable, tableFooter} = this.$refs;
        tableFooter.init(elTable);
    },
    watch: {
        timezone: {
            immediate: true,
            handler(val) {
                console.log(val);
                if(this.showDataRemind){this.handleGetTimezone(val);}
            }
        },
        defaultPageSize: {
            immediate: true,
            handler(val) {
                this.pageSize = val;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.pagination {
    text-align: right;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex: 1;
    /deep/ .el-input__suffix {
        .el-select__caret {
            font-size: 14px;
            line-height: 14px !important;
        }
    }
}
</style>
<style lang="scss">
$transparent: transparent;
.el-table{
    &.table-border{
        th.is-leaf, td{
            border-right: 1px solid #EBEEF5;
            border-bottom: 1px solid #EBEEF5
        }
    }
}
.total {
    font-weight: 700;
}
.pagination-select .el-select-dropdown__item.selected {
    color: #2dd1ac;
}
.table-content .el-table__footer .cell {
    font-size: 13px;
    // 总计的字体颜色
    color: #53575B;
    letter-spacing: 0;
    font-weight: 700;
    background-color: $transparent;
}
.table-content .el-table__footer-wrapper tbody td,
.table-content .el-table__fixed-footer-wrapper tbody td {
    // 总计背景颜色改变
    background-color: #fafafa;
}
.table-content .el-table__footer .cell {
    font-size: 12px;
}
.btn-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .btn-wrap {
        display: inline-block;
        margin-right: 10px;
        height: 24px;
        font-size: 24px;
        .table-opt-btn {
            display: inline-block;
            height: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        &:last-child {
            margin-right: 0px;
        }
        .btn-text {
            font-size: 13px;
            font-weight: 700;
            color: #4a90e2;
        }
    }
}
.msn-select-row td {
    background-color: #eaf1f7;
}
tr.el-table__row{
    .btn-wrapper.fixed{
        display: none;
    }
   &.hover-row{
       .btn-wrapper.fixed{
           display: flex;
       }
   }
}
.cell {
    .el-checkbox__inner {
        border-color: #53575b;
        background: $transparent;
        line-height: 14px;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        background-color: #f2f6fc;
        border-color: #dcdfe6;
    }
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #2dd1ac;
        border-color: #2dd1ac;
    }
    .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #2dd1ac;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #53575b;
    }
}
// 新型table style
.new-table-style{
    //滚动条的滑块
    ::-webkit-scrollbar-thumb {
        background-color: rgba(196, 196, 196, 0.7);
        border-radius: 3px;
    }
    &.el-table .sort-caret.ascending{
        border-bottom-color: #8CA0B3;
    }
    &.el-table .sort-caret.descending{
        border-top-color: #8CA0B3;
    }
    &.el-table.table-border th.is-leaf{
        border-right: 1px solid rgba(140, 160, 179, 0.14);
        border-bottom: 1px solid rgba(140, 160, 179, 0.14);
        background: #EEF1F4;
    }
    &.el-table.table-border td {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
    .el-switch {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .el-switch__core {
        width: 20px;
        height: 10px;
    }

    .el-switch__core:after {
        top: 0;
        width: 8px;
        height: 8px;
    }
    .el-switch.is-checked .el-switch__core::after{
        margin-left: -8px;
    }
}
// 滚动条放至合计下面 暂不可用，造成样式错乱
// .el-table{
//   overflow: auto;
//     &::-webkit-scrollbar {
//         height: 6px !important;
//         width: 6px !important;
//     }
//     &::-webkit-scrollbar-thumb {
//         background-color: rgba(196, 196, 196, 0.7);
//         border-radius: 3px;
//     }
// }
// .el-table .el-table__body-wrapper,
// .el-table .el-table__header-wrapper,
// .el-table .el-table__footer-wrapper{
//   overflow: visible;
// }
// .el-table::after{
//   position: relative !important;
// }
.el-table--border{
    border: none;
}
.el-table__fixed{
    bottom: 0px !important;
}
.table-footer-bottom{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    .data-remind{
        color: rgba(83,87,91,0.5);
        font-size: 12px;
        line-height: 15px;
        margin-top: 20px;
    }
}
</style>
