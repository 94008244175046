/**
 * @file
 * @author  fangsimin
 * @date    2020-02-09 21:29:40
 * @last Modified by    fangsimin@baidu.com
 * @last Modified time  2021-06-25 15:18:42
 */

import state from './state-en';
import country from './country-en';
export default {
    permissions: {
        legalReTip: 'Country/Region in which the company is registered',
        legalPerson: 'Corporate Domicile',
        companyLegalPerson: 'Location of company legal Person',
        roleList: 'Role List',
        roleName: 'Role',
        rolePrivileges: 'Role privileges',
        announcementEmail: 'Announcement Email'
    },
    newDashboradBreadCrumbs: {
        account: 'Home',
        accounts: 'Account',
        companys: 'Company',
        campaign: 'Campaign',
        campaigns: 'Campaigns',
        ad: 'Ad',
        billCampaign: '活动'
    },
    menuData: {
        popInCreativeMakerTitle: 'popIn Creative Maker',
        receivablesTitle: 'Discovery Receivable',
        receivables: 'Discovery Receivable',
        monthlyBill: 'External Monthly Billing',
        monthlyBillTitle: 'External Monthly Billing',
        msgCenter: 'Receive Notifications',
        notification: 'Notification Center',
        notificationTitle: 'Notification Center',
        monthBillTitle: 'Monthly Bills',
        amConfigTitle: 'Settings',
        amConfig: 'Settings',
        minBid: ' To maximize campaign performance, target bid cannot be less than  {minBid} ',
        readCreateTitle: 'Get READ Code',
        discoveryReadCreateTitle: 'Get READ Code',
        brandReason: 'Brand is not safe to run on premium publishers, please contact your account manager for details',
        imgReason: 'This campaign was rejected due to non-compliant images or headline',
        campaignReason: 'This campaign was rejected due to non-compliant landing page',
        textOnImageReason: 'This campaign violates the ad policy. Please contact your account manager for more details.',
        msnRejectionReason: 'The creative was rejected due to non-compliance with some publisher\'s  policies.',
        sexualRelatedReason: 'The creative was rejected due to involvement in sexual-related violations.',
        tobaccoProductsReason: 'The creative was rejected due to involvement in violations related to minors.',
        textOnImageMediaType: 'MSN Review Tag Filter',
        tagReason: 'This campaign was rejected by premium publishers because the products being promoted fall into restricted advertising categories - （{tagName}）',
        uploadWaring: 'The platform service will go under regular maintenance for 6 hours in the near future, ',
        uploadWaringMore: 'more.',
        uploadText: 'The platform service will go under regular maintenance for 6 hours, starting between 10:00 pm on January 15, 2022 to 4:00 am on January 16, 2022 in the EST time zone. We recommend planning your campaign management accordingly.<br><br>Converted Time Zones:<br><br>UTC+8: 11:00 am on January 16, 2022  to 5:00 pm on January 16, 2022<br>UTC+0:  3:00 am on January 16, 2022 to 9:00 am on January 16, 2022',
        multiDimension: 'Multi-dimensional report',
        multiDimensionTitle: 'Multi-dimensional report',
        downList: 'Click here to download the document.',
        wrongList: 'The domains of {wrongList} you added are incorrect, and the incorrect domains will not take effect',
        domainSuccess: 'There are {correctLenght} successful hits in the domains you uploaded',
        searchSimilarBtnTitle: 'Bulk Review',
        searchSimilar: 'Bulk Review',
        stripeActionTitle: 'Stripe',
        PaymentManagement: 'Payment Management',
        PaymentManagementTitle: 'Payment Management',
        ROIAnalysisTitle: 'ROI Analysis',
        campaignTitle: 'New Campaign',
        dashboardTitle: 'Dashboard',
        new_dashboardTitle: 'New Dashboard',
        performance: 'performance',
        auditTitle: 'Review Ads',
        ecommerceToolTitle: 'E-commerce Tool',
        lPManagementTitle: 'LP Management',
        oPManagementTitle: 'OP Management',
        productManagementTitle: 'Product Management',
        domainManagementTitle: 'Domain Management',
        testTitle: 'Test',
        visualTestTitle: 'Viewable Test',
        visualTestBannerTitle: 'Banner Viewable Test',
        generateLocalPageWrapper: 'generate LP Wrapper',
        generateLocalPageWrapperTitle: 'generate LP Wrapper',
        checkStatusTitle: 'AD Callback',
        administrator: 'Administrator',
        financialTitle: 'Finance',
        monthlyBillRelease: 'Monthly Bill Release',
        monthlyBillSave: 'Monthly Bill Save',
        creditLineTitle: 'Credit Line',
        roiMgrTitle: 'ROI Report',
        incomeTitle: 'Receivable',
        payTitle: 'Payable',
        agencyManagementTitle: 'Agency Management',
        offlineTitle: 'Offline',
        applyTitle: 'Apply',
        permissionTitle: 'Authorization',
        accountTitle: 'Account List',
        bdOpenAccountTitle: 'BD Open Account',
        userTitle: 'User List',
        roleTitle: 'Role List',
        balance: 'Total Balance',
        adListTitle: 'Ad',
        dataReportTitle: 'Data Report',
        amTitle: 'Ad Operation',
        amDashboardTitle: 'Analysis',
        icafeTitle: 'Tracker',
        sampleTitle: 'Sample',
        adTooltipText: 'At least choose one campaign',
        pixel: 'Pixel',
        pixelTitle: 'Pixel',
        pixelConversion: 'Conversions',
        conversionListTitle: 'Conversion & Read',
        readListTitle: 'Read',
        conversionCreateTitle: 'New Conversion',
        pixelCreate: 'New Conversion',
        account: 'account',
        accounts: 'accounts',
        rechargeTitle: 'Billing',
        payActionTitle: 'Recharge',
        stripeAction: 'stripe',
        showbilling: 'Show Billing',
        helpCenterTitle: 'Help Center',
        gettingStartTitle: 'Getting Started',
        bestPracticeTitle: 'Best Practice',
        createAndManageCampaignTitle: 'Manage Campaign',
        priceAndBillTitle: 'Pricing & Billing',
        qaCenterTitle: 'Inner FAQ',
        faqTitle: 'FAQ',
        adPolicyTitle: 'Ad Policy',
        reportAndOptimizingTitle: 'Report & Optimizing ',
        diagnosisTitle: 'Diagnosis',
        bidAnalysisTitle: 'Bid Analysis',
        pageBidTitle: 'Ad Bid Configuration',
        monthlyReportTitle: 'Monthly Report',
        customerManagementTitle: 'Customer Management',
        wraningWrod: 'Please note：Bid is above normal network range.',
        cpcWraningWrod: 'You have adjusted CPC from {cpc} to {cpcData}, and the adjustment amplitude is higher than the normal level. Do you want to continue?',
        dayWraningWrod: 'You have adjusted the daily budget from {day} to {dayData}, the adjustment is beyond the normal level, please confirm whether to continue?',
        conversions: 'Conversions',
        conversionsTitle: 'Discovery Conversion',
        siteTip: 'Some site CPC under campaign are lower than {siteCpc} , they will be automatically adjusted to {siteCpc}',
        cpcTips: 'Please note that this is the account transaction currency',
        cpcTips2: ''
    },
    login: {
        emailLogin: 'Login to your account',
        thirdPartyLogin: 'Third-party account login',
        forgetPwd: 'Forgot Password',
        haveNoAccount: 'Don’t have an account?',
        register: 'Get started',
        email: 'Email',
        pwd: 'Password',
        loginText: 'Login',
        pwdBefore: 'Old Password',
        pwdAfter: 'New Password',
        confirmPwd: 'Confirm Password',
        account: 'Account Name',
        loginEmail: 'Login Email'
    },
    button: {
        subimtAudit: 'Submit for review',
        subimtAuditTitle: '(Review takes 1~3 working days)',
        backCheck: 'Check',
        confrimReject: 'Confirm',
        search: 'Search',
        addCreative: 'Add New Creative',
        trash: 'Delete',
        reachOfLandingPage: 'Reach of Landing Page',
        checkoutConversion: 'Checkout Conversion',
        budgetSpend: 'Budget Spend',
        offerConversion: 'Offer Conversion',
        purchaseConversion: 'Purchase Conversion',
        apiToken: 'Copy ApiToken',
        filterCols: 'Columns',
        export: 'Export',
        reset: 'Reset',
        apply: 'Apply',
        confirm: 'Confirm',
        cancel: 'Cancel',
        previous: 'Previous',
        next: 'Next',
        submit: 'Submit',
        audit: 'Appeal',
        revise: 'Fix Campaign',
        total: 'Total Items',
        edit: 'Edit',
        operate: 'Actions',
        detail: 'Detail',
        authorize: 'Authorize',
        ignore: 'Ignore',
        add: 'Add',
        query: 'Search',
        recreate: 'Recreate Campaign',
        createConversion: 'Create Conversion',
        createCampaign: 'Create Ads',
        UpdateConversion: 'Update a Conversion',
        archived: 'Archived',
        editMore: 'Edit More',
        delete: 'Delete',
        pass: 'Pass',
        copy: 'Copy',
        import: 'Import',
        create: 'Create',
        history: 'History',
        pageAudit: 'Review',
        preview: 'Preview',
        rename: 'Rename',
        sendReport: 'Send a report',
        save: 'Save',
        notSave: 'No Save',
        openEditor: 'Open In Editor',
        deleteTitle: 'The delete operation cannot be cancelled. Please click confirm to continue.',
        deletePage: 'Delete Page',
        innerStatus: 'Hibernation'
    },
    dashboard: {
        smbLearningCompletedTip: 'SmartBid learning phase is completed, CPC modification is not accepted now.',
        maxcvCpcTip: 'CPC modification is not accepted in MaxCV.',
        siteBlockAccountContentTip: 'Please be reminded that once you\'ve blocked a domain on account level, you\'re not allowed to unblock a domain on campaign level under the account.',
        siteBlockCampaignContentTip: 'Please be reminded that once you\'ve blocked a domain on campaign level. You cannot unblock the domain on account level, you need to unblock the domain on the campaign level.',
        viewSelected: 'View Selected',
        adReview: '{num} Ad selected',
        assetCampaign: '{num} Asset selected',
        assetReview: '{num} Campaign selected',
        accountReview: '{num} Account selected',
        campaignReview: '{num} Campaign selected',
        specifyCampaignTip1: 'Campaign id is incorrect',
        specifyCampaignTip2: 'This campaign has been inherited as a new activity with one click. Please select the activity again.',
        specifyCampaignTip3: 'This campaign\'s CV<3 in the past 6 days has failed to meet the standard for one-click inheritance. Please select another activity.',
        specifyCampaignTip4: 'There are no creative ideas with cv≥3 in the past 14 days under this campaign. Please select another activity.',
        specifyCampaignTip5: 'Country, platform, OS, and Browser settings are inconsistent, and the learning effect may not be good. Are you sure you want to continue submitting?',
        assignMatch: 'Manually specify',
        inheritCampaignId: 'Advertising plan ID ',
        inherit: 'One-click inheritance',
        matchType: 'Matching method',
        autoMatch: 'automatic matching',
        exceedLimitTip: 'The upper limit on the number of one-click inheritance activities that exceeds the account latitude will take effect',
        notInheritTip: 'does not meet the prerequisites for one-click inheritance. Should you continue to submit the remaining plans after elimination?',
        notInheritTip2: 'This advertising plan does not meet the one-click inheritance prerequisites',
        matchDegree: 'suitability',
        excellent: 'excellent',
        fine: 'good',
        noMatch: 'One-click inheritance asset not matched',
        matching: 'Matching',
        inheritEnd: 'Inheritance ended',
        dateHourTip: 'Hourly data with a maximum range of 14 days at a time.',
        ocpcphase: 'This campaign has entered the ocpc phase 2',
        ocpcNewPhase: 'Campaign is in Smart Bid mode',
        dateRemind: 'Conversion data was updated on:{month}/{data}/{year} at {hour}:{minute} {meridian} <span class="date-remind-color">({timezone})</span>',
        downloadTypeDay: 'Daily Report',
        downloadTypeSummary: 'Summary Report',
        accountFilter: 'Account Filter',
        customerSource: 'Customer Source',
        vcpm: 'vCPM',
        country: 'Country selection',
        readChart: 'READ 分析',
        readChartTitle: 'READ 报表',
        total: 'Total',
        account: 'Account',
        allAccounts: 'All Accounts',
        colManagement: 'Management',
        colCampaigns: 'Campaigns',
        colAds: 'Ads',
        colCost: 'Cost',
        colTrafficAcquisition: 'Traffic & Acquisition',
        colAudienceMetrics: 'AudienceMetrics',
        mcv: 'MCV',
        cv: 'CV',
        cvr: 'CVR',
        mcvr: 'MCVR',
        mcpa: 'MCPA',
        colTargeting: 'Targeting',
        dimensionFilter: 'dimensionFilter',
        conversiontype: 'conversionType',
        newConversiontype: 'Discovery Conversion (MCV)',
        mediagoCv: 'MediaGo Conversion (Lead&purchase)',
        currencyType: 'currencyType',
        colPerformance: 'performance',
        payPal: 'PayPal',
        StripeVisa: 'Stripe-Visa',
        StripeMastercard: 'Stripe-Mastercard',
        productName: 'product Name',
        otherPage: 'awaiting domain resolution',
        blocked: '(blocked on account level)',
        adListColSize: 'Ad Size Checkbox Of Setting Column',
        auditPublisher: 'Review Details Media Classification',
        domainSspColumn: 'Domain Tab ssp Column',
        dashboardDomainBlock: 'Domain Block',
        uniformlyFlag: 'Pacing',
        campaign: 'Campaign',
        campaignName: 'Campaign Name',
        status: 'Status',
        adStatus: 'Ad Status',
        campaignStatus: 'Campaign Status',
        spend: 'Spend',
        impression: 'Viewed Imp',
        click: 'Clicks',
        clickRate: 'CTR',
        startTime: 'Start Time',
        endTime: 'End Time',
        searchPlaceholder: 'Campaign Name',
        filterBtn: 'Filter',
        refreshBtn: 'Refresh',
        viewSelectedBtn: 'View Selected',
        editSelectedBtn: 'Edit Selected',
        review: 'Review Details',
        discarded: 'This campaign has been archived',
        reviewResult: 'Review Result',
        sppNotWork: 'The status of this campaign is abnormal and cannot be delivered. Please contact AM to fix it in time.',
        reviewAssetWaiting: 'The creative is awaiting for review, we will complete within 3 business days. Please wait',
        reviewAssetNotPass: 'This ads creative cannot be served due to a policy violation, click the button on the right to <i class="msn-fa msn-fa-tool-small"></i>&nbsp; learn more ',
        reviewAssetReason: 'The creative isn\'t able to run due to policy restrictions',
        reviewAssetReason1: 'The creative can be run, but can not be shown in all situations due to policy restrictions',
        reviewAssetNotPass1: 'The creative can be run, but can not be shown in all situations due to policy restrictions <br/>click button on the right side&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;to check the detail',
        discardedAsset: 'This asset has been archived',
        newReviewNotPass1: 'Some creatives under this campaign can be served，but due to policy restrictions，they cannot be displayed in all situations. <br/>Click the button on the right&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;to learn more',
        newReviewAssetWaiting: 'This ads creative is eligible to serve,<br/> but cannot be displayed in all situations due to policy restrictions',
        newReviewNotPass: 'Some creatives under this campaign cannot be served due to policy violations.<br/> Click the button on the right to&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;learn more',
        reviewWaiting: 'This ad is under review,<br/>we will finish review within 3 business days',
        reviewHasRemain: '{wait_audit_asset_num}/{asset_num} creatives is under review <br/>',
        reviewWaiting1: 'Some materials under the advertising plan are under review, <br/>which will not affect the normal delivery of the reviewed materials under the advertising plan. <br/>We will complete all reviews within 3 working days. Please wait',
        // reviewNotPass: 'This ad has been rejected,<br/>please contact your AM',
        reviewNotPass: 'Some ads in this campaign are rejected,<br/>click <i class="msn-fa msn-fa-tool-small"></i> button on the right side to check the detail',
        reviewNotPass1: 'Some ads in this asset are rejected,<br/>click <i class="msn-fa msn-fa-tool-small"></i> button on the right side to check the detail',
        auditDetails: 'Details',
        campaignFilter: 'Campaign Filter',
        adSetFilter: 'Ad Set Filter',
        adFilter: 'Ads Filter',
        datetimeRange: 'Start & End',
        and: 'and',
        selectDate: 'Select Date',
        on: 'On',
        off: 'Off',
        CPC: 'CPC',
        totalBudget: 'Total Budget',
        roas: 'ROAS',
        dailyBudget: 'Daily Budget',
        campaignDetails: 'Campaign Details',
        campaignCopy: 'Campaign Copy',
        adSetsDetails: 'Ad Set Details',
        adsDetails: 'Ad Details',
        objective: 'Objective',
        accountsName: 'Account Name',
        adCreator: 'Ad Creator',
        campaignEdit: 'Campaign Edit',
        adCopy: 'Ad Copy',
        adEdit: 'Ad Edit',
        creative: 'Creatives',
        utcStandard: 'UTC Standard Time',
        // audit
        assetTitle: 'Title',
        asset: 'Image & Creative Name',
        assetName: 'Asset Name',
        adInformation: 'Ad Information',
        accountName: 'Account Name',
        adName: 'Ad Name',
        submitTime: 'Submission Time',
        start: 'Start Time',
        userId: 'User ID',
        reviewItems: 'Review Items',
        pass: 'Pass',
        fail: 'Some ads are rejected',
        invalidReason: 'Reason',
        comments: 'Comments by reviewer',
        reviewer: 'Reviewer',
        reviewTime: 'Review Time',
        colCfgTitle: 'Columns',
        campaignId: 'Campaign Id',
        accountId: 'Account Id',
        colDimension: 'Dimension',
        colAdPerformance: 'Ad Performance',
        colContentPerformance: 'Content Performance',
        colStatus: 'Status',
        colStart: 'Start',
        colEnd: 'End',
        colSubmissionTime: 'Submission Time',
        colSpend: 'Spend',
        // colBalance: 'Campaign Balance',
        colBalance: 'Remaining Budget',
        colTotalBudget: 'Total Budget',
        colDailyBudget: 'Daily Budget',
        colConversion: 'Conversions',
        newColConversion: 'CV',
        cvViewContent: 'View Content',
        cvAppInstall: 'App Install',
        cvCompleteRegistration: 'Complete Registration',
        cvAddToCar: 'Add to Cart',
        cvAddPaymentInfo: 'Add Payment Info',
        cvSearch: 'Search',
        cvStartCheckout: 'Start Checkout',
        cvPurchase: 'Purchase',
        cvAddToWishlist: 'Add to Wishlist',
        cvLead: 'Lead',
        cvOther: 'Other',
        colDetail: 'Details',
        colDelivery: 'Delivery',
        detail: 'Details',
        colCpc: 'CPC',
        colAvgCpc: 'Avg.CPC',
        colVCpm: 'vCPM',
        colImp: 'Viewed Imp',
        colClick: 'Clicks',
        colCtr: 'CTR',
        delivery: 'Delivery',
        conversion: 'Conversions',
        conversions: 'Conversions',
        conversionRate: 'Conversion Rate',
        colConversionRate: 'Conversion Rate',
        costPerConversion: 'Cost Per Conversion',
        colConversionPrice: 'Cost Per Conversion',

        newConversion: 'CV',
        newConversions: 'CV',
        newConversionRate: 'CVR',
        newColConversionRate: 'CVR',
        newColAgency: 'Agency',
        newColAgencyInput: 'Search by Agency Name',
        newColAgencyName: 'Agency Name',
        newColAgencyId: 'Agency ID',
        newCostPerConversion: 'CPA',
        newColConversionPrice: 'CPA',
        colAccount: 'Account Info',
        campaignMultiEdits: {
            selectIdsNum: 'Editing 1 Campaign | Editing {num} Campaigns',
            selectAssetIdsNum: 'Editing 1 Creatives | Editing {num} Creatives',
            dialogTitle: 'Bulk Edit',
            status: 'Status',
            bid: 'Bid',
            perCampaign: 'per campaign',
            dailySpendCap: 'Daily Spend Cap',
            lifetimeSpendCap: 'Spend Cap',
            bulkEditSuccess: 'Successfully updated',
            assetBulkEditSuccess: 'Successfully updated',
            bulkArchivedSuccess: 'Successfully updated',
            assetBulkArchivedSuccess: 'Successfully updated'
        },
        accountList: {
            searchPlaceholder: 'Search by Account Name'
        },
        adList: {
            head: 'Ad List',
            adNum: 'Created: $num items',
            id: 'Ad Id',
            status: 'Status',
            fileName: 'File Name',
            name: 'Asset Name',
            image: 'Creative',
            size: 'Size',
            title: 'Title',
            brandName: 'Brand Name',
            spend: 'Spend',
            impression: 'Viewed Imp',
            ctr: 'CTR',
            url: 'URL',
            searchPlaceholder: 'Search Title/Ad Id/URL',
            click: 'Clicks',
            webUrl: 'Website URL',
            cpc: 'CPC',
            campaignId: 'Campaign Id',
            campaignName: 'Campaign Name',
            assetId: 'Asset ID',
            tableFooterMsg: '{n} ad(s) being created',
            searchAssetId: 'Search Title/Asset Id/URL',
            failedMsg: 'Failed'
        },
        domain: {
            ssp: 'SSP',
            domainName: 'Site Name',
            spend: 'Spend',
            impression: 'Viewed Imp',
            click: 'Clicks',
            ctr: 'CTR',
            vcpm: 'vCPM',
            acpc: 'Avg.CPC',
            suggestedCpc: 'Suggested CPC',
            cpc: 'CPC',
            searchPlaceholder: 'Search Domain',
            blockBtn: 'Block',
            blockDisableBtn: 'Block Disable',
            resumeBtn: 'Resume',
            resumeDisableBtn: 'Resume Disable',
            domainNameRemark: 'Please expect a 2-hour latency for domain-level performance'
        },
        domainTitle: 'Sites',
        // data report column setting
        colBreakDownBy: 'Break Down By',
        colCampaignAds: 'Campaign/Ads',
        suffixBreakDownBy: 'You can choose 2 at most',
        colData: 'Data',
        colEarning: 'Earning',
        yesterday: 'Yesterday',
        today: 'Today',
        last7days: 'Last 7 days',
        last30days: 'Last 30 days',
        archivedMsg: 'Are you sure to archive this campaign? (You cannot edit archived campaign, but data will be saved)',
        archivedMsg2: 'Are you sure to archive these campaign? (You cannot edit archived campaign, but data will be saved)',
        archivedAssetMsg: 'Are you sure to archive these creative? (You cannot edit archived creative, but data will be saved)',
        filterList: 'Campaign List Setting',
        adFilterList: 'Ad List Setting',
        hideArchivedCmps: 'Hide archived campaigns',
        hideArchivedAds: 'Hide archived ads',
        onOffBoth: 'Show all campaigns',
        adOnOffBoth: 'Show all ads',
        showAllCampaigns: 'Show all campaigns (including archived campaigns)',
        onlyArchivedCmps: 'Only show archived campaigns',
        showAllAds: 'Show all ads (including archived ads)',
        onlyArchivedAds: 'Only show archived ads',
        selectedCampaigns: '{num} selected',
        adInfo: 'for {num} campaign{s}',
        domainCampaignTip: 'for {num} campaign{s}',
        domainAccountTipAll: 'for all accounts',
        domainAccountTip: 'for {num} account{s}',
        mediaBlackWhiteListTitle: 'Media Account/Site Whitelist',
        mediaType: 'Publisher',
        selectedZipCode: 'Zipcode: {num} item{s}',
        campaignSetOcpc: 'OCPC',
        campaignSetEcpc: 'ECPC',
        bidByDomain: 'bid by domainCPC',
        suggested: 'Suggested CPC by site',
        readChartObj: {
            avgReadTime: 'Avg. READ Time',
            avgReadRate: 'Avg. READ %',
            totalReadTime: 'Total READ Time',
            readPart: 'READDistribution',
            readAnalysis: 'READ Analytics',
            pv: 'Pageviews',
            readRate: 'READ',
            readUserPart: 'READ User Behavior',
            tiaoReadPart: 'Skimmed',
            shuReadPart: 'Thoroughly Read',
            yueReadPart: 'Read',
            popInAvgReadRate: 'Inflow thru popIn Avg. READ %',
            inAvgReadRate: 'Ordinary inflow Avg. READ %',
            contentAnalysis: 'Analysis by Context',
            clickNumber: 'clicks',
            imp: 'Impressions',
            ctr: 'CTR',
            outMediaAnalysis: 'Analysis by External Content Consumption',
            normalReadContent: 'Categories being read in general',
            normalReadTimePeriod: 'General time of day when articles are being read',
            timePeriod: 'Time of day',
            category: 'Category'
        }
    },
    create: {
        maxCvTipFirst: '1.Suggested budget for new campaign is 5-10 times TargetCPA.',
        maxCvTipLast: '2.To prevent excessive CPA, it is recommended to increase the daily budget increase within 30% by one time per day maximum.',
        dataInheritance: 'Data Inheritance',
        dataInheritanceInternal: 'Data Inheritance（internal）',
        smartBidmaxcv: 'SmartBid Max CV',
        smartBidmaxcvTip: 'This bidding strategy is recommended if you want to get as many conversions as possible within your budget.',
        submitTitle: 'In creatives such as pictures and titles, there is no unauthorized infringement/appropriation of other people\'s names, photos, conversations or portraits, trademarks, works, etc.',
        requiredTitle: '(Required)',
        picSize: 'Image Size',
        displaySize: 'Banner Ads Upload',
        anySize: 'Any Size',
        recommendedSize: 'Recommended Display Ad Sizes',
        plsSelectOne: 'Please select one image size',
        plsSize: 'Please upload an image of [{size}] size',
        campaignCategory: 'Campaign Category',
        createCampaignSetps: 'Create A Campaign',
        campaignCategoryInfo: 'Define a category for your campaign content.The effectiveness of campaign optimization is highly related to the category, please select carefully.',
        addAds: 'Add Creatives',
        conversionGoal: 'Conversion Goal',
        basicSettings: 'General Settings',
        campaignTargeting: 'Campaign Targeting',
        marketingObjective: 'Marketing Objective',
        scheduleAndBudget: 'Schedule and Budget',
        smartBid: 'Smart Bid',
        smartBidLearning: 'Smart Bid (Learning)',
        smartBidLearningTip: 'MediaGo is learning how to best deliver your campaign. Performance may improve further. Please avoid editing your ad set during the learning phase. This phase uses Fixed Bid.',
        smartBidLearningCompleted: 'Smart Bid (Learning Completed)',
        smartBidLearningCompletedTip: 'The learning phase has complete for this campaign. Smart Bid is already in use.',
        uploadStatus: 'ステータス',
        audienceTip: 'Choose target audiences for the campaign. Selecting more audience types will increase your audience reach.',
        singleUpload: '手動入稿',
        bulkUpload: '一括入稿',
        uploadFile: 'インポート',
        uploadCsvFile: 'CSVファイルをインポート',
        downloadTemplateCsv: 'テンプレートをダウンロード',
        csvUploadTip: '下記リンクよりバルクシートのテンプレートをダウンロードしてください。<br>バルクシートをインポートする際に、テンプレート内の３行目の記入例を削除してください。<br>《入稿項目》<br>入稿URL、UTMトラッキングコード（必須ではない）、カテゴリー、タイトル、広告主名、画像。<br>バルクシートのフォーマットは以下です。<br>・ファイル形式：.csv<br>・⽂字コード︓UTF-8<br>・コンマ区切り',
        dragUpload: 'ドラッグ＆ドロップ',
        plsUploadCsv: 'CSVファイルをアップロードしてください',
        uploadRecord: '	インポートログ',
        csvRecordTip: 'キャンペーンあたり登録可能なクリエティブ(asset)数：最大100',
        uploadTime: 'インポート時間',
        uploadRemark: '備考',
        file: 'ファイル名',
        magnitudeEvaluation: 'Magnitude Evaluation',
        thirdPartyTracking: '3 rd Party Pixel',
        tagType: 'Tag Type ',
        eventType: 'Event Type',
        url_code: 'Add your rul / code here',
        click: 'Click',
        viewImperssion: 'Viewable Impression',
        trackingTips: 'You can add 3rd party tracking here to capture your campaign data and deliver it to outside platforms to facilitate your marketing objectives. Third-party tracking supports the same macros as UTM supports above more.  Please note: When using JavaScript tags, since some traffic does not support this type of tracking, there may be a difference between the tracked and the actual data.',
        domainTip: 'You can upload the required domain whitelists through CSV files, but it should be noted that these whitelists can only take effect when they match the existing domains of the platform, and the whitelists that are successfully matched are only valid for mobile targeted traffic.',
        jp: 'Japan',
        au: 'Australia',
        gb: 'United Kingdom',
        sg: 'Singapore',
        my: 'Malaysia',
        th: 'Thailand',
        hk: 'Hong Kong',
        tw: 'Taiwan',
        kr: 'Korea',
        id: 'Indonesia',
        preview: 'Preview',
        collapse: 'Collapse',
        expand: 'Expand',
        de: 'Germany',
        fr: 'France',
        it: 'Italy',
        es: 'Spain',
        at: 'Austria',
        br: 'Brazil',
        mx: 'Mexico',
        nl: 'Netherlands',
        pl: 'Poland',
        se: 'Sweden',
        be: 'Belgium',
        pt: 'Portugal',
        ch: 'Switzerland',
        cz: 'Czechia',
        no: 'Norway',
        dk: 'Denmark',
        hu: 'Hungary',
        deLang: 'German',
        itLang: 'Italian',
        frLang: 'French',
        // campaign
        createCampaign: 'Schedule and budget',
        accountName: 'Account Name',
        campaign: 'Campaign',
        objective: 'Objective',
        promotionObjective: 'Promotion target',
        completeConversion: 'Complete conversion',
        purchaseOrder: 'Purchase order',
        willingnessToInteract: 'Willingness to interact',
        fillForm: 'Fill out the form',
        signUp: 'Sign up',
        downLoadData: 'Download data',
        sendMessage: 'Send message',
        browseWebpage: 'browse webpage',
        reputation: 'Reputation',
        enhanceBrand: 'Enhance brand awareness',
        objectiveTraffic: 'Traffic',
        objectiveAwareness: 'Awareness',
        objectiveConversions: 'Conversions',
        objectiveLead: 'Lead',
        campaignName: 'Campaign Name',
        campaignStatus: 'Campaign Status',
        startAndEnd: 'Start & End',
        startTime: 'Start',
        endTime: 'End',
        setTime: 'Set Time',
        immediately: 'Run Immediately',
        indefinitely: 'Run Indefinitely',
        dayparting: 'Dayparting',
        selectDay: 'Select the days and hours you’d like to schedule your campaign.',
        dailySpendCap: 'Daily Spend Cap',
        dailyCapTip: 'Depending on available opportunities, you might spend less, or up to twice as much, on a given day.',
        lifetimeSpendCap: 'Spend Cap',
        lifetimeSpendCapTip: 'You can choose monthly or total campaign budget',
        lifetimeBudget: 'Lifetime Budget',
        monthlyBudget: 'Monthly Budget',
        pacing: 'Pacing',
        device: 'Device',
        os: 'OS',
        osv: 'win10 ad-targeting',
        userPackage: 'userPackage',
        domainwhitelist: 'Site Whitelist',
        moreBtn: 'more',
        impURL: 'impURL',
        osvTip: 'Your campaign will target Windows 10 by selecting this.',
        amountSmall: 'Please note:The platform-system-browser combination you are targeting has low traffic and may affect the effectiveness of your AD delivery. It is recommended to try another platform-system-browser combination',
        noAmount: 'Please note:There is no traffic for the targeted system-browser combination you selected, please reselect',
        platform: 'Platform',
        browser: 'Browser',
        gender: 'Gender',
        carrier: 'Mobile operator',
        // ad set
        search: 'Search...',
        English: 'English',
        Spanish: 'Spanish',
        Japanese: 'Japanese',
        All: 'All',
        lang: 'Language',
        allSelect: 'All',
        allSelect1: 'Select All',
        countryUsa: 'USA',
        countryCanada: 'Canada',
        adSetTitle: 'Targeting Settings',
        adSets: 'Ad Sets',
        adSetsName: 'Ad Set Name',
        adSetsStatus: 'Status',
        adFormat: 'Ad format',
        infopane: 'Infopane Native Ads',
        river: 'River Placement Ads',
        placement: 'Placement',
        size: 'Size',
        audience: 'Audience',
        audienceAsiaTip: 'Note: The selected audience has limited traffic in Asia',
        audienceAmericaTip: 'Note: The selected audience has limited traffic in Europe and the United States',
        industry: 'Category',
        industryPh: 'Please select category',
        industryInfo: `Please select at most two sets of phrases to describe the category of the product or service on the above landing page. Accurate description helps the algorithm automatically adapt the appropriate traffic to the campaign.
        * Please do not change the category arbitrarily.`,
        location: 'Location',
        state: 'State',
        us_state: 'State',
        ca_state: 'Region',
        au_state: 'State',
        gb_state: 'Region',
        jp_state: 'Region',
        tw_state: 'Region',
        id_state: 'Region',
        kr_state: 'Region',
        hk_state: 'Region',
        sg_state: 'Region',
        my_state: 'Region',
        th_state: 'Region',
        // 必须按照缩写_placeholder的格式填写 有多个地方用到
        us_placeholder: 'State/Zip Code',
        ca_placeholder: 'Region',
        au_placeholder: 'State',
        gb_placeholder: 'Region',
        jp_placeholder: 'Region',
        tw_placeholder: 'Region',
        id_placeholder: 'Region',
        kr_placeholder: 'Region',
        hk_placeholder: 'Region',
        sg_placeholder: 'Region',
        my_placeholder: 'Region',
        th_placeholder: 'Region',
        targetBid: 'Target Bid',
        contentCategory: 'Content Category',
        include: 'Include',
        exclude: 'Exclude',
        only: 'Only Include',
        zipcode: 'Zip Code',
        // 需要一些错误代码
        locationTip: 'P/lease fill in the information completely',
        locationTip1: ' is required',
        locationCheck: 'Only IP addresses in selected location',
        locationCheckTip: 'When checked, the target audience is whose IP addresses are in the selected location.',
        // ad
        createAds: 'Creative',
        adName: 'Ad Name',
        adAssetName: 'Creative Name',
        adStatus: 'Status',
        brandName: 'Brand Name',
        headline: 'Title',
        asset: 'Image',
        webUrl: 'Landing Page',
        upload: 'Upload',
        reUpload: 'Reupload',
        utmSuffix: 'Tracking',
        urlPreview: 'Variations Preview',
        /* eslint-disable-next-line */
        // imageDescription: `In order to increase CTR, it's recommended to upload images with a 16: 9 ratio and keep the object centered(maintain an appropriate distance from the image border).The maximum image size is 2MB; The image dimensions must be larger than 600x400 pixels.`,
        imageDescription: 'The maximum size of the picture is 2MB, and the minimum resolution: 600x400 pixels.',
        // CAMPAIGN_ID: '广告计划id',
        // AD_ID: '广告id',
        // AD_NAME: '广告名字',
        // PAGE_TYPE: '页面类型，DHP，HP等',
        // AD_STYLE: '广告样式，infopane，river等',
        // REQ_ID: '请求id，rid',
        // BID_TIME_STAMP: '广告填充时间戳（精度，以go支持的精度为主）',
        // TRACKING_ID: '跟踪id',
        // CPC: '此次广告的CPC'
        more: ' More',
        utmInfos: 'Enter your tracking code for campaign-level tracking. ',
        titleInfo: 'You can set dynamic macro in you title',
        titleTemplate1: 'This value will be replaced with the region (including states) the user is browsing from',
        titleTemplate2: 'This value will be replaced with the month. For example: January',
        utmInfoHeader: 'Recommended tracking code:',
        titleInfoHeader: ' Such as:',
        assetPreview: 'Asset Preview',
        adList: 'Ad List',
        adHead: {
            id: 'Ad Id',
            name: 'Asset Name',
            image: 'Image',
            title: 'Title',
            brandName: 'Brand Name',
            url: 'Landing Page',
            searchPlaceholder: 'Title/Asset Name'
        },
        assetAditionInfo: 'More traffic are coming soon...',
        closedText: 'Coming Soon...',
        delAdMsg: 'Are you sure to delete this asset? (The related ads will be archived but data will be saved)',
        mdyAdMsg: 'Are you sure to change this asset? (The related ads will be archived but data will be saved)',
        disableSizeMsg: 'We recommend you to keep all the sizes, our algorithm can pick the best performed ad to optimize.',
        importZipCode: 'Import Zip Codes',
        importDomainList: 'Import Domain Whitelist',
        selectCsv: 'Choose a CSV file',
        downloadTemplate: 'Download Template',
        previewTip: 'content recommendations come in many shapes and sizes. Placement and ad creatives (image, title, and brand name) arrangement differ according to the publisher site they appear on.',
        fixedCpc: 'Fixed CPC',
        notCutTip: 'If this option is not selected, the system will automatically cut the image according to the scale as required. After submission, it cannot be modified.',
        notCut: 'Do not use smart cropping.',
        OCPC: 'OCPC',
        ECPC: 'ECPC',
        ocpcTip: 'Your bid will adjust according to the conversions you track. Bid by domain will be invalid after selecting this function.[Supported Location: Japan]',
        cpaMaxChangeTip: 'Enter the maximum CPA multiplier. To optimize CPA effect, the system may exceed the upper limit during learning.',
        ecpcTip: 'System will dynamically adjust bid within CPC*Bid Multipliers to obtain better conversion. CPC of domain will be changed with this CPC ratio adjustments after selecting this function.[Supported Location: USA,Canada,United Kingdom,Australia]',
        cpaTypeTip: 'In order to enable system to get better and quick learning, it is recommended not to modify it once the CPA type is selected.',
        fisrtStepCpc: 'Phase 1 CPC',
        secondStepTargetCpa: 'Phase 2 Target CPA',
        secondStepTargetMcpa: 'Secod target MCPA',
        cpaMaxChange: 'Max CPA Multiplier',
        cpcChange: 'Bid Multipliers Range',
        targetCpa: 'Target CPA',
        cpaType: 'CPA Type',
        targetCpaRequired: 'Please fill in target CPA',
        targetCpanumbered: 'Target CPA must be a number',
        bidType: 'Bidding method',
        fixedBid: 'Fix CPC',
        smartBidText: 'OCPC',
        smartBidTargetCPA: 'SmartBid Target CPA',
        smartBidTargetCPATip: 'Important: Setting your Target CPA too low may lead to Cannot use Smart Bid.',
        smartBidNext: 'Smart Bid',
        smartBid: 'OCPC（Only support in Taiwan & Japan market at the moment）',
        phaseCpc: 'Phase 1 CPC',
        smartBidTips: '*Phase 1 - model learning: fixed CPC bidding, accumulation of conversion data\n*Phase 2 - Smart Bidding: Model Smart Bidding based on campaign goals, Target CPA and conversion data',
        smartBidNextTips: 'Use smart bidding strategy to maximize your campaign performance automatically.',
        smartTargetCpaTips: 'Target CPA: Target CPA for pages with embedded popIn CV pixel\nOur system will continue to optimize the delivery effect based on the target CPA you filled in.\nPlease fill in according to your expected target CPA, so as not to affect the effect of system optimization.',
        upToAdsLimitMsg: 'The maximum number of ads for this campaign is {limit}.',
        previewImgTip: 'Image ratio ',
        previewImgTitle: 'Your title here',
        previewImgName: 'Your brand name here',
        theThirdPartyPixelPrompt: 'You can add 3rd party tracking here to capture your  campaign data and deliver it to outside platforms to facilitate your marketing objectives.Third-party tracking supports the same macros as UTM supports above ',
        PixelPromptMore: 'more.',
        postback: 'Postback',
        postbackNull: 'None',
        mPostbackNull: 'None',
        postback91App: '91App',
        discovery: 'Discovery',
        mediago: 'MediaGo',
        postbackTip: 'This Postback setting applies to all ads in a campaign.If selected "None", would affect the accuracy of attribution.',
        assetInfo: 'Support uploading multiple images</br>Recommended aspect Ratio: 16:9</br>Image type：JPG，JPEG，PNG，GIF</br>Max image size: 1 MB</br>Reducing important details such as product，logo by 20% internally and avoiding proximity to the image edges is recommended.',
        assetSingleInfo: 'Recommended aspect Ratio: 16:9</br>Image type：JPG，JPEG，PNG，GIF</br>Max image size: 1 MB</br>Reducing important details such as product，logo by 20% internally and avoiding proximity to the image edges is recommended.',
        assetInfoTip: 'Max image size：1MB',
        createCampaignBtnText: 'Create Campaign',
        campaignNoAssetsTips: 'The Campaign has not uploaded creative yet',
        addAssetsBtnText: 'Add Creative'
    },
    monthBill: {
        agencyAccountName: 'Agency account name',
        monthBillTitle: 'Monthly Bills',
        settlementCurrency: 'Current settlement amount',
        settlementCurrencyRemark: 'Account current tax-included expenses(transaction currency) are exchanged according to the monthly exchange rate and the amount in the settlement currency',
        montlyExchangeRate: 'Montly exchange rate',
        settlementSpendWithTax: 'Current tax-included expenses',
        spendPlusTax: 'Current tax-excluded expenses',
        consumptionTax: 'Current consumption tax',
        consumptionTaxRemark: 'Consumption tax arising from Account current tax-excluded expenses in the current period',
        spendPlusTaxRemark: 'Account current tax-excluded expenses for the selected month',
        settlementSpendWithTaxRemark: 'Account current tax-included expenses for the selected month，Account current tax-included expenses=Account current tax-excluded expenses+Account current consumption tax',
        monthlyExchangeRate: 'Montly exchange rate',
        agentName: 'Agency account name',
        updataTitile: 'The previous months bill will be updated on the 3rd of the next month',
        monthlyExchangeRateRemark: 'Exchange rate conversion rules: Conversion is based on the daily average exchange rate of the last month. The exchange rate is based on The Federal Reserve System<a href="http://www.federalreserve.gov/releases/h10/hist/default.htm">http://www.federalreserve.gov/releases/h10/hist/default.htm</a>'

    },
    audit: {
        review: 'Review',
        auditStatus: 'Review Status',
        detail: 'Detail',
        reAudit: 'Reaudit',
        reviewAds: 'Review Ads',
        status: 'Status',
        inReview: 'In Review',
        limited: 'Limited delivery',
        preliminaryReview: 'In Preliminary Review',
        secondReview: 'In Second Review',
        rejected: 'Rejected',
        rejectedSelect: 'Some campaigns are rejected',
        manuallyApproved: 'Manually Approved',
        bdApproved: 'Baidu Approved',
        msApproved: 'Publisher Approved',
        bdRejected: 'Baidu Rejected',
        msRejected: 'Publisher Rejected',
        adId: 'Ad Id',
        campaignName: 'Campaign Name',
        accountName: 'Account Name',
        submittedTime: 'Submission Time',
        startTime: 'Start Time',
        adName: 'Ad Name',
        operate: 'Operate',
        startReviewing: 'Start Reviewing',
        searchPlaceholder: 'Search campaign name or account name',
        adInfo: 'Ad Info',
        userId: 'User ID',
        reviewItems: 'Review Items',
        pass: 'Pass',
        fail: 'Fail',
        reviewResult: 'Review Result',
        conclusion: 'Conclusion',
        approved: 'Approved',
        abnormal: 'Abnormal state',
        description: 'Description',
        comments: 'Comments',
        skip: 'Skip',
        submitAndNext: 'Submit and Next',
        reviewer: 'Reviewer',
        reviewTime: 'Review Time',
        reviewed: 'Reviewed',
        reviewedLines: 'Reviewed: {num} items',
        team: 'Team',
        result: 'Result',
        reason: 'Reason'
    },
    auditEn: {
        confirmation: 'confirmation',
        searchSimilar: 'Bulk Review',
        mediaType: 'Publisher',
        location: 'Location',
        language: 'language',
        languageTip: 'English creatives cannot be run on Spanish traffic.',
        audience: 'Audience',
        industry: 'Category',
        startTime: 'Start Time',
        brandName: 'Brand Name',
        headline: 'Title',
        asset: 'Image',
        landingPage: 'Landing Page',
        webUrl: 'Landing Page',
        mApproved: 'In Machine Review',
        preliminaryReview: 'In Preliminary Review',
        secondReview: 'In Second Review',
        rejected: 'Rejected',
        manuallyApproved: 'Manually Approved',
        bdApproved: 'Baidu Approved',
        msApproved: 'Publisher Approved',
        bdRejected: 'Baidu Rejected',
        msRejected: 'Publisher Rejected',
        operate: 'Operate',
        startReviewing: 'Start Reviewing',
        searchPlaceholder: 'Campaign/Account/Reviewer',
        adId: 'Ad Id',
        assetId: 'Asset Id',
        assetName: 'Asset Name',
        campaignName: 'Campaign Name',
        accountName: 'Account Name',
        submittedTime: 'Submission Time',
        assetSubmissionTime: 'Asset Submission Time',
        campaignSubmissionTime: 'Campaign Submission Time',
        reviewItems: 'Review Items',
        pass: 'Pass',
        fail: 'Fail',
        reviewResult: 'Review Result',
        conclusion: 'Conclusion',
        approved: 'Approved',
        description: 'Description',
        comments: 'Comments',
        skip: 'Skip',
        submitAndNext: 'Submit and Next',
        reviewer: 'Reviewer',
        reviewTime: 'Review Time',
        reviewed: 'Reviewed',
        reviewedLines: 'Reviewed: {num} items',
        team: 'Review Team',
        result: 'Review Result',
        reason: 'Reject Reason',
        status: 'Status',
        detail: 'Detail',
        review: 'Review',
        reAudit: 'Reaudit',
        adInfo: 'Ad Info',
        adName: 'Ad Name',
        operateRecord: 'Review Records',
        baiduBrainScanRecords: 'Baidu Brain Scan Records',
        refused: 'Refused',
        brainApproved: 'Approved',
        suspected: 'Suspected',
        others: 'Others',
        modify: 'Modify',
        baiduBrainScan: 'Baidu Brain Scan',
        baiduBrainScanResult: 'Baidu Brain Scan Result',
        baiduBrainRejectReason: 'Baidu Brain Reject Reason',
        baiduBrainReviewTag: 'Baidu Brain Review Tag',
        noData: 'No Data',
        lowLevelPornographic: 'Low Level Pornographic;',
        highLevelPornographic: 'High Level Pornographic;',
        lowLevelPolitics: 'Low Level Politics;',
        highLevelPolitics: 'High Level Politics;',
        lowLevelViolenceAndTerrorism: 'Low Level Violence And Terrorism;',
        highLevelViolenceAndTerrorism: 'High Level Violence And Terrorism;',
        lowLevelProhibited: 'Low Level Prohibited;',
        highLevelProhibited: 'High Level Prohibited;',
        lowLevelDisgustingPicture: 'Low Level Disgusting Picture;',
        highLevelDisgustingPicture: 'High Level Disgusting Picture;',
        submit: 'Submit',
        cancel: 'Cancel',
        confirm: 'Confirm',
        skipInfo: 'You will lose content, are you sure?',
        leaveInfo: 'You will lose content, are you sure?',
        reviewNotComplete: 'Review is uncompleted',
        requestError: 'Network error, please try again',
        discarded: 'Archived',
        pass1next: 'Pass and next',
        passAction: 'Pass',
        geoEdge: 'Geo Edge',
        displayAllImg: 'display all audited img',
        setSensitive: 'Sensitive Category',
        baiduBrain: 'Baidu Brain'
    },
    creditLine: {
        currencyConverterTip: 'Exchange rate conversion rules: Conversion is based on the daily average exchange rate of the last month. The exchange rate is based on The Federal Reserve System<a>http://www.federalreserve.gov/releases/h10/hist/default.htm</a>',
        tradingCurrencies: 'Transaction currency',
        accountName: 'Account Name',
        accountId: 'Account ID',
        status: 'Status',
        companyName: 'Company Name',
        availableBalance: 'Total Balance',
        availableBalanceRemark: 'Total Balance = Account <br />Balance + Credit Line + Total <br /> Coupon Consumption + Total <br /> Valid Coupon Balance<br>（Transaction currency）',
        balance: 'Account Balance',
        balanceRemark: 'Account Balance =<br>Accumulative Recharge-<br>Accumulative Expenditure <br>(cpc）（Transaction currency）',
        totalRecharge: 'Total Recharged',
        totalRechargeRemark: 'Accumulated Historical <br>Recharge Amount',
        creditLinetotalRechargeRemark: 'Accumulated Historical<br>Recharge Amount<br>（Transaction currency）',
        totalSpend: 'Total Spend',
        untaxedCopy: 'The total spend is the Cumulative tax-excluded expenses, which may be different from the Cumulative tax-included expenses in the invoice',
        totalSpendRemark: 'Cumulative Historical Spending Amount</br>（Transaction currency）',
        creditBalance: 'Credit Balance',
        creditBalanceRemark: 'When account balance + <br>Coupon total consumption >=0, credit balance = Credit Line; when account balance + <br>Coupon total consumption <0, credit balance = Credit Line + <br>account balance + Coupon <br>total consumption</br>（Transaction currency）',
        creditLine: 'Credit Line',
        creditLineRemark: 'The total additional credit limit we set for the account</br>（Transaction currency）',
        balanceNote: 'The total available limit of the current account is less than $200, please note',
        balanceNote1: 'To ensure that the advertisement is not affected, please recharge as soon as possible',
        filter: 'Filter',
        charge: 'Recharge',
        edit: 'Edit',
        editTitle: 'Credit Line Settings',
        chargeTitle: 'Recharge',
        currencyTitle: 'Settlement currency',
        tradingCurrencyLable: 'Transaction currency',
        contractualTitle: 'Contract Title',
        agent: 'Agent',
        userName: 'The operator',
        note: 'Note',
        operationTime: 'Operation time',
        amount: 'Recharge amount',
        chargeTime: 'Recharge time',
        editRecordTitle: 'Credit limit modification record',
        chargeRecordTitle: 'Recharge record',
        opt: 'operate',
        couponBalance: 'Total effective coupon balance',
        couponBalanceRemark: 'Accumulation of valid coupon<br> balance<br>（Transaction currency）',
        couponSpend: 'Total coupon consumption',
        couponSpendRemark: 'Total coupon consumption（Transaction currency）',
        couponTitle: 'Coupon',
        couponAmount: 'Coupon amount',
        effectiveTime: 'Effective time',
        expireTime: 'Expiration',
        offline: 'Offline',
        offlineTip: 'After confirmation, the coupon will be permanently offline and cannot be restored. Confirm?',
        profitRate: 'Profit Margin (expected)',
        profitRateRemark: 'Manually set the expected profit margin for this account. <br/>The profit margin will affect the actual bid.',
        profitRateTitle: 'Profit Margin Settings',
        profitRateRecordTitle: 'Profit margin modification record',
        curProfitRate: 'Current profit margin',
        transactionCurrency: 'Transaction currency',
        settletmentCurrency: 'Settlement currency',
        couponCost: 'Current Coupons',
        adjustmentAmount: 'Adjustment Amount',
        receivablesWithTax: 'Receivables With Tax',
        adjustmentAmountRecord: 'Adjustment Amount records',
        receivablesWithoutTax: 'Receivables Without Tax',
        currentCoupons: 'Current Coupons',
        selectMonth: 'Select a month',
        amountSetting: 'Adjustment Amount Setting',
        setAmount: 'Set an adjustment amount',
        searchText: 'Search Account/Company/Agency Name',
        searchMonthlyText: 'Search Account/Agency Name',
        amountRecords: 'Adjustment Amount records',
        spendPlusTaxRemark: 'Accumulated untaxed spend of the account during the selected period',
        couponCostRemark: 'Total account Coupon consumption during the selected period',
        adjustmentAmountRemark: 'The total adjusted amount of the account during the selected period （without tax)',
        consumptionTaxRemark: 'VAT generated on current untaxed spend (adjusted)',
        monthlyExchangeRateRemark: 'Average monthly exchange rate in settlement month',
        receivablesWithTaxRemark: 'Accumulated account spend including tax during the selected period',
        receivablesWithoutTaxRemark: 'Account receivable withou tax (transaction currency) during the selected period',
        currentUntaxedSpend: 'Current untaxed spend',
        settlementCurrency: 'Settlement currency',
        rebate: 'Rebate',
        monthText: 'Previous payments cannot be modified after the 3rd day of each month',
        adjustedText: 'Please enter the complete amount to be adjusted',
        changeRecords: 'Records',
        remarkNote: 'Note',
        changeDate: 'Selected month',
        amountEdit: 'Adjustment Amount Edit',
        release: 'Release'
    },
    roiMgr: {
        serviceType: 'Service Type',
        category: 'Industrial Classification',
        companyName: 'Company Name',
        accountName: 'Account Name',
        accountId: 'Account ID',
        income: 'Baidu\'s Current Revenue',
        incomeRemark: 'Baidu\'s Current Income = <br>Account Current Consumption-Current Coupon Cost',
        spend: 'Account Current Consumption',
        spendRemark: 'Accumulated spend of the <br>account within the selected<br> time period',
        cost: 'Current Traffic Cost',
        costRemark: 'The money we paid to msn in <br>the selected time frame (CPM)',
        profit: 'Current Profit',
        profitRemark: 'Current Profit = Baidu Current <br>Revenue-Current Traffic Cost',
        profitRate: 'Profit Margin (actual)',
        profitRateRemark: 'Profit Rate = Current Profit / <br>Baidu Current Income',
        impression: 'Impression',
        click: 'Clicks',
        filter: 'Filter',
        coupon: 'Current Coupon Cost',
        couponRemark: 'Total coupon consumption <br>during the selected time',
        rebateRatio: 'Rebate Ratio',
        rebateRatioRemark: 'On the ROI report page, both the agency and its advertisers show the rebate ratio.',
        source: 'Customer Source',
        sourceCompany: 'Customer Source Company',
        accountType: 'Account Nature',
        rebate: 'Current Rebate Cost',
        rebateRemark: 'Current rebate cost = Baidu current revenue x rebate ratio',
        launchDays: 'Run Days',
        averageDailySpend: 'Daily Consumption',
        maximumDailySpend: 'Maximum Daily Consumption'
    },
    // 可视化测试
    visualTest: {
        adminEmailCode: 'The administrator obtains the email verification code'
    },
    financiaIncome: {
        companyName: 'Company Name',
        accountId: 'Account ID',
        accountName: 'Account Name',
        timezone: 'Account Time Zone',
        email: 'Account Holder\'s Email',
        submitTime: 'Creation Time',
        spend: 'Account Current Consumption',
        spendRemark: 'Accumulated spend of the account within the selected time period',
        coupon: 'Current Coupons',
        couponRemark: 'Total coupon consumption during the selected time',
        receivablesRemark: 'Financial Receivable = Account Current Consumption-Current Coupon Cost',
        recharge: 'Current Recharge Amount',
        receivables: 'Financial Receivable',
        businessManager: 'Business Manager'
    },
    financiaPay: {
        accountType: 'Account Nature',
        source: 'Customer Source',
        accountId: 'Account ID',
        rebateRatio: 'Rebate Ratio',
        rebateRatioRemark: 'On the financial payable page, the rebate ratio of advertisers under the agency is 0 (not shown here).',
        companyName: 'Company Name',
        accountName: 'Account Name',
        timezone: 'Account Time Zone',
        email: 'Account Holder\'s Email',
        cost: 'Current Traffic Cost',
        costRemark: 'The money we paid to msn in the selected time (CPM)',
        businessManager: 'Business Manager',
        rebate: 'Current Rebate Cost',
        sspName: 'Media',
        rebateRemark: 'Current rebate cost (payable): The cumulative sum of current rebate costs of each advertising account under the agent. The rebate amount of the consumption generated by the advertising account under the agent shall be paid to the agent company itself'
    },
    dataReport: {
        account: 'Account',
        device: 'Device',
        os: 'OS',
        platform: 'Platform',
        browser: 'Browser',
        audience: 'Audience',
        industry: 'Category',
        location: 'Location',
        // country: 'Country',
        country: 'Location',
        stateregion: 'State/Region',
        language: 'Language',
        publisher: 'Publisher',
        campaignName: 'Campaign',
        assetName: 'Creative Name',
        adsize: 'Image Size',
        spend: 'Spend',
        viewedImp: 'Viewed Imp',
        click: 'Clicks',
        ctr: 'CTR',
        colAvgCpc: 'Avg.CPC',
        colVcpm: 'vCPM',
        colDate: 'Date',
        conversions: 'Conversions',
        conversionRate: 'Conversion Rate',
        conversionPrice: 'Conversion Price',
        colConversionPrice: 'Conversion Price',
        colConversionRate: 'Conversion Rate',
        colCtr: 'CTR',
        needAccount: 'Select an account',
        needCampaign: 'Select a campaign',
        timePre: 'Data Last Updated',
        conversionTimePre: 'Conversion Data Last Updated',
        timeAfter: '(Report timezone is as same as your ad account)',
        targetBid: 'Target Bid',
        fixedBid: 'Fixed CPC',
        OCPC: 'OCPC',
        ECPC: 'ECPC'
    },
    amDashboard: {
        // 运营配置
        currentSsp: 'Currently serving at SSP',
        // black&white
        kwTitle: 'Account-level media blacklist/whitelist Keyword tag setting',
        kwSetting: 'Blacklist/Whitelist setting',
        kwTag: 'Keyword tag setting',
        filterType: 'Filter Type',
        // blackList: 'Black List',
        // whiteList: 'White List',
        insertList: 'Insert keyword list',
        selectCsv: 'Select csv file',
        editDate: 'Editing Date',
        operationId: 'Operation ID',
        objectName: 'Object name',
        insert: 'Insert',
        edit: 'Edit',
        importWhite: 'Import keyword Whitelist',
        importBlack: 'Import keyword Blacklist',
        kwWarning: 'Format error, please upload keywords again',
        dialogTitle: 'Upload results',
        dialogSubTitle: '{num} keywords have been uploaded',
        downloadCurrentCSV: 'download current CSV',

        companyName: 'Company Name',
        // country: 'Country',
        country: 'Location',
        language: 'Language',
        date: 'Date',
        roas: 'ROAS',
        // remainingBudget: 'Campaign Balance',
        remainingBudget: 'Remaining Budget',
        taboolaBind: 'Taboola Ad Pull',
        configableCpc: 'Minimum CPC Available',
        accountListSetting: 'Account List Setting',
        hideClosedAccount: 'Hide closed account',
        hideBalanceAccount: 'Hide account with total balance <= 0',
        campaignName: 'Campaign Name',
        accountName: 'Account Name',
        status: 'Status',
        campaignStatus: 'Campaign Status',
        spend: '0 Time Rev',
        impression: 'Viewed Imp',
        click: 'Clicks',
        clickRate: 'CTR',
        startTime: 'Start Time',
        endTime: 'End Time',
        CPC: 'CPC',
        colAvgCpc: 'Avg.CPC',
        totalBudget: 'Total Budget',
        dailyBudget: 'Daily Budget',
        budget: 'Budget',
        iImpression: 'Impression',
        cost: 'Cost',
        iCtr: 'iCTR',
        iCpm: 'iCPM (revenue)',
        viewableRate: 'Viewable Rate',
        vCtr: 'vCTR',
        colCfgTitle: 'Columns',
        campaignId: 'Campaign Id',
        accountId: 'Account Id',
        colStatus: 'Status',
        colStart: 'Start',
        colEnd: 'End',
        colSpend: '0 Time Rev',
        colTotalBudget: 'Total Budget',
        colDailyBudget: 'Daily Budget',
        colConversion: 'Conversions',
        colDetail: 'Details',
        colDelivery: 'Delivery',
        colOther: 'Other',
        detail: 'Details',
        colCpc: 'CPC',
        colVCpm: 'vCPM (revenue)',
        colImp: 'Viewed Imp',
        colClick: 'Clicks',
        colCtr: 'vCTR',
        delivery: 'Delivery',
        conversion: 'Conversions',
        colConversionRate: 'Conversion Rate',
        colConversionPrice: 'Conversion Price',
        colIimpression: 'Impression',
        colCost: 'Cost',
        colIctr: 'iCTR',
        colIcpm: 'iCPM (revenue)',
        colViewableRate: 'Viewable Rate',
        blackWhiteConfig: 'Black white config',
        colProfitRate: 'Benefit Rate',
        colSpendRate: 'Budget Consumption Ratio',
        colIncome: '0 Time Rev',
        colLastSpend: '-1 Time Rev',
        colCompareRate: 'Rev ROC',
        colAmResponsible: 'AM Responsible',
        spendRemark: 'Income Comparison = (Current Period Income-Last Period <br>Income)/Last Period Income',
        spendRateRemark: 'Budget Consumption Ratio: <br>The total consumption of the<br> advertisement during the<br> selected time/the total daily<br> budget of the advertisement <br>during the selected time. Use <br>the latest daily budget.',
        profitRateRemark: 'Profit Margin (actual) = (Current Income-Current cost) / Current Cost',
        totalCampaign: 'All Campaigns',
        abnormalCampaign: 'Abnormal Campaigns',
        campaignOptLog: 'Campaign Operation Log',
        campaignOptCfg: 'Campaign Operation Configuration',
        pageIdBlockCfg: 'Campaign PageId Blacklist Configuration',
        campaignAlertCfg: 'Campaign Exception Reminder',
        colIndustry: 'Category',
        tradeCategory: 'Industry',
        icafe: {
            newItem: 'New Tracker'
        },
        serviceRecord: 'Advertiser Operation Record',
        minCpcRecordText: 'The CPC limit is modified from {old} to {new}',
        accountBlackWhiteConfigTitle: 'Account-level media blacklist/whitelist',
        campaignBlackWhiteConfigTitle: 'Campaign-level media blacklist/whitelist',
        sellerIdBlackWhiteConfigTitle: 'Account-level Seller ID blacklist/whitelist',
        sellerIdBlackWhiteConfigTips: '*The SellerID blacklist/whitelist will take effect, only when the Account-level media blacklist/whitelist is removed',
        rulesType: 'Type',
        configIndex: 'Level',
        configObject: 'Object Name',
        blackList: 'Blacklist',
        whiteList: 'Whitelist',
        mediaSiteGroup: 'Site Group',
        mediaAccountAndSite: 'Media Account/Site',
        mediaAccount: 'Media Account',
        mediaSite: 'Site',
        selectedConfig: 'Current blacklist/whitelist',
        rulesTypeTip: 'Add blacklist/whitelist across type is not allowed. Please delete all whitelist before adding any blacklist, and delete all blacklist before adding any whitelist.',
        deleteAll: 'Delete all',
        sitePlaceholder: 'You can add a batch of site for the above media accounts. Please enter site name, separated by semicolon;. Add suffix * to create a fuzzy match, otherwise you crete an exact match.',
        mediaPlaceholder: 'Supports adding accounts in batches for selected media accounts. Please enter account id, separated by semicolons.',
        sellerPlaceholder: 'Supports adding Seller ID in batches for selected accounts. Please enter Seller ID, separated by semicolons.',
        creativeIdPlaceholder: 'Content, separated by two semicolons.',
        cancel: 'Cancel',
        confirm: 'Confirm',
        operationReport: 'Operation Report',
        operationTime: 'Operation Time',
        userName: 'User Name',
        actionDetails: 'Action Details',
        USA: 'USA',
        Canada: 'Canada',
        UnitedKingdom: 'United Kingdom',
        Australia: 'Australia',
        Japan: 'Japan',
        Korea: 'Korea',
        Taiwan: 'Taiwan',
        Indonesia: 'Indonesia',
        Singapore: 'Singapore',
        Malaysia: 'Malaysia',
        Thailand: 'Thailand',
        HongKong: 'Hong Kong',
        from: 'From',
        to: 'To',
        tips1: 'This OCPC optimization and creation of ad bids OCPC linkage, please carefully.',
        tips2: '1) If the campaign bid is OCPC, advertisers can perceive it and use it with caution.',
        tips3: '2) If the campaign bid is a fixed bid, this OCPC configuration is not perceptible to advertisers. If the advertiser modifies the fixed bid to OCPC, this target CPA will be used as the default target CPA of OCPC.',
        // 通知中心
        notificationCenter: 'Notification Center',
        createNotification: 'Create a New Notification',
        notificationTitle: 'Title',
        recipients: 'Recipient',
        noticeDate: 'Date',
        notificationContent: 'Content',
        confirmNotice: 'Confirm',
        previousNotifications: 'Previous Notifications',
        sender: 'Sender',
        submittedTime: 'Submitted time',
        dataRange: 'Date Range',
        notificationStatus: 'Status',
        changeStatusTip: 'Once the status is off, it cannot be turned on',
        msgTitleRequired: 'Title is required',
        msgRecipientRequired: 'Select a recipient',
        msgLanguageRequired: 'Select a language',
        msgContentRequired: 'Content is required',
        msgTipsTitle: 'Tips',
        msgStatusChangeTips: 'Once the status is turned off, it cannot be turned on.\nAre you sure to turn off this notification?',
        close: 'Close',
        delete: 'Read',
        noMessage: 'No new notifications',
        moreMessage: 'More notification',
        toNoticeList: 'Back to notifications list',
        noticeDetail: 'Notifications detail',
        closeDetail: 'Return',
        noticeListTitle: 'Notifications list'
    },
    pixel: {
        companyName: 'Company Name',
        selectDimensionTip: 'Please select account/company',
        accountPlaceholder: 'Please select the account',
        companyPlaceholder: 'Please select the company',
        pixelCreateTipHeader: 'Configure Initial Pixel, MCV Event Pixel and CV Event Pixel for conversion tracking under the selected account/company',
        pixelCreateTipContent: 'If you want to embed pixel for only one account, please select the account name. If you want to embed pixel for multiple accounts under the same company, please select the company name, so that you do not need to embed the code multiple times.',
        createRead1: 'Please copy the following code and place it before the </ body> tag.',
        createRead2: 'We will check the operation after the code is installed, so please contact your account manager at least 2 days before campaign starting.',
        createRead3: 'Embed HTML Code',
        getREADCode: 'Get READ Code',
        codeType: 'Code Type',
        urlBased: 'URL',
        eventBased: 'Event',
        type: 'Type',
        accountName: 'Account Name',
        pixelName: 'Initial Pixel Name',
        conversionType: 'Type',
        status: 'Status',
        includeInTotalConversion: 'Important Conversion',
        lastReceived: 'Last Received',
        lastModified: 'Last Modified',
        conversionName: 'Conversion Name',
        eventName: 'Event Name',
        eventNameAppend: 'Use a unique and descriptive name for your event. This name will appear in the code snippet and will help you or your webmaster recognize it when you implement the code in your site.',
        category: 'Category',
        optionalVal: 'Conversion Value',
        optionalValAppend: 'This is used to add information like conversion price to your conversion.',
        conversionWindow: 'Conversion Window',
        isIncludeTatalConversion: 'Important Conversion',
        isExcludeCampaigns: 'Exclude Audience from all campaigns',
        urlBasedTip: 'URL Based: Track every time a user visits a website whose url meets some certain conditions',
        pixelReadTip: 'For example, the conversion path:<br>Click Ads --> Content Page --> Product Page (advertiser website) --> Thankyou Page (Conversion).<br>★ You need to enter the following domains, and all pages need to embed the initialization code<br>1. Content page domain<br>2. Product Page domain',
        eventBasedTip: 'Event Based: Track every time someone completes a specific action by using js code. eg: add some product to the cart',
        urlCondition: 'URL Conditions',
        urlConditionAppend: 'Include traffic whose url meets any of the following conditions:',
        conversionProperties: 'Conversion Properties',
        conversionNameAppend: 'Use a unique name to help you distinguish the meaning of the conversion.',
        conversionCategory: 'Conversion Category',
        conversionCategoryAppend: 'Choose the category to which this conversion belongs',
        importantConversion: 'Important Conversion',
        importantConversionTipTitle: 'If you choose yes,',
        importantConversionTip1: 'The Conversion data are included in the convesion column of Dashboard.',
        importantConversionTip2: 'The algorithm will optimize according to this conversion data',
        eventCategory: 'Choose Event Category',
        eventCategoryAppend: 'Choose the event category that will trigger the conversion.',
        embedHtmlCode: 'Embed HTML Code',
        embedHtmlCodeAppend: 'Add the event code  to the relevant html when the corresponding event is triggered.',
        pixelInitialCode: 'Pixel Initial Code',
        pixelInitialInfo: `Phase 2: Add this code to the head tag of the HTML code of the landing page and redirect pages.<br>
        Please be reminded that adding the initial pixel is the first step to install conversion tracking, and you still need to click the New Conversion button and follow the intructions to install conversion tracking.`,
        copy: 'Copy to Clipboard',
        create: 'New Conversion',
        condition: 'Condition',
        or: 'OR',
        newCondition: 'New Condition',
        newConditionAppend: 'Enter the URL or part of the URL of the destination page on which a visit should count as a conversion.',
        confirmCreate: 'Create Conversion',
        initPixel: 'View MediaGo Initial Pixel',
        initPixelPopin: 'View Popin Initial Pixel',
        mcvCvOther: 'MCV/CV/Other Event',
        initPixelTab: 'Initial Pixel',
        viewCode: 'View Code',
        conversionEdit: 'Edit Conversion',
        step1: 'Step 1',
        step1Desc: 'Add Pixel Initial Code',
        step1Desc1: '(If you already add initial code, you can skip this step).',
        step2: 'Step 2',
        step2Desc: 'Choose Type',
        step3: 'Step 3',
        step3Desc: 'Properties',
        step4: 'Step 4',
        pixelInitialInfo1: 'Phase 2: Add this code to the head tag of the HTML code of the landing page and redirect pages.',
        headerCode: 'Phase 1: Please enter the domains of the landing page and redirect pages',
        headerCode1: '(※If the path includes different domains, please enter all domains in the next line)',
        pixelInitialTitle1: 'Phase 1: Please enter the domains of the landing page and redirect pages<br>(※If the path includes different domains, please enter all domains in the next line)',
        expandCode: 'Expand & create and copy the initial code',
        // new pixel conversions
        createNewPixel: 'Create New Pixel',
        howInstallPixel: 'How to install Pixel?',
        downloadDoc: 'Download File',
        pixelCreateSubTitle: 'Add initial pixel, MCV event pixel and CV event pixel for conversion tracking under selected accounts.',
        selectAccount: 'Choose Account',
        configInitPixel: 'Initial Pixel Setting',
        addPixelCode: 'Add Code',
        mcvEventPixel: 'MCV Event Pixel',
        hasConfigText: 'Added:',
        pixelNumUnit: '',
        cvEventPixel: 'CV Event Pixel',
        otherEventPixel: 'Other Event Pixels',
        addInitialPixel: 'Add Initial Pixel',
        initCodeTips: '*Please ensure the initial pixel is accurately installed in the </head> tag of html code on every page of your website. If the path includes different domains, please enter all domains in the next line',
        pixelCodeTips: '*Please ensure the initial pixel is accurately installed in the </head> tag of html code on every page of your website.',
        pixelPlaceholder: 'If the advertising links are LP1, LP2 and OP, their domain names are a.com, b.com and c.com respectively, please proceed as follows:\na.com\nb.com\nc.com',
        getInitialPixel: 'Get initial Pixel',
        editUpdateText: 'Edit',
        initPixelDone: 'I have finished adding the initial pixel code',
        saveAndCopy: 'Copy and save',
        setMcvEventPixel: 'MCV Event Pixel Setting',
        mcvEventTips: '*Please ensure that the MCV event pixel is accurately installed in the head tag of html code on the next page of the first landing page.',
        getMcvEventPixel: 'Get MCV Event Pixel',
        copyMcvPixelTips: '*Please install this code in the head tag of html code on next page of the first landing page.',
        mcvPixelDone: 'I have finished adding MCV event pixel code',
        setCvEventPixel: 'CV Event Pixel Setting',
        cvEventCodeTips: '*Please ensure the CV event pixel is installed exactly on the page or button where the promotion goal is accomplished.',
        getCvEventPixel: 'Get CV Event Pixel',
        copyCvPixelTips: '*Please copy this code on the page or button corresponding to the completion of the promotion target',
        cvPixelDone: 'I have finished adding CV event pixel code',
        setOtherEventPixel: 'Other Event Pixels Setting',
        otherEventTips: '*Please ensure the event pixel is installed in the relevant html when the corresponding event is triggered.',
        copyOtherPixelTips: '*Please install this code to the relevant html when the corresponding event is triggered.',
        getOtherEventPixel: 'Get Other Event Pixels',
        otherPixelDone: 'I have finished adding other event pixels',
        trackingCodeTips: '*Embed html code Add the event code to the relevant html when the corresponding event is triggered.',
        manageExistPixel: 'Manage existing Pixels',
        initPixelTips: 'Please install the initial pixel in the </head> tag of the html code on every page of your ad.',
        mcvPixelTips: 'Please install the MCV event pixel in the </head> tag of the second landing page html code.',
        cvPixelTips: 'Please install the CV event pixel on the page or button where the promotion goal is accomplished.',
        hasDeprecated: '（Archived）'
    },
    // 权限部分
    permissionMgr: {
        accountId: 'Account Id',
        accountStatus: 'Account Status',
        accountName: 'Account Name',
        companyName: 'Company Name',
        type: 'Company Type',
        email: 'Account Holder\'s Email',
        payMethod: 'Payment Method',
        adMethod: 'Payment Method',
        timezone: 'Time Zone',
        createAccount: 'Add Account',
        inviteLink: 'Account Opening Invitation Link',
        copyLink: 'Copy Invitation Link',
        dailyCmpCount: 'Account API Campaign Upper Limit',
        dailyCmpCount2: '',
        openDailyCmpCount: 'Open Account API Single-Day Creation Campaign Upper Limit',
        dailyLimit: 'When enabled, an account can create 500 campaign per day using the API, and a maximum of 10 assets can be created under a single campaign.',
        roleMgr: {
            detail: 'Details',
            edit: 'Authorization',
            del: 'Delete'
        }
    },
    openAccount: {
        stepOne: 'Ad Account Application',
        stepTwo: 'Binding User Account',
        stepThree: 'Application Completed',
        workEmailTip: ' (this will be used as your username)',
        billingEmail: 'Billing Email',
        billingEmailSymbol: '\';\' must be half-width character',
        billingEmailLength: 'No more than 1000 characters',
        billingEmailTip: 'Fill in the email that hope to receive account invoice. Please use \';\' to separate each email.',
        currency: 'Currency',
        // 币种提示
        currencyTitle: 'Choose your settlement currency, once selected, it cannot be modified.',
        // 交易币种提示
        tradingCurrencyTitle: 'Choose your transaction currency, once selected, it cannot be modified.',
        // 合同提示
        contractualTip: 'Please enter the contractual subject.',
        accountTitle: 'Ad Account Application',
        accountTitleDesc: 'The following information will only be used for account application, and we promise that we will not expose your message.',
        accountTitleDesc1: 'The information you provide below will only be used for account application. ',
        accountTitleDesc2: 'As this will directly impact billing, please enter the organization details carefully and accurately to reflect official registered documents.',
        accountName: 'Ad Account Name',
        accountEmail: 'Ad Account Admin Email',
        totalBudget: 'Spend Cap',
        payMethod: 'Payment Method',
        prepayment: 'Prepayment',
        delivery: 'Cash on delivery',
        timezone: 'Reporting Timezone',
        adMethod: 'Account Type',
        selfService: 'Self-service',
        managed: 'Managed',
        companyName: 'Organization Name',
        registerAddr: 'Registration Address',
        operateAddr: 'Business Address',
        website: 'Organization Website',
        userTitle: 'Bind User Account',
        userTitle1: 'Apply for an user account',
        addExistAccount: 'Add an existing user account',
        registAccount: 'Register a new user account',
        userTitleDesc: 'You can bind your ad account to many user accounts. Fill in the following information to add or register your MediaGo user account.',
        // country: 'Country',
        country: 'Location',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Work Email',
        jobTitle: 'Job Title',
        phone: 'Phone Number',
        submitAppend: 'By clicking "Apply", you agree to the MediaGo',
        privacyLink: 'Privacy Policy',
        terms: 'Terms',
        logout: 'Log out',
        del: 'Delete',
        add: 'Add',
        apply: 'Apply',
        formAppendLink: 'Privacy Policy',
        info: 'Thanks for your application, we will contact you as soon as possible.',
        info1: '',
        footer1: 'If you have any other questions, please contact: ',
        footer2: 'We will reply you as soon as possible.',
        invalidToken: 'The link you provided is invalid. Please make contact with AM in your organization to get the correct link.',
        inviteMsg: ' is inviting you to open an ad account on MediaGo'
    },
    permission: {
        p1: 'Please agree to MediaGo\'s privacy policy and terms to continue.',
        password: 'Password',
        newPassword: 'New Password',
        timeZone: 'Reporting Timezone',
        inputVCode: 'Verification Code',
        vCode: 'Verify', // Send Me a Verification Code
        forgotPasswordInputVCode: 'Verification Code',
        forgotPasswordVCode: 'Verify',
        title: 'Advertiser application',
        subtitle: 'Only two steps to enter our platform.',
        userId: 'User Id',
        accountType: 'Account Type',
        quizz1: '1. Does your company has an Ad Account?',
        yes: 'YES',
        no: 'NO',
        quizzyes: '2. Please click "Send Email" button to apply permission.',
        quizzno: '2. Please click "Send Email" button to open an Ad Account and apply permission.',
        sendEmail: 'Send Email',
        email: {
            title: 'User Application',
            title1: 'Ad Account Application and User Application',
            divider: '===============================================================',
            bodyTitle1: 'MediaGo for MSN User Application',
            bodySubtitle1: '(Please complete all the fields)',
            bodyTitle2: 'MediaGo for MSN Ad Account Application and User Application',
            bodySubtitle2: '(Please complete all the fields)',
            account: {
                title: '[Ad Account Application]',
                company: '* Organization Name: ',
                registerAddr: '* Organization Address: ',
                operationAddr: '* Billing Address: ',
                type: '* Organization Type: ',
                officialSite: '* Company Website: ',
                position: '* Job Title:',
                owner: '* Ad Account Admin Email:',
                ownerInfo: '(Please type your email address)',
                accountNum: '* Ad Account Number:    1 ',
                accountNumInfo: '(You can only apply 1 ad account)',
                accoutName: '* Ad Account Name:  ',
                totalCap: '* Spend Cap:    $ ',
                totalCapInfo: '(Currency: USD)',
                chargeType: '* Billing Method:  ',
                chargeTypeInfo: '(Prepayment or Cash on delivery)',
                manner: 'Account Type:   ',
                mannerInfo: '(Self-Service/ Managed)'
            },
            permission: {
                title: '[User Application]',
                name: '* Nick Name: ',
                nameInfo: '(Please type your full name and position in your organization. Eg: Duan Ying - PM)',
                userId: '* User ID: ',
                accountType: '* User Account Type: ',
                userType: '* User Type:  ',
                userTypeInfo: '(Advertiser/Agency)',
                email: '* User Email Address:  ',
                emailInfo: '(Please type your email address)',
                company: '* Organization Name: ',
                adAccount: '* Ad Account Name:',
                adAccountInfo: '(Please type the ad account name of your organization.)'
            },
            result: {
                info: 'Thanks for your application, we will contact you as soon as possible.',
                info1: '',
                footer1: 'If you have any other questions, please contact: ',
                footer2: 'We will reply you as soon as possible.'
            }
        },
        emailReport: {
            create: 'Automatic Reporting'
        },
        formTitle: 'Apply Your MediaGo Account',
        formDesc: `The short form below will help us set up your first campaign.
        we will contact you by following information.`,
        formAppend: 'By clicking “Apply”, you agree to the MediaGo',
        formAppendLink: 'Privacy Policy',
        // country: 'Country',
        country: 'Location',
        currency: 'Currency',
        firstName: 'First Name',
        lastName: 'Last Name',
        formEmail: 'Work Email',
        formEmailTip: 'this will be used as your username',
        submitProtocol: 'Yes! Send me personal advice to improve my campaign performance.',
        timeZoneTip: 'Select the time zone to manage your campaign. Your setting cannot be modified.',
        currencyTip: 'Choose your settlement currency, once selected, it cannot be modified.',
        organizationName: 'Organization Name',
        companySite: 'Company Website',
        jobTitle: 'Job Title',
        phone: 'Phone Number',
        companyNameandtips: 'Company Registered Name (fill in when registering)',
        apply: 'Get Started Today',
        createAccount: 'Creat Account',
        tologinText: 'Already have a {platform} account?'
    },
    currencySpecies: {
        dollar: 'USD（$）',
        bahtDollar: 'THB（฿）',
        japaneseDollar: 'JPY（￥）',
        RMB: 'CNY（CNY ￥）',
        taiwanDollar: 'TWD（NT$）',
        HKDollar: 'HKD（HK$）',
        singaporeDollar: 'SGD（S$）',
        wonDollar: 'KRW（₩）',
        malaysianRupiah: 'MYR（RM）',
        indonesianRupiah: 'IDR（Rp）'
    },
    validations: {
        campaignObjective: 'Promotion target is required',
        couponAmount: 'Please enter the Coupon amount to be set',
        effectiveTime: 'Select the effective time',
        validNumber: 'Please type valid number here',
        validDecimal: 'Must have no more than 3 decimal positions',
        simpleRequired: 'Required',
        jobTitle: 'Please enter a valid job title',
        firstName: 'Please enter a valid first name',
        lastName: 'Please enter a valid last name',
        required: '$field is required',
        number: 'Please type valid number here',
        money: 'Please type valid amount of money',
        moneyNum: 'Target Bid must be a number',
        moneyJpy: 'The daily spend cap must be an integer',
        moneyNumJpy: 'Target Bid must be must be an integer',
        moneyTotalJpy: 'The total budget must be an integer',
        numberBeyond: 'Cannot exceed 1,000,000,000',
        numberBeyondJpy: 'Minimum daily spend cap is ￥2,000/day',
        dailyCapBeyond: 'Minimum daily spend cap is {money}/day',
        cpcBeyond: 'To maximize campaign performance, target bid cannot be less than {money}',
        checkCurrencyMaxCPC: 'To maximize campaign performance, target bid cannot be higher than {money}',
        dailyOverTotal: 'Daily spend cap cannot be higher than spend cap',
        dailyOverTotal2: 'Daily budget cannot be higher than total budget',
        dailyOverTotal3: 'Total budget cannot be lower than daily budget',
        dailyOverTotal4: 'Minimum daily budget is $20/day',
        dailyOver20: 'Minimum daily spend cap is $20/day',
        targetBidOverDailyJpy: 'Target bid must be an integer',
        lifetiemUnderDaily: 'Spend cap cannot be lower than daily spend cap',
        targetBidOverDaily: 'Target bid cannot be higher than daily spend cap',
        targetBidOverDaily2: 'CPC cannot be higher than daily budget',
        startAfterEnd: 'End time cannot be earlier than start time',
        start: 'Start time is required',
        end: 'End time is required',
        endBeforeStart: 'End time cannot be earlier than start time or current time',
        status: 'Status is required',
        minLimit: 'Minimum daily spend cap is ￥2,000/day',
        daily: 'Daily spend cap is required',
        lifetime: 'Spend cap is required',
        targetBid: 'Target bid is required',
        // create
        image: 'Please upload a suitable image',
        imageRevise: 'Please upload a new suitable image',
        imageInfo: 'Please upload a {scale}px image here',
        size: 'Size is required',
        http: 'Please enter a valid company website start with http:// or https://',
        charLenth: 'No more than 250 characters',
        charLen: 'No more than {num} characters',
        revise: 'Please modify required field',
        name: 'Name is required',
        platform: 'Platform is required',
        os: 'OS is required',
        browser: 'Browser is required',
        adFormat: 'Ad format is required',
        placement: 'Placement is required',
        audience: 'Audience is required',
        industry: 'Category is required',
        location: 'Location is required',
        zipcode: 'Zip code is required',
        brandName: 'Brand name is required',
        headline: 'Title is required',
        adAssetName: 'Creative Name is required',
        imageValidations: 'Image is required',
        adAssetNameNotRepeat: 'Creative Name can not be the same',
        landingPage: 'Landing Page is required',
        impURL: 'View Tracking is required',
        landingPageError: 'Landing Page cannot include space, \', \' and multiple \'?\'',
        noAds: 'There are no ads waiting for review under this account',
        skipInfo: 'You have unsaved changes that will be lost if you skip this page.',
        leaveInfo: 'You have unsaved changes that will be lost if you leave this page.',
        reviewNotComplete: 'Review incomplete',
        advertiser: 'Select an advertiser account',
        utmParams: 'Tracking url cannot include ?, @, /, space and line break',
        picDuplicated: 'Do not upload same image',
        // 登录
        email: 'Please enter a valid email address',
        organizationName: 'Organization name is required',
        companySite: 'Company website is required',
        switchLang: 'You will lose all content you filled, are you sure to change language?',
        confirmToSwitch: 'There’re unsaved edits. Do you want to save the edits before switching language？',
        confirmTip: '发布操作不可取消，请点击确认按钮以继续。',
        saveTip: '是否保存当前操作？请点击相应按钮以继续。',
        cacheNewCampaign: 'We noticed that you have a campaign draft, would you like to continue where you left off?',
        cacheContinue: 'Continue',
        cacheAgain: 'Start Over',
        cacheSaveNewCampaign: 'The campaign draft will be saved when you leave this page, you can continue editing next time.',
        fileParse: 'ファイル解析中、送信ボタンをクリックしてください。5 ~ 15分後に新しいダッシュボードでクリエイティブが正常に作成されたか確認できます。',
        pwd: 'Use 8 or more characters with a mix of letters, numbers & symbols',
        pwdNotMatch: 'The two passwords you typed do not match',
        relation: 'Please select filter condition',
        pwdRequired: 'Please enter a valid password',
        // open account
        company: 'Please enter a valid organization name',
        companyName: 'Please enter a valid organization name',
        account: 'Ad account name is required',
        budget: 'Spend cap is required',
        registerAdd: 'Please enter a valid organization registration address',
        registerAddress: 'Please enter a valid organization registration address',
        businessAddress: 'Please enter a valid organization business address',
        timezone: 'Account timezone is required',
        // country: 'Country is required',
        country: 'Location is required',
        payMethod: 'Paymeth method is required',
        adMethod: 'Account type is required',
        vCodeNeed: 'Please enter the correct verification code',
        // 权限
        noUser: 'No user can be authorized',
        errorCampaign: 'Sorry, you don\'t have permission.',
        errorUploadImgSize: 'The uploaded image size cannot exceed 500K!',
        blackWhiteType: 'Please select blacklist or whitelist',
        sspRequire: 'Please select media account',
        accountRequired: 'Account name is required',
        conversionNameRequired: 'Conversion name is required',
        categoryRequired: 'Category is required',
        valueRequired: 'Conversion value is required',
        eventTypeRequired: 'Event category is required',
        repeatEmail: 'This email address is already in use. Please enter another',
        emailNotExist: 'Email address does not exist',
        selectUser: 'User account is required',
        cpcInfo: '\'Target Bid\' must have no more than 3 decimal positions',
        cpcJpyInfo: '\'Target Bid\' must have no more than 1 decimal positions',
        targetCpaInfo: '\'Target CPA\' must have no more than 2 decimal positions',
        cpcNumber: '\'Target Bid\' must be a number',
        invalidFile: 'None of the zipcodes could be added. Please upload valid CSV file and according to the templete',
        sensitiveTag: 'Sensitive Tag is required',
        cpcPercentMin: 'The percent change cannot be less than -99',
        cpcNumberInteger: 'Please enter an integer',
        currencyCheck: 'Please select account settlement currency',
        tradingCurrencyCheck: 'Please select the transaction currency of your account',
        legalRepresentativeCheck: 'Please select one of the following options',
        contractCheck: 'Please select account contractual subject',
        targetCpa: 'Please enter the CPA',
        targetMax: 'Only integers are supported, the minimum input value is 100.',
        cpcMin: 'Only supports inputting integers, and the minimum input value is 10.',
        cpcMax: 'Only integers are supported, the minimum input value is 100. It is recommended that the input value of the ratio is above 150%.',
        cpcMaxUp: 'The upper limit value must be greater than the lower limit value.',
        cpaType: 'Please select CPA type'
    },
    infos: {
        info: 'Tips',
        total: 'Summary',
        emptyText: 'No Data',
        emptyReadChartText: 'No data to show. Your READ tag might not be in place, or there might be no data within the requested date range.',
        allSelect: 'All',
        selected: 'Selected',
        dragTip: '(Drag to reorder)',
        conversionTip: 'Use MediaGo postback link to sync conversion data, {}. Or go to \'Pixel\' navigation bar to set MediaGo pixel, {}. Conversion data will be updated on platform after 2 hours.',
        DiscoveryConversionTip: 'Use Discovery postback link to sync conversion data{}. Or go to \'Pixel\' navigation bar to set Discovery pixel, {}. Conversion data will be updated on platform after 2 hours.',
        conversionTipGo: 'go',
        conversionTipMore: 'more',
        postBack: 'Postback code:',
        sessionInvalid: 'The system has been updated, please refresh the page and try again',
        fileSizeLimit: 'To maximize campaign performance,recommended image  is larger than 600*400 pixels.'
    },
    constants: {
        all: 'All',
        cancel: 'Cancel',
        selectAll: 'All',
        eq: 'Equal',
        gt: 'More than',
        lt: 'Less than',
        ge: 'No less than',
        le: 'No More than',
        platform: 'Platform',
        os: 'OS',
        browser: 'Browser',
        smartphone: 'Smartphone',
        mobile: 'Mobile',
        desktop: 'Desktop',
        tablet: 'Tablet',
        xbox: 'Xbox',
        android: 'Android',
        ios: 'iOS',
        macOs: 'Mac OS',
        windows: 'Windows',
        linux: 'Linux',
        chrome: 'Chrome',
        firefox: 'Firefox',
        safari: 'Safari',
        ie: 'Internet Explorer',
        samsung: 'Samsung',
        opera: 'Opera',
        uc: 'UC Browser',
        inApp: 'InApp',
        edge: 'Edge',
        est: 'EST',
        utc0: 'UTC+0',
        utc7: 'UTC+7',
        utc8: 'UTC+8',
        usdname: 'USD',
        jpyname: 'JPY',
        cnyname: 'CNY',
        sgdname: 'SGD',
        myrname: 'MYR',
        krwname: 'KRW',
        idrname: 'IDR',
        thbname: 'THB',
        twdname: 'TWD',
        hkdname: 'HKD',
        utc9: 'UTC+9',
        standard: 'Standard',
        accelerate: 'Accelerate',
        other: 'Other',
        daily: 'Daily',
        hourly: 'Hourly',
        earlier: 'Earlier than',
        later: 'Later than',
        at: 'On',
        white: 'White',
        black: 'Black',
        msn: 'MSN',
        mgid: 'MGID',
        // 合同主体
        mediago: 'mediago',
        jpSubject: 'jp.popin.cc',
        krSubject: 'kr.popin.cc',
        twSubject: 'tw.popin.cc',
        docomo: 'docomo',
        au: 'au',
        softbank: 'softbank',
        male: 'Male',
        female: 'Female',
        // 受众
        car: 'Autos',
        election: 'Election',
        entertainment: 'Entertainment',
        money: 'Money',
        finance: 'Finance',
        foodDrink: 'Food & Drink',
        homepage: 'Homepage',
        health: 'Health',
        lifestyle: 'Lifestyle',
        music: 'Music',
        movies: 'Movies',
        news: 'News',
        sports: 'Sports',
        travel: 'Travel',
        TV: 'TV',
        video: 'Video',
        weather: 'Weather',
        education: 'Education',
        gadgetsAutos: 'Gadgets and Autos',
        business: 'Business',
        beautyFashion: 'Beauty and Fashion',
        economyMoney: 'Economy and Money',
        homeLifestyle: 'Home and Lifestyle',
        loveMarriage: 'Love and Marriage',
        politicsSocial: 'Politics and Social',
        travelGourment: 'Travel and Gourment',
        autoselectronics: 'Autos & Electronics',
        // 广告分类
        auto: 'Auto',
        beautyFitness: 'Beauty & Fitness',
        beautyHairCare: 'Beauty-Hair Care',
        businessAndFinance: 'Business and Finance',
        careers: 'Careers',
        computerElectronics: 'Computer & Electronics',
        eCommerce: 'E-commerce',
        eventsAndAttractions: 'Events and Attractions',
        familyAndRelationships: 'Family and Relationships',
        financeInsurance: 'Finance & Insurance',
        food: 'Food',
        gaming: 'Gaming',
        Gaming: 'Gaming',
        healthcare: 'Healthcare',
        homeGarden: 'Home & Garden',
        newsAndPolitics: 'News and Politics',
        pets: 'Pets',
        personalCareOralCare: 'Personal Care-Oral care',
        popCulture: 'Pop Culture',
        realEstate: 'Real Estate',
        religionSpiritualty: 'Religion & Spiritualty',
        science: 'Science',
        travelHospitality: 'Travel & Hospitality',
        weightLoss: 'Weight Loss',
        wellness: 'Wellness',
        others: 'Others',
        content: 'Content',
        leadGen: 'Lead Gen',
        search: 'Search',
        insurance: 'Insurance',
        recruitment: 'Recruitment',
        marriage: 'Marriage',
        medicalIndustry: 'Medical industry',
        diet: 'Diet',
        beautyCosmetic: 'Beauty cosmetic',
        homeAppliances: 'Home appliances',
        computer: 'Computer',
        communication: 'Communication',
        media: 'Media',
        game: 'Game',
        physicalEducation: 'Physical education',
        beautyCosmetics: 'Beauty cosmetics',
        industry: 'Category'
    },
    requestMsg: {
        requestError: 'Network error, please try again',
        exportError: 'Failed to export data, please try again',
        exportSuccess: 'Successfully exported',
        statusUpdated: 'Successfully updated',
        updatedFailed: 'Failed to upload data',
        createError: 'Failed to create',
        uploadFailed: 'Failed to upload, please try again',
        uploadOverSize: 'Image size cannot exceed {num} MB',
        uploadSuccess: 'Successfully uploaded',
        gifSizeInfo: 'Please upload a new image with suitable size',
        logoutSuccess: 'Successfully logout',
        logoutFailed: 'Faild to logout',
        modified: 'Your password has been reset successfully!',
        loginFailed: 'Account name or password is wrong',
        copySuccess: 'Successfully copied',
        previewFirst: 'Please click Preview first'
    },
    backendMsg: {
        fail: 'fail',
        success: 'success',
        sendCodeSucs: 'Send code success',
        alreadyExist: 'This email address is already in use. Please enter another',
        verificateFail: 'Please enter the correct verification code',
        busy: 'Server is busy, please try later',
        noPermission: 'Sorry, you don\'t have permission.',
        gifError: 'Gif size does not meet requirements, please upload a new Gif',
        loginError: 'Account or password is incorrect',
        registError: 'This email address is already existing',
        repeatCreate: 'Do not create ads repeatedly',
        operateTooMuch: 'Do not operate frequently',
        amountError: 'Recharge amount should be more than 0',
        accountExist: 'The ad account already exists',
        companyExist: 'The campany already exists',
        campaignNameExist: 'Campaign name should not be same under one account',
        paramsError: 'Parameter is wrong',
        addRepeat: 'Cannot Add Repeatedly',
        landingpageOrUtmIsInvalid: 'Landing page or tracking code is incorrect',
        conversionExist: 'This conversion name already exists',
        requestError: 'Network error, please try again',
        linkInvalid: 'Invalid token',
        roleExist: 'Role name already exists',
        noResult: 'The verification code has timed out, or the mailbox has not sent the verification code',
        ecommercePageNameRepeated: 'Page Name is already in use. Please enter another.',
        ecommerceSeoUrlRepeated: 'SEO URL is already in use. Please enter another.',
        auditTypeNameExist: '审计类型名称已经存在',
        tagExist: '标签已存在',
        readExist: 'The Read code already exists',
        completedDataAccumulation: 'This campaign has completed data accumulation and the one-click inheritance function cannot be used.',
        sizeTooLarge: 'The maximum image size cannot exceed 1MB.',
        imgTypeError: 'Only supports uploading image types such as JPG，JPEG，PNG，GIF.'
    },
    // paypal充值
    paymentList: {
        time: 'Time',
        id: 'Payment ID',
        status: 'Payment Status',
        actualAmount: 'Actual Amount',
        transferFee: 'Payment Type',
        prepaidAmount: 'Payment Amount',
        method: 'Payment Method',
        payer: 'Paypal Account',
        receipt: 'Receipt',
        accountName: 'Ad Account',
        totalCoupon: 'Total Coupon',
        couponBalance: 'Coupon Balance',
        totalQuotaTip: 'Total Balance = Total Recharged + Credit Line + Total Coupon - Total Spend',
        fold: 'Fold Account List',
        expand: 'Unfold Account List',
        recharge: 'Recharge',
        autoBilling: 'Auto Billing',
        operate: 'Details',
        balanceLess0: 'Total balance of this account is 0. Visit {Billing} to recharge your account before launching a new campaign.'
    },
    // stripe支付
    stripeList: {
        status: 'Status',
        account: 'Ad Account',
        method: 'Payment Method',
        date: 'Last Billing Date',
        amount: 'Last Billing Amount',
        spend: 'Account Spend',
        card: 'Payment Card',
        ABAmount: 'Auto Billing Amount',
        edit: 'Edit',
        paymentDetails: 'Payment Details',
        history: 'Payment History'
    },
    // geoEdge
    geoEdge: {
        type: 'Alert Type',
        time: 'Time',
        detail: 'Detail',
        detailLink: 'Details Page Link'
    },
    // bidAnalysis
    bidAnalysis: {
        adId: 'ad id',
        placementId: 'placement id',
        avgRank: 'rank',
        bidMode: 'bid mode',
        picpm: 'P-ICPM',
        pvcpm: 'P-VCPM',
        pictr: 'P-ICTR',
        pvctr: 'P-VCTR',
        pvwr: 'P-VWR',
        pvctrLevel: 'P-VCTR level',
        pvwrLevel: 'P-VWR level',
        cpc: 'CPC',
        campaignId: 'campaign id',
        title: 'ad title',
        linkTo: 'bid analysis'
    },
    // 电商
    'nativeE-commerceTool': {
        createFormAccountName: 'Account Name',
        createFormProductName: 'Product Name',
        createFormSampleLPUrl: 'Sample LP Url',
        createFormParseBtn: 'Parse',
        createFormPageName: 'Page Name',
        createFormPageNameLength: 'No more than 300 characters',
        createFormImages: 'Images',
        createFormImagesBtn: 'View All Images',
        createFormreplaceKeyword: 'Replace Keywords',
        createFormreplaceKeyword1: 'replace all',
        createFormreplaceKeyword2: 'to',
        createFormAddRules: 'Add Rules',
        createFormMatchCase: 'match case',
        createFormCTAURL: 'CTA URL',
        createFormSEOURL: 'SEO URL',
        createFormSeoTagTitle: 'SEO TITLE',
        createFormseoDescription: 'SEO Desription',
        createLPBtn: 'Add New LP',
        createOPBtn: 'Add New OP',
        parseSuccess: 'Parse Success',
        parseFail: 'Parse Fail',
        searchLPFilter: 'Search Page Name',
        searchButton: 'Search',
        productMultipleChoice: 'product只允许单选',
        coppyOPHTML: '复制 HTML',
        productChoice: '请选择product',
        opCreatedPageName: '请输入PageName',
        coppyLPLinkButton: 'Copy Link',
        selectDomain: 'Select Domain',
        addTemplate: 'Add New Email Template',
        searchtemplate: 'Search Email Template',
        emailTemplateName: 'Email Template Name',
        status: 'Status',
        domain: 'Domain',
        emailAddress: 'Email Address',
        emailPassword: 'Password',
        lastModifiedDate: 'Last Modified Date',
        creationUser: 'Creation User',
        lastModifiedUser: 'Last Modified User',
        operation: 'Operation',
        domainName: 'Domain Name',
        templateName: 'Template Name',
        conditions: 'Conditions',
        storeLogo: 'Store Logo',
        storeName: 'Store Name',
        emailTitle: 'Email Title',
        emailContent: 'Email Content',
        emailImage: 'Email Image',
        emailFooter: 'Footer',
        addNewEmailTemplate: 'Add New Email Template',
        editEmailTemplate: 'Edit Email Template',
        copyEmailTemplate: 'Copy Email Template',
        deleteEmailTemplate: 'Delete Email Template',
        recommendedSize: 'Recommended Size'
    },
    supplement: {
        bulkUpload: 'Bulk upload',
        regionEurope: 'Region: GE、FR、IT、ES、AT',
        editCompany: 'Edit Company',
        agentAccount: 'Agency Account',
        selectAgentAccount: 'Please select an agency account',
        externalWebsite: 'External website link to fill in company information',
        change: 'Revise',
        editAccount: 'Edit Account',
        addAccount1: 'Add Account',
        pleaseEnterContent: 'Please enter content',
        openingInformation: 'Account Opening Information',
        agencyPublisher: 'If the selected account is agency publisher, it cannot be modified once selected.',
        specifiedSSP: 'Specified SSP',
        operationType: 'Operation Type',
        accountManager1: 'Account / Manager',
        progressInDescription: 'Progress Description',
        descriptionOfCurrentProgress: 'Description',
        currentProgress: 'Please enter a description of the current progress',
        pleaseSelect: 'Please select',
        quantityAllotted: 'Quantity Allotted',
        discoveryOnline: 'discovery online contract',
        seleced: 'Selected',
        editRemark: 'Edit Remark',
        bid: 'Bid',
        noBid: 'No Bid',
        fixedBid: 'Fixed Bid',
        smartBid: 'Smart Bid',
        noAdBid: 'There are no ad bid configurations pending review',
        EntNumber: 'Please enter a number between -1 to 1',
        trainPrice: 'Train Price',
        floorPrice: 'Floor Price',
        maxPrice: 'Max Price',
        limitCPC: 'Limit CPC',
        atLeast: 'At least one hour or day is required',
        noCreateAPI: 'The account does not meet the conditions to create an API',
        eventArea: 'Please select an event area',
        pleaseenterrecipientemail: 'Please enter recipient email',
        billingInformation: 'Billing Information',
        cooperationMode: 'Cooperation Mode',
        viewRS: 'View RS Modification History',
        setRS: 'New RS Setting',
        onceEnter: 'Once enter, it can\'t be modified',
        billingCycle: 'Billing Cycle',
        financialEmail: 'Financial Email',
        sendInvoice: 'Used to send invoice information, multiple email can be added, separated by;',
        mediaInformation: 'Media Information',
        trafficType: 'Traffic Type',
        adTool: 'Ad Tool',
        addUser1: 'Add User',
        userBackground: 'User Background',
        authorizationConfig: 'Authorization Config',
        roleConfig: 'Role Config',
        remenberUser: 'The user is added successfully, please remenber the following user account password.',
        userName: 'User Name',
        userPassword: 'User Password',
        advertiserLogin: 'The user is added successfully, please log in with the advertiser\'s platform password.',
        done: 'Finish',
        accountORcompany: 'Enter account/company name',
        selectRole: 'Please select a role',
        selectBMaccount: 'Please select BM account',
        selectWritable: 'Please select a writable account',
        selectReadable: 'Please select a readable account',
        accAuthorization: 'Account Authorization',
        enterAmountCoupon: 'Please enter the amount to be Coupon',
        enterBM: 'Please enter business manager',
        enterIndustryClassification: 'Please enter industry classification',
        enterIndustry: 'Please enter industry',
        selectAM: 'Please select account manager',
        selectContractType: 'Please select the contract type',
        selectAccountLanguage: 'Please select account language',
        selectCompanyType: 'Please select company type',
        addressCompany: 'Please enter the company operating address',
        enterAddress: 'Please enter company registered address',
        enterValid: 'Please enter a valid rebate rate',
        selectAppropriate: 'Please select the appropriate agency account',
        onHold: 'on Hold',
        cVROPC1: 'This CVR optimization and creation of ad bids',
        cVROPC2: 'OCPC linkage, please carefully.',
        cVROPC3: '1) If the campaign bid is a fixed bid, this CVR optimization configuration is not perceptible to advertisers. If the advertiser modifies the fixed bid to OCPC, this CVR optimization configuration will be used as the default configuration of OCPC.',
        cVROPC4: '2) If the campaign bid is OCPC, this CVR optimization configuration is linked with OCPC, advertisers can perceive it and use it with caution.',
        algorithmic: 'Algorithm optimization object,',
        changedAfterSetting: ' cannot be changed after setting',
        cpa: 'Must be a number greater than 0',
        completed: 'Completed',
        pleaseEnter1: 'Please enter',
        profitRatio: 'Profit margin must be a number',
        yueBao1: '(Internal information, please do not leak it at will)',
        yueBao3: 'Operation red line, material pictures and detailed data indicators are only viewed internally, do not share directly with advertisers',
        dailySubsidy: 'The upper limit of the supplementary sky-level loss must be a number not less than 0',
        totalSubsidy: 'The upper limit of the total loss of replenishment must be a number not less than 0',
        accelerate: 'Speedup training multiplier must be an integer between 0-20',
        impBound: 'Speedup training multiplier must be an integer between 0-10000',
        contains: 'Contains',
        percentage: 'Percentage',
        creation: 'Creating',
        processing: 'Processing',
        accepting: 'Accepting',
        clear: 'Clear',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sun: 'Sun',
        selected: 'Selected',
        unselected: 'Unselected',
        less1: 'Due to the transfer fee charged by Stripe, MediaGo will receive a recharge of less than ',
        less2: '.',
        whenRecharge: 'When the account is consumed to the set limit, Stripe will deduct a fixed amount from the credit card that you have saved in Stripe for automatic deduction. The automatic deduction amount can be set in the automatic recharge.',
        whenAmount: 'When the account is consumed to the amount you set in the account consumption amount, Stripe will deduct the amount you set here from the credit card you saved in Stripe\'s automatic deduction. Please note that the set automatic recharge amount cannot be lower than the set account consumption amount.',
        autoBillingAmount: 'Auto Billing Amount',
        paymentDetails: 'Payment Details',
        dueAmount: 'Due to the transfer fee, the actual amount received will be less than the payment amount.',
        selectAdAccount: 'Select Ad Account',
        minimum: 'The minimum amount is $50.00',
        transferAmount: 'Transfer FeePay full amount (inclusive of PayPal\'s fees)',
        paypal1: 'To make sure MediaGo receives the full amount of ',
        paypal2: ', you need to pay',
        paypal3: ' to cover the transfer fee charged by PayPal.',
        amountPayable: 'Amount Payable',
        transferFee: 'Transfer fee',
        rechargeAmount: '2.9% of recharge amount + $0.30 per transaction',
        actualAmount: 'Actual amount',
        cancel: 'Cancel',
        payNow: 'Pay Now',
        paymentHistoryDetails: 'Payment History Details',
        paymentAccount: 'Payment Account',
        rechargeAmount1: 'Recharge Amount',
        amountSpend: 'Amount Spend',
        account: 'Account',
        PayPalmore1: 'Please note that the standard transaction fee via PayPal is 2.9% of the transaction amount plus $0.30 per transaction. For more details, ',
        PayPalmore2: 'please visit here.',
        automaticBilling: 'Automatic Billing',
        automaticBilling1: 'Once this is turned on，Stripe will automatically save your credit card information for the next automatic billing.',
        monthlyOperationalReport: 'Monthly Report',
        currentIncome: 'Current Income',
        percentageOfConsumption: 'Percentage Of Consumption',
        lastPeriodIncome: 'Last Period Income',
        yearOnYearLastWeek: 'WoW',
        yearOnYear: 'Year-On-Year',
        comparisonFunction1: 'Comparison Function Usage Tips:',
        comparisonFunction2: 'Step1: Select the current period time interval on the left',
        comparisonFunction3: 'Step2: Select the start time of the comparison on the right',
        display: 'Pie Chart Data',
        comparison: 'Comparison',
        categoryOfEvent: 'Category Of Event',
        adAccount: 'Ad Account',
        category: 'Category',
        campaignName: 'Campaign Name',
        priority: 'Priority',
        status: 'Status',
        accountManager: 'Account Manager',
        currentPersonInCharge: 'Current Person In Charge',
        addTracker: 'Add Tracker',
        uploadImage: 'Upload Image',
        blackFormat: 'Black Format',
        blackPageType: 'Black Page Type',
        forstyle: 'For style filtering, separate multiple values with English ";". For example: infopane;river',
        forpage: 'For pagetype filtering, multiple values are separated by English ";". For example: edgechrmtp;25;36',
        startDate: 'Start Date',
        accountSource: 'Account Source',
        accountNature: 'Account Nature',
        contractTitle: 'Contract Title',
        aPIToken: 'APIToken',
        serviceType: 'Service Type',
        businessManagement: 'Business Management',
        advertiser: 'Advertiser',
        aPIhasinstalled: 'API has installed',
        withoutSettingAPI: 'Without Setting API',
        openAccountIndependently: 'Open Account Independently',
        inviteToOpenAccount: 'Invite To Open Account',
        searchName: 'Search Account/Company Name',
        previewImgTitle: 'Clear',
        accountName: 'Account Name',
        accountID: 'Account ID',
        companyName: 'Company Name',
        // country: 'Country',
        country: 'Location',
        billingEmail: 'Billing Email',
        organizationName: 'Organization Name',
        businessRebate: 'Business Rebate',
        email: 'Account Holder\'s Email',
        paymentMethod: 'Payment Method',
        deliverymethod: 'Delivery method',
        accountTimeZone: 'Account Time Zone',
        businessManager: 'Business Manager',
        industryCategory: 'Industry Category',
        creationTime: 'Creation Time (EST)',
        operate: 'Operate',
        accountDetails: 'Account Details',
        accountInformation: 'Account Information',
        organizationWebsite: 'Organization Website',
        accountHolderInformation: 'Account Holder Information',
        totalAccountBudget: 'Total Account Budget',
        timeZone: 'Time Zone',
        company: 'Company',
        mediaGoNon: 'MediaGo Non-Docking Company',
        registeredAddressOfCompany: 'Registered Address Of Company',
        companyAddress: 'Company Address',
        emailaddress: 'Please enter a valid email address',
        prepay: 'Prepay',
        postpay: 'Postpay',
        selfService: 'Self-service',
        managed: 'Managed',
        mark1: 'Conditions Of Account Creation API:1. The nature of account is an advertiser (second confirmation)2. The account status is open3. The consumption of the account reaches $5000',
        generateToken: 'Generate Token',
        mark2: 'Note: Ads placed on Taboola cannot use API  to pull data.',
        addUserRW: 'Adding a user will automatically enable the user\'s readable permission for the account',
        otherUsers: 'Other Users',
        operator: 'Operator',
        operatingTime: 'Operating Time',
        specificIndustry: 'Specific Industry',
        add: 'Add',
        automaticReporting: 'Automatic Reporting',
        reportTimeZone: 'Report Time Zone',
        recipient: 'Recipient',
        sendingFrequency: 'Sending Frequency',
        stopsending: 'Stop sending email after closing',
        consistent: 'Consistent with account time zone',
        mark4: 'Multiple emails can be entered, separated by commas',
        daily: 'Daily',
        monthly: 'Monthly',
        advertiserName: 'Advertiser Name',
        advertiserEmail: 'Advertiser Email',
        advertiserApplication: 'Advertiser Application',
        loginInfo: 'Login Info',
        applicationCompleted: 'Application Completed',
        postpay1: 'Postpay',
        accountType: 'Account Type',
        reportingTimezone: 'Reporting Timezone',
        selectthetimezone: 'Select the time zone to manage your campaign. Your setting cannot be modified.',
        organizationName1: 'Organization Name',
        asthiswil: 'As this will directly impact billing, please enter the organization details carefully and accurately to reflect official registered documents.',
        organizationAddress: 'Organization Address',
        asthiswilldirectly: 'As this will directly impact billing, please enter the organization details carefully and accurately to reflect official registered documents.',
        organizationWebsite1: 'Organization Website',
        initialCreditLine: 'Initial Credit Line',
        ifyou: 'If you set an initial credit line greater than 0, a notification email will sent to both AM group and you.',
        advertisernameisrequired: 'Advertiser name is required',
        registeraddressisrequired: 'Register address is required',
        pleaseEnter: 'Please enter a valid company website starting with http: or https:',
        workEmail: 'Work Email',
        workEmail1: '(this will be used as username)',
        firstName: 'First Name',
        lastName: 'Last Name',
        password: 'Password',
        pleaseenteravalidemailaddress: 'Please enter a valid email address',
        pleaseenteravalidfirstname: 'Please enter a valid first name',
        pleaseenteravalidlastname: 'Please enter a valid last name',
        specialcharacterandnumber: 'Please enter at least 8 characters with a mix of character, special character and number',
        next: 'Next',
        previous: 'Previous',
        submit: 'Submit',
        officialWebsite: 'Official Website',
        pleaseenteryouremail: 'Please enter your email',
        pleaseentercompanyURL: 'Please enter company URL',
        pleaseentertheaccountname: 'Please enter the account name',
        pleaseenterbudget: 'Please enter budget',
        deliveryMethod: 'Delivery Method',
        pleaseselectacompany: 'Please select a company',
        addCompany: 'Add Company',
        ifthere: 'If there is no your company in the options, please add and then select.',
        customerSource: 'Customer Source',
        terminalclient: 'Terminal client',
        agent: 'Agent',
        contractType: 'Contract Type',
        contractType2: 'Contract Title',
        adding: 'Adding a user will automatically enable the user\'s readable permission for the account',
        pleasechooseanotherrole: 'Please choose another role.',
        registeredaddressofthecompany: 'Registered address of the company',
        companyBAddress: 'Company B Address',
        bysubmitt: 'By submitting this form, I agree to MediaGo\'s Privacy Policy and Terms.',
        authorizedUser: 'Authorized User',
        unauthorizedUser: 'Unauthorized User',
        name: 'Name',
        usersource: 'User source',
        loginMethod: 'Login Method',
        workEmail2: 'Work Email',
        companyName1: 'Company Name (name entered on the platform)',
        position: 'Position',
        numberOfWritableAccounts: 'Number Of Writable Accounts',
        numberOfReadableAccounts: 'Number Of Readable Accounts',
        role: 'Role',
        searchCompanyName: 'Search Company Name+Name+Email',
        userInvitationLink: 'User Invitation Link',
        details: 'Details',
        userRegistrationInformation: 'User Registration Information',
        userSource: 'User Source',
        lastName1: 'Last Name',
        name1: 'Name',
        companyWebsite: 'Company Website',
        telephone: 'Telephone',
        userRole: 'User Role',
        accountAllocation: 'Account Allocation',
        bMAccount: 'BM Account',
        writableAccount: 'Writable Account',
        readableAccount: 'Readable Account',
        addingordeletinga: 'Adding or deleting a BM account will affect the user\'s readable authority for all agent accounts as the BM account. Please operate with caution.',
        edit: 'Edit',
        ordinal: 'Ordinal',
        roleName: 'Role Name',
        assignedNumber: 'Assigned Number',
        changeTime: 'Change Time',
        createRole: 'Create Role',
        pleaseentertherolename: 'Please enter the role name',
        mediaGoAdvertiser: 'MediaGo Advertiser',
        baiduReviewer: 'Baidu Reviewer',
        microsoftReviewer: 'Microsoft Reviewer',
        baiduRD: 'Baidu RD',
        baiduBD: 'Baidu BD',
        baiduAM: 'Baidu AM',
        financialStaff: 'Financial Staff',
        admin: 'Admin',
        dataAnalyst: 'Data Analyst',
        mGIDReviewer: 'MGID Reviewer',
        parentologyReviewer: 'Parentology Reviewer',
        helpCenter编辑: 'HelpCenter编辑',
        financialReceivable: 'Financial Receivable',
        roleManager: 'Role Manager',
        prebidTestReviewer: 'Prebid Test Reviewer',
        rTBDemandReviewer: 'RTBDemand Reviewer',
        zipporReviewer: 'Zippor Reviewer',
        adPushupReviewer: 'AdPushup Reviewer',
        advertiser1: 'Agent-Advertiser',
        pubGeniusReviewer: 'PubGenius Reviewer',
        insticatorReviewer: 'Insticator Reviewer',
        streamAmpReviewer: 'StreamAmp Reviewer',
        financialStaffPaypal: 'Financial Staff-Paypal',
        eCommerceAM: 'E-commerce AM',
        eCommerceROIReport: 'E-commerce ROI Report',
        adminR: 'Admin-R',
        internalTestAdvertiser2: 'Internal Test Advertiser 2',
        offlineProject: 'Finance-Offline Project',
        bulkReview: 'Bulk Review',
        domainWhitelist: 'Domain Whitelist',
        financialReport: 'Financial Report',
        stripeAutoBilling: 'Internal Test Advertisers-Stripe Auto Billing',
        discoveryAdvertiser: 'Discovery Advertiser',
        discoveryFinancalTest: 'Discovery Financal Test',
        discoveryOldAdvertisers: 'Discovery Old Advertisers',
        discoveryOldAgency: 'Discovery Old Agency',
        oldDiscoveryAgency: 'Old Discovery Agency',
        discoveryNewadvertisers: 'Discovery New advertisers',
        platformExperienceRead: 'Platform Experience -Read',
        bidbydomain: 'bid by domain',
        newDiscoveryAdvertiser: 'New Discovery Advertiser',
        dashboard: 'Dashboard',
        reviewDetails: 'Review Details',
        campaignDetails: 'Campaign Details',
        campaignCopy: 'Campaign Copy',
        campaignEdit: 'Campaign Edit',
        adStatus: 'Ad Status',
        domainBlock: 'Domain Block',
        domainTabsspColumn: 'Domain Tab ssp Column',
        reviewDetails1: 'Review Details Media Classification',
        adColumnSize: 'Ad Column & Size',
        dimensionFilter: 'dimensionFilter',
        conversiontype: 'conversiontype',
        oCPC: 'OCPC',
        domainSetCampaignCpc: 'domainSetCampaignCpc',
        eCPC: 'ECPC',
        adOperation: 'Ad Operation',
        analysis: 'Analysis',
        blackwhiteconfig: 'Black white config',
        campaignOperationConfig: 'Campaign Operation Config',
        taboolaAdPull: 'Taboola Ad Pull',
        blacklistconfig: 'Campaign PageId Blacklist config',
        reminder: 'Campaign Exception Reminder',
        log: 'Campaign Plan Operation Log',
        advertiserOperationRecord: 'Advertiser Operation Record',
        multiDimensionalreport: 'Multi-dimensional report',
        tracker: 'Tracker',
        bidAnalysis: 'Bid Analysis',
        diagnosis: 'Diagnosis',
        innerFAQ: 'Inner FAQ',
        sample: 'Sample',
        newCampaign: 'New Campaign',
        win10adTargeting: 'win10 ad-targeting',
        userPackage: 'userPackage',
        more: 'more',
        // domainwhitelist: 'domainwhitelist',
        impURL: 'impURL',
        // locationJapan: 'locationJapan',
        // monthlyCapStatus: 'monthlyCapStatus',
        // objective: 'objective',
        // audience: 'audience',
        pixel: 'Pixel',
        conversionlist: 'Conversion list',
        readlist: 'Read list',
        newConversion: 'New Conversion',
        dataReport: 'Data Report',
        lPManagement: 'LP Management',
        oPManagement: 'OP Management',
        productManagement: 'Product Management',
        domainManagement: 'Domain Management',
        customerManagement: 'Customer Management',
        rOIAnalysis: 'ROI Analysis',
        paymentManagement: 'Payment Management',
        reviewAds: 'Review Ads',
        startReviewing: 'Start Reviewing',
        detail: 'Detail',
        category1: 'Category',
        geoEdge: 'Geo Edge',
        sensitiveCategory: 'Sensitive Category',
        auditLabel: 'Review Tag',
        displayallauditedimg: 'display all audited img',
        authorization: 'Authorization',
        accountList: 'Account List',
        addAccount: 'Add Account',
        link: 'Account Opening Invitation Link',
        authorize: 'Authorize',
        ignore: 'Ignore',
        copyInvitationLink: 'Copy Invitation Link',
        bDAccountOpening: 'BD Account Opening',
        userList: 'User List',
        roleList: 'Role List',
        delete: 'Delete',
        create: 'Create',
        billing: 'Billing',
        recharge: 'Recharge',
        stripe: 'Stripe',
        autoBilling: 'Auto Billing',
        multiCurrencySwitcher: 'Multi-Currency Switcher',
        finance: 'Finance',
        creditLine: 'Credit Line',
        creditLine1: 'Credit Line',
        coupon: 'Coupon',
        rOIReport: 'ROI Report',
        receivable: 'Receivable',
        payable: 'Payable',
        offline: 'offline',
        bannerViewableTest: 'Banner Viewable Test',
        viewableTest: 'Viewable Test',
        hibernation: 'Hibernation',
        aDCallback: 'AD Callback',
        adBidConfig: 'Ad Bid Config',
        review: 'Review',
        history: 'History',
        generateLPWrapper: 'generate LP Wrapper',
        getEmail: 'Get Email Verification Code',
        write: 'Write',
        read: 'Read',
        currencyType: 'Currency Type',
        open: 'Open Account Independently',
        inviteTo: 'Invite To Open Account',
        uSD: 'USD',
        cNY: 'CNY',
        jPY: 'JPY',
        sGD: 'SGD',
        hKD: 'HKD',
        tWD: 'TWD',
        tHB: 'THB',
        iDR: 'IDR',
        kRW: 'KRW',
        mYR: 'MYR',
        totalBalance: 'Total Balance',
        totalRecharge: 'Total Recharge',
        availablequota: 'Available quota',
        creditLineSetting: 'Credit Line Setting',
        creditLineRecord: 'Credit Line Record',
        rechargeRecord: 'Recharge Record',
        pleaseentertheamounttorecharge: 'Please enter the amount to recharge.',
        validAmount: 'Please enter valid amount.',
        asof: 'As of',
        remark: 'Remark',
        pleaseenteraremark: 'Please enter a remark',
        uTSDataUpdateTime: 'UTS Data Update Time',
        totalCoupon: 'Total Coupon',
        couponConsumption: 'Coupon Consumption',
        effectiveCouponBalance: 'Effective Coupon Balance',
        effectiveTime: 'Effective Time',
        expireDate: 'Expire Date',
        offlineTime: 'Offline Time',
        profitMarginSetting: 'Profit Margin Setting',
        pleaseenterprofitmargin: 'Please enter profit margin',
        profitMarginRecord: 'Profit Margin Record',
        currentIncome1: 'Current Income',
        currentCost: 'Current Cost',
        currentProfit: 'Current Profit',
        profitMargin: 'Profit Margin',
        impression: 'Impression',
        clicks: 'Clicks',
        totalHistory: 'Total History',
        totalHistoricalIncome: 'Total Historical Income',
        totalHistoricalCost: 'Total Historical Cost',
        historicalProfitMargin: 'Historical Profit Margin',
        lastUpdateTime: 'Last Update Time',
        enterAccountCompanyName: 'Enter AccountCompany Name',
        media: 'Media',
        currentIncome2: 'Current Income = Current Consumption Of The Account (without coupon reduction)',
        pullAds: 'Pull Ads',
        interfaceSetting: 'Interface Setting',
        adInterface: 'Ad Interface',
        testField: 'Test Field',
        campaignId: 'Campaign Id',
        aDId: 'AD Id',
        onlineEnvironment: 'Online Environment',
        testEnvironment: 'Test Environment',
        developmentEnvironment: 'Development Environment',
        testNoBilling: '1- Test-No Billing',
        realBilling: '0-Real-Billing',
        multipleids: 'Multiple ids, separated by English commas. Example: 111,222',
        multipleids1: 'Multiple ids, separated by English commas. Example: 111,222',
        pageParameters: 'Page Parameters',
        placement: 'Placement',
        pageURL: 'Page URL',
        category2: 'Category',
        adTypeSetting: 'Ad Type Setting',
        thereserveprice: 'The reserve price, default is 0. eg: 0.43',
        fixedIndex: 'Fixed Index: English comma separated, default index',
        geographicalConfig: 'Geographical Config',
        zipCode: 'Zip Code',
        iP: 'IP',
        language: 'Language',
        unitedStatesState: 'United States-State',
        noneMSNMayNotTransmit: 'None-MSN May Not Transmit',
        stateData: 'State Data',
        deviceInformation: 'Device Information',
        userAgent: 'userAgent',
        personalInformation: 'Personal Information',
        microsoftUID: 'Microsoft UID',
        copymuid: 'Copy muid',
        refreshmuid: 'Refresh muid',
        fuzzyMatch: 'Fuzzy Match',
        sspId: 'Ssp Id',
        selectRecallStatus: 'Select Recall Status',
        notrecallable: 'Not recallable',
        recallable: 'Recallable',
        inquire: 'Inquire',
        column: 'Column',
        campaign: 'campaign',
        ad: 'ad',
        generate: 'Generate',
        getVerificationCode: 'Get Verification Code',
        verificationCode: 'Verification Code',
        none: 'None',
        obtain: 'Obtain',
        pid: 'Pid',
        priceStatus: 'Price Status',
        trainPriceStatus: 'Train Price Status',
        sSPId: 'SSP Id',
        imageSize: 'Image Size',
        format: 'Format',
        pidProfit: 'Pid Profit',
        cTRSwitch: 'CTR Switch',
        priceMode: 'Price Mode',
        toBeReviewed: 'To Be Reviewed',
        belowReservePrice: 'Below Reserve Price',
        reset: 'Reset',
        search: 'Search',
        addPid: 'Add Pid',
        startReview: 'Start Review',
        adId: 'Ad Id',
        mediaAccount: 'Media Account',
        mediaAccountName: 'Media Account Name',
        site: 'Site',
        creativeID: 'Creative ID',
        browser: 'Browser',
        platform: 'Platform',
        oS: 'OS',
        stateRegion: 'StateRegion',
        cost: 'Cost',
        revenue: 'Revenue',
        click: 'Click',
        vCPM: 'VCPM',
        cTR: 'CTR',
        avgCPC: 'Avg.CPC',
        reqAdNum: 'Req AdNum',
        reqNum: 'Req Num',
        resNum: 'Res Num',
        conversions: 'Conversions',
        conversionRate: 'Conversion Rate',
        conversionPrice: 'Conversion Price',
        viewContent: 'View Content',
        appInstall: 'App Install',
        completeRegistration: 'Complete Registration',
        addToCar: 'Add To Cart',
        addPaymentInfo: 'Add Payment Info',
        startCheckout: 'Start Checkout',
        purchase: 'Purchase',
        addToWishlist: 'Add To Wishlist',
        lead: 'Lead',
        other: 'Other',
        addTitle: 'Add Title',
        benchmark1: 'Benchmark Rules:',
        benchmark2: 'Export all Campaign data under each category as raw data by category',
        benchmark3: 'Filter the raw campaign data',
        benchmark4: 'Keep the approved Campaign (exclude rejection and archive)',
        benchmark5: 'Keep this month’s campaigns with Vimp greater than 1W, Clicks greater than 100, and Spend greater than $100',
        benchmark6: 'Sort the remaining Campaign into categories by CPC and CTR to get the range of CPC and CTR',
        benchmark7: 'Excellent material selection rules:',
        benchmark8: 'Export all Campaign data under each category as raw data by category',
        benchmark9: 'Filter the raw campaign data',
        benchmark10: 'Keep the approved Campaign (exclude rejection and archive)',
        benchmark11: 'Filter out the top 10 campaigns that consume this month under each category',
        benchmark12: 'Filter the ads under Top Campaign, and filter out the ads with vimp greater than 2W',
        benchmark13: 'Select Top Ad by category: make sure that the creative is not duplicated, if the creative is duplicated, the ranking will be postponed',
        benchmark14: 'Content: Sort the filtered Ad uniformly according to vctr, and select the top 10 Ad by vctr.',
        benchmark15: 'E-commerce: Sort the filtered Ad according to cvr and vctr respectively',
        benchmark16: 'Pick out the top 5 ads by cvr',
        benchmark17: 'Select the top 5 Ad by vctr',
        benchmark18: 'Finance: Sort the selected Ad uniformly according to vctr, and select the top 10 Ad by vctr.',
        locationJapan: 'locationJapan',
        monthlyCapStatus: 'monthlyCapStatus',
        domainwhitelist: 'domainwhitelist',
        objective: 'objective',
        audience: 'audience',
        remarkisrequired: 'Remark is required！',
        amountRequired: 'Please enter the complete amount to be adjusted',
        addUser: 'Add User',
        privacyPolicy1: 'By submitting this form, I agree to MediaGo\'s',
        privacyPolicy2: 'Privacy Policy',
        privacyPolicy3: 'and',
        privacyPolicy4: 'Terms',
        privacyPolicy5: '.',
        previous1: 'Thank you for registering!',
        previous2: 'If you have any further questions,',
        previous3: 'please contact',
        previous4: 'We’ll reply to you as soon as possible.',
        startAuthorizing: 'Start Authorizing',
        searchbyAccountType: 'Search  by Account Type',
        youImageHere: 'You Image Here',
        targetMCPA: 'Target MCPA',
        targetCPA: 'Target CPA',
        confirmSubmission: 'After submission, the algorithm optimization object cannot be changed. Confirm submission?',
        accountName1: 'Account Name',
        campaginID: 'Campaign Id',
        currentManager: 'Current Manager',
        addRole: 'Add role',
        loginAddress: 'Login Address',
        permanentlyDelete: 'The current operation will permanently delete the role, please click Continue',
        goon: 'continue',
        verifyThat: 'Confirm API token generation',
        applicationTitle: 'Application completed!Please save the following advertise & login info. AMwill deal your credit line application， please check your email.',
        noApecificAccount: 'No specific account',
        noMoreThan: 'Can not exceed',
        pleaseSelectItemCategory: 'Please select category',
        pleaseSelectStatus: 'Please select status',
        pleaseSelectPriority: 'Please select a priority',
        pleaseSelectResponsiblePeople: 'Please select the current responsible person',
        theAbnormalExposure: 'Abnormal exposure',
        blackFormat1: 'Please enter black format',
        blackPlacement: 'Please enter black placement',
        keyGoal: 'Core target length cannot exceed 100 characters',
        advertisingCategory: 'Product length cannot exceed 100 characters',
        communicationChannel: 'Communication channel length cannot exceed 100 characters',
        otherInfo: 'Other information length cannot exceed 200 characters',
        expireTime: 'Please select an expiration time.',
        profitRate: 'Please enter the profit margin to be modified',
        amount: 'Please enter the amount to be recharged',
        editCreditline: 'Please enter the credit line to be set',
        recordValue: 'Current Credit Line',
        totalRecharge1: 'Cumulative Recharge Amount',
        cannotBeModified: 'After confirmation, it cannot be modified. Confirm?',
        checkNumber: 'Please enter a valid number',
        password1: 'One upper/lower character',
        password2: 'One special character',
        password3: 'One number',
        password4: 'At least 8 characters',
        yueBao2: 'Creative Recommendation',
        undef: 'Undefined',
        affiliate: 'Affiliate',
        affiliateName: 'Affiliate name',
        billingPeriod: 'Billing period',
        conversionDeduction: 'Conversion deduction',
        income: 'Income',
        offerId: 'Offer ID',
        offerName: 'Offer name',
        benchmark: 'Benchmark',
        content: 'content',
        ecommerceCtr: 'ecommerceCtr',
        ecommerceCvr: 'ecommerceCvr',
        'lead gen': 'lead gen',
        noMore: 'Operation record description length cannot exceed 800 characters',
        noUndef: 'Operation record description cannot be empty',
        comparison1h: '1h Comparison',
        comparison24h: '24h Comparison',
        comparison1w: '1w Comparison',
        now: 'Now',
        optimizationType: 'Please set the algorithm optimization object. After setting, it cannot be changed',
        generateAnalysisReport: 'Generate Analysis Report',
        reportEmail: '(The report will be sent to your login email)',
        confirmSendingReport: 'Confirm Delivery Report',
        lineChart1: 'There’s no data to display in the table.',
        lineChart2: 'Please select an ad id and placement id to view the data.',
        searchPlacementID: 'Search Placement ID',
        sampleId: 'Sample ID',
        sampleName: 'Sample Name',
        bucketId: 'Bucket ID',
        description: 'Describe',
        conf: 'Configure',
        type: 'Type',
        flow: 'flow',
        correctFormat: 'Please enter the correct format, for example: 12-20.',
        noNumberLR: 'Left or right is not a number!',
        defaultHomePage: 'Default Home Page',
        homePage: 'Home Page',
        articlePage: 'Article Page',
        galleryPage: 'Gallery Page',
        videoPlayerPage: 'Video Player Page',
        kids: 'Kids',
        forGood: 'For Good',
        basePrice: 'Floor price, if not filled, it will default to 0, eg: 0.43',
        fixedIndex1: 'Fixed index: separated by commas, if not filled, the default index is incremented by 1, eg: 2, 3',
        'river-half': 'river-half',
        river: 'river',
        rivertall: 'rivertall',
        'river-dbl': 'river-dbl',
        infopane: 'infopane',
        flexaside: 'flexaside',
        copyDone: 'copy done',
        checkStatus1: 'CPC cap details',
        checkStatus2: 'Daily Spend',
        checkStatus3: 'Daily Cap',
        checkStatus4: 'Total Cost',
        checkStatus5: 'Total Cap',
        checkStatus6: 'Special campaigns cannot set hibernation',
        adStatus1: 'Ad is on',
        dormancy: 'Hibernation Status on｜off',
        recallStatus: 'Recallable Status',
        clickCapStatus: 'CPC Cap Status',
        impCapStatus: 'CPM Cap status',
        under: 'Budget not exhausted',
        full: 'Budget exhausted',
        timeStatus: 'Schedule Status',
        platoonPeriod: 'Within the schedule',
        outside: 'Out of schedule',
        uniformlyStatus: 'Standard Status',
        uniformlyStatus1: 'Consumed at standard rate, under control',
        campaignStatus: 'Campaign Status',
        allRejected: 'All Reject',
        allThrough: 'All Pass',
        'Approved+pendingPeview': 'Passed + Pending',
        'Pass+Reject': 'Pass + Reject',
        currentMediaPass: 'Current Media Pass',
        currentMediaPendingReview: 'Current Media Pending',
        currentMediaRejection: 'Current Media Rejection',
        totalAccountBalanceStatus: 'Total Account Balance Status',
        accountStatus: 'Account Status',
        auditPassSsps: 'Moderated Media',
        index: 'Index',
        newAdvertisingSpace: 'Add Ad Slot',
        json: 'The input box supports json.',
        json1: '(support multiple input)',
        json2: 'Please enter the correct JSON number',
        pidProfit1: 'Line ${i + 1} Pid Profit value cannot be greater than 1 or less than -1',
        beforeTheChange: 'Before Change',
        afterTheChange: 'After Change',
        modifier: 'Modifier',
        modifiedTime: 'Change Time',
        newURL: 'New Link',
        taboolaLP: 'Taboola LP',
        pageId: 'Page Id',
        creationDate: 'Creation Date',
        product: 'Product',
        onLp: 'Whether to Open LP Retention Popup',
        onBanner: 'Whether to Open Fixed Right Banner',
        addNewProduct: 'Add New Product',
        editProuduct: 'Edit Prouduct',
        deleteProduct: 'Delete Product',
        addNewDomain: 'Add New Domain',
        editDomain: 'Edit Domain',
        domainId: 'Domain Id',
        favicon: 'Favicon',
        operations: 'Operations',
        tips: 'Recommended Size: 90*90',
        tips1: 'Uploaded images can only be in JPG / JPEG / PNG format!',
        tips2: 'Upload image size cannot exceed 2MB!',
        enterDomainName: 'Please enter a domain',
        enterValidDomainName: 'Please enter a valid domain',
        pleaseUploadPictures: 'Please upload a image',
        pleaseEnterFooter: 'Please upload Footer',
        emailTemplate1: 'after the order',
        emailTemplate2: 'and',
        emailTemplate3: 'the order',
        domain: 'Please select a domain name.',
        emailAddress: 'Please enter an email.',
        emailAddress1: 'Please input the correct email.',
        enterPassword: 'Please enter your email password.',
        templateName: 'Please enter an email template name.',
        time: 'Please select a time period.',
        storeLogo: 'Please upload your store logo.',
        storeName: 'Please enter store name.',
        emailTitle: 'Please enter email title.',
        emailContent: 'Please enter the message body.',
        emailImage: 'Please upload an email poster.',
        footer: 'Please enter email footer.',
        time1: 'Please select a time.',
        lastUpdateDate: 'Last Update Date',
        selectAccountName: 'Select Account Name',
        selectProductName: 'Select Product Name',
        selectCampaignName: 'Select Campaign Name',
        onlineDate: 'Online Date',
        order: 'Order',
        adSpendBudget: 'Ad Spend Budget',
        adSpend: 'Ad Spend',
        atv: 'ATV',
        enterLandingPage: 'Enter Landing Page',
        enterOfferPage: 'Enter Offer Page',
        impCost: 'Imp Cost',
        merchandise: 'Merchandise',
        numberOfProduct: 'Number Of Product',
        numberOfOrders: 'Number Of Orders',
        logisticFee: 'Logistic Fee',
        productPrice: 'Product Price',
        startPurchase: 'Start Purchase',
        avgCTR: 'Avg.CTR',
        vimpCost: 'vimpCost',
        prodcutName: 'Prodcut Name',
        totalAmount: 'Total Amount',
        disputeAmount: 'Dispute Amount',
        disputeRate: 'Dispute Rate',
        disputeRate1: 'Dispute Rate (by dispute date)',
        disputeRate2: 'Dispute Rate (by charge date)',
        paymentCount: 'Payment Count',
        disputeCount: 'Dispute Count',
        disputeCount1: 'Duplicate Dispute Count',
        disputeCount2: 'Fraudulent Dispute Count',
        disputeCount3: 'General Dispute Count',
        disputeCount4: 'Credit Not Processed Dispute Count',
        notRecCount: 'Product Not Received Count',
        productUnacceptableCount: 'Product Unacceptable Count',
        subscriptionCanceledDisputeCount: 'Subscription Canceled Count',
        unrecognizedDisputeCount: 'Unrecognized Count',
        notReceivedDisputeAmount: 'Not Received Dispute Amount',
        notAsDescribedDisputeAmount: 'Not As Described Dispute Amount',
        unauthorizedDisputeAmount: 'Unauthorized Dispute Amount',
        creditNotProcessedDisputeAmount: 'Credit Not Processed Dispute Amount',
        duplicateTransactionDisputeAmount: 'Duplicate Transaction Dispute Amount',
        incorrectAmountDisputeAmount: 'Incorrect Amount Dispute Amount',
        paymentByOtherMeansDisputeAmount: 'Payment By Other Means Dispute Amount',
        canceledRecurringBillingDisputeAmount: 'Canceled Recurring Billing Dispute Amount',
        problemWithRemittanceAmount: 'Problem With Remittance Amount',
        otherDisputeAmount: 'Other Dispute Amount',
        fraudAmount: 'Fraud Amount',
        fraudRate: 'Fraud Rate',
        // 需要翻译
        comparison1d: '1d对比',
        comparison7d: '7d对比',
        comparisons: '周期对比'
    },
    serviceRecord: {
        tracker: 'Tracker',
        backgroundInformation: 'Background Information',
        edit: 'Edit',
        accountName: 'Account Name',
        accountHoldersEmail: 'Account Holder\'s Email',
        accountTimeZone: 'Account Time Zone',
        // country: 'Country',
        country: 'Location',
        industryCategory: 'Industry Category',
        accountManager: 'Account Manager',
        businessManager: 'Business Manager',
        coreTarget: 'Core Target',
        launchProduct: 'Launch Product',
        communicationChannel: 'Communication Channel',
        otherInformation: 'Other Information',
        serviceRecord: 'Service Record',
        serviceType: 'Service Type',
        deliveryStatus: 'Delivery Status',
        meetingCommunication: 'Meeting/Communication',
        changeOfManager: 'Change Of Manager',
        other: 'Other',
        timeline: 'Timeline',
        operationRecordDescription: 'Operation Record Description',
        add: 'Add',
        config: 'The configuration of the account dimension only takes effect for new campaigns. The whitelist of the old campaign remains unchanged.',
        whitelist: 'Whitelist',
        individualChoice: 'Individual Choice',
        supportbatch: 'Support batch writing, please use "";"" to separate.',
        percentageOfMedia: 'Percentage Of Media',
        pleaseenter: 'Please enter 1-99, the number is the percentage of campaign clicks in the media (unit: %)',
        clientid: 'Client id:',
        clientSecret: 'Client Secret:',
        accountid: 'Account id:',
        added1: 'Added:',
        profitMarginOperation: 'Profit Margin Operation',
        profitMargin: 'Profit Margin',
        replenishmentday: 'Replenishment day-level loss ceiling',
        topUptotallosscap: 'Top-up total loss cap',
        trainingOperations: 'Training Operations',
        speedUpTrainingMultiple: 'Speed Up Training Multiple',
        retrainingthreshold: 'Retraining threshold',
        cVROptimization: 'CVR Optimization',
        switch: 'Switch'
    },
    tracker: {
        trackerCategory: 'Tracker Category',
        priority: 'Priority',
        status: 'Status',
        industryCategory: 'Industry Category',
        onlyShow: 'Only Show The Tracker You Are Responsible for',
        include: 'Include Historical Responsibility',
        afterchecking: 'After checking, you can only search for manager, can not search account.',
        newItem: 'New Item',
        ordinal: 'Ordinal',
        adAccount: 'Ad Account',
        updateTime: 'Update Time',
        accountManager: 'Account Manager',
        currentManager: 'Current Manager',
        creationTime: 'Creation Time',
        operate: 'Operate',
        update: 'Update',
        consumptionRiseAnalysis: 'Consumption Rise Analysis',
        consumptionFallAnalysis: 'Consumption Fall Analysis',
        lowBudget: 'Low Budget Consumption',
        bugFeedback: 'Bug Feedback',
        functionOptimization: 'Function Optimization Suggestions',
        material: 'Material Design Requirements',
        strategicSupport: 'Strategic Support',
        technicalSupport: 'Technical Support',
        conversionRelated: 'Conversion Related',
        other: 'Other',
        request: 'Request',
        callback: 'Callback',
        imp: 'Imp',
        vimp: 'Vimp',
        click: 'Click',
        revenue: 'Revenue',
        selectPublisher: 'Select Publisher',
        selectPage: 'Select Page',
        selectFormat: 'Select Format',
        selectAccount: 'Select Account',
        selectCampaign: 'Select Campaign',
        search: 'Search',
        reset: 'Reset',
        byPublisher: 'By Publisher',
        byPage: 'By Page',
        byFormat: 'By Format',
        byAccount: 'By Account',
        byCampaign: 'By Campaign',
        more: 'More',
        columns: 'Columns',
        publisherName: 'Publisher Name',
        page: 'Page',
        format: 'Format',
        accountName: 'Account Name',
        campaignName: 'Campaign Name',
        callbackRate: 'Callback Rate',
        winRate: 'Win Rate',
        viewableRate: 'Viewable Rate',
        iCTR: 'iCTR',
        vCTR: 'vCTR',
        callbackRateROC: 'Callback Rate ROC',
        winRateROC: 'Win Rate ROC',
        viewableRateROC: 'Viewable Rate ROC',
        vCTRROC: 'vCTR ROC',
        iCTRROC: 'iCTR ROC',
        cost: 'Cost',
        rOI: 'ROI',
        icpm: 'icpm',
        cRpm: 'cRpm',
        iRpm: 'iRpm',
        revenueROC: 'Revenue ROC',
        revenueDiff: 'Revenue Diff',
        requestDiff: 'Request Diff',
        callbackDiff: 'Callback Diff',
        impDiff: 'Imp Diff',
        vimpDiff: 'Vimp Diff',
        clickDiff: 'Click Diff',
        profit: 'Profit',
        profitDiff: 'Profit Diff',
        accountID: 'Account ID',
        campaignID: 'Campaign ID',
        accountNameData: 'Account Name (data)',
        conversion: 'Conversion',
        conversionDiff: 'Conversion Diff',
        conversionRate: 'Conversion Rate',
        conversionRateROC: 'Conversion Rate ROC',
        costPerConversion: 'Cost Per Conversion',
        costPerConversionDiff: 'Cost Per Conversion Diff',
        viewContent: 'View Content',
        appInstall: 'App Install',
        completeRegistration: 'Complete Registration',
        addToCar: 'Add To Cart',
        addPaymentInfo: 'Add Payment Info',
        startCheckout: 'Start Checkout',
        purchase: 'Purchase',
        addToWishlist: 'Add To Wishlist',
        lead: 'Lead',
        submissionDate: 'Asset Submission Date',
        reviewDate: 'Review Date',
        startDate: 'Start Date',
        endDate: 'End Date',
        geoEdgeAlert: 'GeoEdge Alert'
    },
    monthlyBill: {
        downloadTemplate: 'Download template',
        initData: 'Initial data',
        currentData: 'Current data',
        operationLog: 'Operation log/record',
        placeholderTip: 'Company Name/User Name to search',
        uploadErrorTip: 'Upload failed, please modify and upload again',
        uploadTip: 'Only editable partial uploads are supported',
        uploadTypeTip: 'Only csv format is supported',
        selectFlie: 'Select a document',
        uploadResult: 'Upload result',
        editTip: ' modified ',
        editFormTip: ' from ',
        editToTip: ' to ',
        remark: 'Remark',
        upload: 'Import',
        line: 'Line '
    },
    // AI-CreativeGo
    aiCreativeGo: {
        Japan: 'Japan',
        twd: '台湾',
        hk: '香港',
        my: '马来西亚',
        Japanese: 'Japanese',
        chinese: '简体中文',
        zhHant: '繁体中文',
        // title
        hearderTitle: 'Title Generation',
        contentTitle: 'AI Generated Title',
        // form-labels
        landingPage: 'Landing Page',
        location: 'Location',
        language: 'Language',
        sellingPoint: 'Selling Point',
        titleSample: 'Title Sample',
        audience: 'Audience',
        gender: 'Gender',
        age: 'Age',
        productName: 'Product Name',
        campaignCategory: 'Campaign Category',
        // btns
        createBtn: 'Generate Title',
        copyAllBtn: 'Copy All Titles',
        // tips
        landingPageNoneTip: 'Landing Page can not be empty',
        landingPageRuleTip: 'Landing Page input box does not support <>',
        languageNoneTip: 'Please select a language',
        sellingPointTip: 'Support batch writing, separated by ,',
        titleSampleTip: 'Please provide 3 titles for popIn Creative Maker to learn, 1 line for each title',
        copied: 'Copied',
        requestErrorTip: 'The page is busy, please try again later',
        all: 'All',
        // 目标受众-option:
        officeWorkers: 'Office Workers',
        Homemakers: 'Homemakers',
        elderlyPeople: 'Elderly people',
        sportsEnthusiasts: 'Sports enthusiasts',
        investors: 'Investors',
        entrepreneurs: 'Entrepreneurs',
        artistsAndDesigners: 'Artists and designers',
        technologyEnthusiasts: 'Technology enthusiasts',
        travelEnthusiasts: 'Travel enthusiasts',
        // gender-option:
        male: 'Male',
        female: 'Female',
        // age-option:
        ageRange1: 'Under 18',
        ageRange2: '18-24',
        ageRange3: '25-44',
        ageRange4: '45-64',
        ageRange5: '65-85',
        ageRange6: '85+',
        // campaignCategory-option
        // aiCreativeGo下所对应的日文较constants的更为准确
        autoselectronics: 'Autos & Electronics',
        business: 'Business',
        beautyFashion: 'Beauty and Fashion',
        entertainment: 'Entertainment',
        economyMoney: 'Economy and Money',
        election: 'Election',
        education: 'Education',
        finance: 'Finance',
        foodDrink: 'Food & Drink',
        homepage: 'Homepage',
        health: 'Health',
        homeLifestyle: 'Home and Lifestyle',
        loveMarriage: 'Love and Marriage',
        music: 'Music',
        movies: 'Movies',
        news: 'News',
        politicsSocial: 'Politics and Social',
        sports: 'Sports',
        travel: 'Travel',
        TV: 'TV',
        video: 'Video',
        weather: 'Weather'
    },
    ...state,
    ...country
};