<template>
    <div class="upload-wrap">
        <el-upload
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            class="msn-upload"
            :style="dragStyle"
            :multiple="multiple"
            :class="`${borderRadius} ${!beforeUploadFlag ? 'show-tip' : ''}`"
            :file-list="fileList"
            action=""
            :accept="accept"
            drag
            :show-file-list="false"
            :before-upload="handleBeforeUpload"
            :http-request="uploadFile"
        >
            <i :class="`msn-fa msn-fa-${action} msn-${action}-icon`"></i>
            <!-- <div class="el-upload__text upload-text">{{uploadText}}</div> -->
            <div
                v-show="progress > 0"
                slot="tip"
                class="el-upload__progress"
                :class="borderRadius"
            >
                <div
                    class="upload-progress"
                    :style="{width: `${progress}%`}"
                ></div>
            </div>
        </el-upload>
        <p class="tip-text">
            <span v-show="!imgSizeCheck && !isDisplay">
                {{ $t("msn.infos.fileSizeLimit") }}

            </span>
            <br>
            <span class="asset-info-tip">
                <slot name="maxSizeTip"></slot>
            </span>
        </p>
        <p v-if="isDisplay" class="tip-text danger">
            <slot name="danger"></slot>
        </p>
    </div>
</template>

<script>
import * as axios from 'axios';
import api from '@/constants/api';
export default {
    name: 'MsnUpload',
    props: {
        accept: {
            default: 'image/*'
        },
        action: {
            default: 'upload'
        },
        value: {
            default: () => []
        },
        dragStyle: {
            default: () => ({})
        },
        maxSize: {
            default: 0
        },
        borderRadius: {
            default: ''
        },
        beforeUpload: null,
        limitSize: {
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        },
        limitImageSize: {
            type: Array,
            // 上传图片的宽高尺寸限制w*h必须大于600*400[600, 400]
            default: () => {
                return [600, 400];
            }
        },
        type: {
            // display广告素材
            type: String,
            default: ''
        },
        displaySize: {
            type: Array,
            default: () => []
        },
        maxFileSize: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            progress: 0,
            // 上传之前的校验
            beforeUploadFlag: true,
            imgSizeCheck: true
        };
    },
    computed: {
        fileList: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        isDisplay() {
            // 是否为display固定尺寸上传素材
            return this.type === 'display';
        }
    },
    methods: {
        // 读取图片尺寸
        readFileSize(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = function (event) {
                    let txt = event.target.result;
                    let img = document.createElement('img');
                    img.src = txt;
                    img.onload = function () {
                        resolve({fileWith: img.width, fileHeight: img.height});
                        img = null;
                    };
                    img.onerror = function () {
                        reject('read error');
                        img = null;
                    };
                };
                reader.readAsDataURL(file);
            });
        },
        // 图片上传
        uploadFile(data) {
            if (!this.beforeUploadFlag && this.isDisplay) {
                return;
            }
            // 限制大小
            const isImgTooLarge = this.maxFileSize && data?.file?.size > this.maxFileSize;
            if(isImgTooLarge){
                return this.$message.warning(this.$t('msn.backendMsg.sizeTooLarge'));
            }
            let formData = new FormData();
            let fileBase = data;
            formData.append('image', data.file);
            if(this.isDisplay && this.displaySize) {
                const [width, height] = this.displaySize;
                formData.append('width', width);
                formData.append('height', height);
            }
            // 文件名
            let index = data.file.name.lastIndexOf('.');
            let adAssetName = data.file.name.slice(0, index);
            this.$emit('loadingStart');
            axios({
                method: 'post',
                url: api.uploadImg,
                data: formData,
                onUploadProgress: progressEvent => {
                    this.progress = (progressEvent.loaded / progressEvent.total * 100 | 0);
                }
            }).then(res => {
                const result = res.data;
                // 回调返回上传的文件
                if (result && result.code === 0) {
                    this.$message.success(this.$t('msn.requestMsg.uploadSuccess'));
                    this.$emit('getFileBase', fileBase);
                    let data = result.data;
                    data.adAssetName = adAssetName;
                    this.$emit('uploaded', data);
                    setTimeout(() => {
                        this.progress = 0;
                    }, 1000);
                } else if (result.code === 1) {
                    this.progress = 0;
                    this.getMsgError(isImgTooLarge, this.$t(result.msg));
                } else {
                    this.progress = 0;
                    this.getMsgError(isImgTooLarge, this.$t('msn.requestMsg.uploadFailed'));
                }
                this.$emit('loadingEnd');
            }).catch(err => {
                this.$emit('loadingEnd');
                this.progress = 0;
                this.getMsgError(isImgTooLarge, this.$t('msn.requestMsg.uploadFailed'));
            });
        },
        getMsgError(error, msg){
            if(error){
                this.$message.warning(this.$t('msn.backendMsg.sizeTooLarge'));
            }else{
                this.$message.warning(msg);
            }
        },
        // 图片上传之前
        async handleBeforeUpload(file) {
            this.beforeUploadFlag = true;
            let fileSize;
            try {
                fileSize = await this.readFileSize(file);
            } catch (err) {
                fileSize = null;
            }
            if (!fileSize) {
                return false;
            }
            let {fileWith, fileHeight} = fileSize;
            if (this.isDisplay && (fileWith < this.limitImageSize[0] || fileHeight < this.limitImageSize[1])) {
                // this.beforeUploadFlag = false;
                this.imgSizeCheck = false;
            }
            if (typeof this.beforeUpload === 'function') {
                this.beforeUploadFlag = await this.beforeUpload(file, fileSize);
                return this.beforeUploadFlag;
            }
            return true;
        }
    },
    watch: {
        fileList: {
            immediate: true,
            handler(val) {
                if (val && val.length === 0) {
                    this.progress = 0;
                }
            }
        }
    }
};
</script>

<style lang="scss">
.tip-text {
    width: 179px;
    color: #e6a23b;
    min-height: 30px;
    margin: 0;
    line-height: 15px;
    font-size: 12px;
    &.danger {
        color: #e92754;
    }
}
.asset-info-tip{
	color: #53575b80;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
}
.preview-container {
    &:hover {
        .msn-upload {
            border: none;
            height: 20px;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                #000 100%
            ) !important;
        }
    }
    .msn-upload {
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            #000 100%
        );
        background-repeat: no-repeat;
        transition: all 0.2s ease-in-out;
        &.show-tip {
            bottom: 30px;
        }
    }
}
.msn-upload {
    height: 100%;
    width: 100%;
    margin: auto;
    // border: 1.5px solid transparent;
    border: 1px dashed #BDC8D4;
    border-radius: 6px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    &:hover {
        border: 1.5px dashed #BDC8D4;
    }
    .el-upload {
        display: block !important;
        height: 100%;
    }
    .el-upload-dragger {
        background-color: rgba(238, 241, 244, 0.5);
        height: 100%;
        width: 100% !important;
        border: none !important;
        border-radius: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
    .upload-text {
        font-size: 13px !important;
        color: #bcc8d3 !important;
        font-weight: 500;
        line-height: 20px;
    }
    .msn-fa {
        display: inline-block;
        &.msn-fa-uploadadd{
            width: 24px;
            height: 24px;
        }
    }
    .msn-upload-icon {
        width: 25px;
        height: 17px;
        margin: 31px auto 32px;
    }
    .msn-reupload-icon {
        width: 14px;
        height: 10px;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-7px);
    }
    .el-upload__progress {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5px;
        width: 100%;
        border-radius: 0 0 3px 3px;
        overflow: hidden;
        .upload-progress {
            height: 100%;
            background: #2dd1ac;
        }
    }
}
</style>
