/*
 * @file msn.js
 * @author liushengxgi
 * @date 2020-02-10 10:58:04
 * ----
 * @last Modified by    fangsimin
 * @last Modified time  2020-03-22 16:06:56
 */
const BASE_URL = 'platform/api/';
export default {
    writeAccountList: `${BASE_URL}campaignManager/writeAccountList`, // 查询科协账号列表
    deleteAutoRecharge: `${BASE_URL}campaignManager/deleteAutoRecharge`, // 删除⾃动充值账户
    turnOffOnAutoRecharge: `${BASE_URL}campaignManager/turnOffOnAutoRecharge`, // 开启/关闭⾃动充值
    editAutoRechargeLimitAmount: `${BASE_URL}campaignManager/editAutoRechargeLimitAmount`, // 修改⾃动充值限额和⾦额
    getAutoRechargeInfo: `${BASE_URL}campaignManager/getAutoRechargeInfo`, // 获取自动充值的信息
    createAutoRecharge: `${BASE_URL}campaignManager/createStripeOrderAutoRecharge`, // 创建自动充值接口
    multidiDomainList: `${BASE_URL}campaign/multidimension/domainList.do`, // 获取domainList
    cridList: `${BASE_URL}campaign/multidimension/cridList.do`, // 获取cridList
    getOriginImage: `${BASE_URL}campaignManager/getImage`, // 获取图片
    uploadSingleImage: `${BASE_URL}campaignManager/uploadSingleImage`, // 裁剪图片上传
    uploadImg: `${BASE_URL}campaignManager/uploadImage`, // 图片上传
    campaignCreate: `${BASE_URL}campaignManager/campaignCreate`, // 创建campaign
    campaignDetail: `${BASE_URL}campaignManager/campaignDetail`, // 查看campaign
    campaignEdit: `${BASE_URL}campaignManager/campaignEdit`, // 编辑campaign
    campaignBulkEdit: `${BASE_URL}campaignManager/campaignBulkEdit`, // 批量编辑campaign
    campaignStatusInnerChange: `${BASE_URL}campaignManager/changeCampaignInnerStatus`, // 更新状态
    campaignStatusChange: `${BASE_URL}campaignManager/campaignStatusChange`, // ds更新状态
    campaignArchive: `${BASE_URL}campaignManager/campaignArchive`, // 更新状态
    campaignAssetArchive: `${BASE_URL}campaignManager/campaign/asset/archive`, // 更新状态
    campaignAssetCreate: `${BASE_URL}campaignManager/campaign/asset/create`, // 新增素材
    campaignAdTotal: `${BASE_URL}campaignManager/creative/assetNum`, // 当前campaign下ad数量
    adStatusChange: `${BASE_URL}campaignManager/adStatusChange`, // 变更Ad状态
    dashboardList: `${BASE_URL}campaign/list.do`, // 获取数据列表
    getSmartBidPermission: `${BASE_URL}campaignManager/getZeroThresholdStatus`, // 获取数据列表
    // new dashboard
    getUserConfig: `${BASE_URL}campaignManager/userManage/userConfig`, // get 获取 用户上次操作信息(时区、日期、设置列、过滤等)
    setUserConfig: `${BASE_URL}campaignManager/userManage/userConfig`, // post 更新 用户本次操作信息(时区、日期、设置列、过滤等)
    getAgentDashboardList: `${BASE_URL}campaignManager/dashboard/getAgentDashboardList`, // 获取agency列表
    getCountryList: `${BASE_URL}campaignManager/getCountryList`,
    dashboardSearchList: `${BASE_URL}campaignManager/dashboard/getAccountCampaignList`,
    dashboardAdList: `${BASE_URL}campaignManager/ads/dashboardList`,
    dashboardAccountList: `${BASE_URL}campaignManager/dashboard/getAccountDashboardList`, // accountList
    getAccountChartData: `${BASE_URL}campaignManager/dashboard/getAccountChartData`, // account chart
    adSummary: `${BASE_URL}campaignManager/ads/dashboardSummary`,
    getCampaignDashboardList: `${BASE_URL}campaignManager/dashboard/getCampaignDashboardList`, // campaign list
    campaignSummary: `${BASE_URL}campaignManager/dashboard/getCampaignChartData`,
    dashboardDomainList: `${BASE_URL}campaignManager/domain/dashboardList`,
    dashboardDomainSummary: `${BASE_URL}campaignManager/domain/dashboardSummary`,
    dashboardDimensionList: `${BASE_URL}campaignManager/dimension/dashboardList`,
    dashboardDimensionSummary: `${BASE_URL}campaignManager/dimension/dashboardSummary`,
    getReadChartData: `${BASE_URL}campaignManager/dashboard/getReadChartData`, // 获取Read分部图表信息
    getReadContentAnalyzeChartData: `${BASE_URL}campaignManager/dashboard/getReadContentAnalyzeChartData`,
    getReadCategoryChartData: `${BASE_URL}campaignManager/dashboard/getReadCategoryChartData`,
    getReadTimeChartData: `${BASE_URL}campaignManager/dashboard/getReadTimeChartData`,
    downloadReadContentAnalyzeChartData: `${BASE_URL}campaignManager/dashboard/downloadReadContentAnalyzeChartData`, // 导出Read内容分析图表信息
    getReadOutsideChartData: `${BASE_URL}campaignManager/dashboard/getReadOutsideChartData`,
    exportAdList: `${BASE_URL}campaignManager/ads/dashboardDownload`,
    exportAccountList: `${BASE_URL}campaignManager/dashboard/downloadAccountDashboardList`,
    exportCampaignList: `${BASE_URL}campaignManager/dashboard/downloadCampaignDashboardList`,
    exportPlatformList: `${BASE_URL}campaignManager/dimension/dashboardDownload`,
    exportDomainList: `${BASE_URL}campaignManager/domain/dashboardDownload`,
    checkCampaignSiteCpcMinRate: `${BASE_URL}campaignManager/checkCampaignSiteCpcMinRate`, // 校验sitecpc是否击穿底价
    getConversionUpdateTime: `${BASE_URL}campaignManager/getConversionUpdateTime`,
    checkCampaignCpc: `${BASE_URL}campaignManager/checkCampaignCpc`,
    assetBulkEdit: `${BASE_URL}campaignManager/assetBulkEdit`, // asset批量编辑
    assetBulkArchive: `${BASE_URL}campaignManager/assetBulkArchive`, // asset批量归档
    domainUpdateTime: `${BASE_URL}campaignManager/domain/getDomainUpdateTime`, // domain更新时间
    getInheritLimit: `${BASE_URL}campaignManager/getInheritLimit`, // 查询账户配额
    getBrandNameLanguageList: `${BASE_URL}campaignManager/brandNameLanguageList`, // 获取brandname审核结果

    // end
    adsList: `${BASE_URL}campaign/ads/list.do`, // 获取某个campain下ad数据列表
    domainList: `${BASE_URL}campaign/domain/list.do`, // 获取account或者campaign层级的domain数据
    downloadDomainList: `${BASE_URL}campaign/domain/download.do`, // 获取account或者campaign层级的domain数据
    domainSetCampaignCpc: `${BASE_URL}campaignManager/domainSetCampaignCpc`, // 屏蔽或恢复
    blockOrResume: `${BASE_URL}campaignManager/domainBlock`, // 屏蔽或恢复
    domainSummary: `${BASE_URL}campaign/domain/summary.do`, // domain图数据
    adsSummary: `${BASE_URL}campaign/ads/summary.do`, // 获取某个campain下ad数据列表
    getCampaigns: `${BASE_URL}campaign/all.do`, // 获取某个campain下ad数据列表
    adsListExport: `${BASE_URL}campaign/ads/download.do`, // 获取某个campain下ad数据列表
    dashboardSummary: `${BASE_URL}campaign/summary.do`, // 获取总体趋势数据
    multidimensionSummary: `${BASE_URL}campaign/multidimension/summary.do`, // 获取总体趋势数据
    dashboardExport: `${BASE_URL}campaign/download.do`, // 下载列表数据
    multidimensionExport: `${BASE_URL}campaign/multidimension/download.do`, // 下拉列表数据
    dashboardCampaign: `${BASE_URL}campaignManager/campaign/detail`, // 在操作面版快速编辑Campaign详情
    dashboardCreative: `${BASE_URL}campaignManager/creative/detail`, // 在操作面版快速编辑Creative详情
    dashboardEditCampaign: `${BASE_URL}campaignManager/campaignEdit`, // 在操作面版快速编辑Campaign
    dashboardEditCreative: `${BASE_URL}campaignManager/creative/update`, // 在操作面版快速编辑Creative
    dashboardCreateCreative: `${BASE_URL}campaignManager/creative/create`, // 在操作面版快速编辑Creative
    campaignBulkArchive: `${BASE_URL}campaignManager/campaignBulkArchive`, // 批量归档
    checkOldInheritCampaign: `${BASE_URL}campaignManager/checkOldInheritCampaign`, // 校验活动ID是否满足匹配条件
    // visualTest dashboard api
    testList: `${BASE_URL}campaign/amList.do`, // 获取列表数据
    testDownload: `${BASE_URL}campaign/fulldownload.do`, // 获取列表数据
    campaignOpLog: `${BASE_URL}campaignManager/campaignOpLog`, // 获取campaign操作日志
    campaignConfig: `${BASE_URL}campaignManager/campaignConfig`, // 获取campaign操作日志
    getTrackUrl: `${BASE_URL}campaignManager/getTrackUrl`, // 获取tracking url接口
    // 运营平台 - 待办事项
    issueCreate: `${BASE_URL}campaignManager/icafe/create`, // 创建待办事项
    issueList: `${BASE_URL}campaignManager/icafe/list`, // 创建待办事项
    getUserList: `${BASE_URL}campaignManager/icafe/userList`, // 创建待办事项
    getUserListByPage: `${BASE_URL}campaignManager/icafe/userListByPage`, // 获取用户分页列表
    simpleUpdate: `${BASE_URL}campaignManager/icafe/simpleUpdate`, // 变更待办事项状态
    progressUpdate: `${BASE_URL}campaignManager/icafe/progressUpdate`, // 变更待办事项责任人/进展
    updateIssue: `${BASE_URL}campaignManager/icafe/update`, // 待办事项编辑
    uploadImageIssue: `${BASE_URL}campaignManager/icafe/uploadImage`, // 图片上传
    uploadCampaignAssetFile: `${BASE_URL}campaignManager/uploadCampaignAssetFile`, // csv文件上传
    campaignCreateAssetFile: `${BASE_URL}campaignManager/campaignCreateAssetFile`, // 新增素材批量上传
    getUploadCampaignAssetFile: `${BASE_URL}campaignManager/getUploadCampaignAssetFile`, // 上传记录列表
    downloadCampaignAssetFile: `${BASE_URL}campaignManager/downloadCampaignAssetFile`, // 下载上传记录
    downloadTemplate: `${BASE_URL}campaignManager/templete/campaignAssetFile/download`, // csv模板
    downloadKwTargetingTemplate: `${BASE_URL}campaignManager/keywordRetargeting/downloadTemplate`, // 下载 kwTrageting csv模板
    uploadKwTargetingTemplate: `${BASE_URL}campaignManager/keywordRetargeting/uploadKeywordCsv`, // 上传kwTrageting csv模板
    getKwTargetingList: `${BASE_URL}campaignManager/keywordRetargeting/getKeywordLog`, // kwTargeting修改记录
    downloadCurKwTargetingCsv: `${BASE_URL}campaignManager/keywordRetargeting/downloadKeyword`, // 下载当前csv
    updateKwTargeting: `${BASE_URL}campaignManager/keywordRetargeting/update`, // 更新记录
    // amConfig
    getDiscoveryAmLogList: `${BASE_URL}campaignManager/getDiscoveryAmLogList`, // 获取Discovery的运营分析列表
    getCpcType: `${BASE_URL}campaignManager/getCpcType`, // 获取目标cpa记录
    setCpcType: `${BASE_URL}campaignManager/checkCpcType`, // 操作目标cpa记录
    getCampaignSspList: `${BASE_URL}campaignManager/blackWhiteConfig/getCampaignSspList`, // 获取在投ssp列表
    // monthBill
    getMonthList: `${BASE_URL}campaignManager/monthList`, // 获取月份接口
    getMonthlyBillingData: `${BASE_URL}campaignManager/getMonthlyBillingData`, // 获取列表数据接口
    downloadMonthlyBillingData: `${BASE_URL}campaignManager/downloadMonthlyBillingData`, // 列表下载接口
    // 运营平台 - 操作
    multidimensionList: `${BASE_URL}campaign/multidimension/list.do`, // 列表数据
    serveBgInfo: `${BASE_URL}campaignManager/serveRecordPage/backgroundInfo`, // 背景信息
    serveRecord: `${BASE_URL}campaignManager/serveRecordPage/serveRecord`, // 服务记录
    amDashboardExport: `${BASE_URL}campaign/amDownload.do`, // 运营分析 导出
    amYueBao: `${BASE_URL}campaignManager/operation/monthlyReport/list`, // 运营月报
    amYueBaoDetail: `${BASE_URL}campaignManager/operation/monthlyReport/detail`, // 运营月报
    taboolaOpt: `${BASE_URL}campaignManager/taboolaAccountMapping`, // get: 根据id查看绑定taboola情况, post: 修改
    accountSetCampaignCpc: `${BASE_URL}campaignManager/accountSetCampaignCpc`, // 运营端设定最低cpc
    getCurrencyCpc: `${BASE_URL}campaignManager/campaign/getCurrencyCpc`, // 获取各个国家的最低cpc
    getAccountIsUpdate: `${BASE_URL}campaignManager/getAccountIsUpdate`, // 获取各个国家的最低cpc是否修改过
    getCpcLimitSettingRecord: `${BASE_URL}campaignManager/getCpcLimitSettingRecord`, // 操作最低cpc历史记录
    amMessageCreate: `${BASE_URL}campaignManager/innerMessage/create`, // 创建消息
    amMessageClose: `${BASE_URL}campaignManager/innerMessage/close`, // 关闭消息
    amMessageList: `${BASE_URL}campaignManager/innerMessage/list`, // 消息list
    amMessageUserList: `${BASE_URL}campaignManager/innerMessage/userMsgList`, // user消息list
    amMessageDetail: `${BASE_URL}campaignManager/innerMessage/detail`, // user消息list detail
    amMessageStatusChange: `${BASE_URL}campaignManager/innerMessage/statusChange`, // user消息list 已读
    amMessageRoleList: `${BASE_URL}campaignManager/innerMessage/roleList`, // user 消息通知 角色列表

    // dataReport api
    getAccountList: `${BASE_URL}campaign/report/accountList.do`, // 获取授权账户list
    getCampaignListByAccount: `${BASE_URL}campaign/report/campaignList.do`, // 获取指定账户的campaign的list
    getAdListByCampaign: `${BASE_URL}campaign/report/adList.do`, // 获取指定的campaign的ad的list
    getReportList: `${BASE_URL}campaign/report/list.do`, // 获取广告明细list
    getReportSummary: `${BASE_URL}campaign/report/summary.do`, // 获取广告图形面板所有数据
    downloadReportList: `${BASE_URL}campaign/report/download.do`, // 获取广告明细list
    getChart: `${BASE_URL}campaign/report/getChart.do`, // 获取图的数据
    getAuditTagGroup: 'media/api/publisherManager/tag/getAuditTagGroup', // tagList
    uploaddomainwhitelist: `${BASE_URL}campaignManager/uploaddomainwhitelist`, // mobile domin白名单提交文件
    dbAdAuditDetail: `${BASE_URL}campaignManager/campaignAuditDetail`, // 服务端权限校验问题，需要重新定义一个接口
    auditList: `${BASE_URL}campaignManager/auditList`, // 广告审核-列表数据
    auditListByTidb: `${BASE_URL}campaignManager/auditListByTidb`, // 广告审核-列表数据 通过TiDB获取
    adList: `${BASE_URL}campaign/multidimension/adList.do`, // adidsList
    adAuditDetail: `${BASE_URL}campaignManager/adAuditDetail`, // 广告审核-详情数据
    adAudit: `${BASE_URL}campaignManager/adAudit`, // 广告审核-审核
    auditLog: `${BASE_URL}campaignManager/getAuditLog`, // 广告审核-审核记录
    auditSensitiveTag: `${BASE_URL}campaignManager/sensitiveTagList`, // 广告审核-获取敏感标签选项
    batchAuditList: `${BASE_URL}campaignManager/batchAuditList`, // 批量审核列表
    batchAuditListByTidb: `${BASE_URL}campaignManager/batchAuditListByTidb`, // 批量审核列表
    batchAudit: `${BASE_URL}campaignManager/batchAudit`, // 批量审核
    campaignCreateDetail: `${BASE_URL}campaignManager/campaignCreateDetail`, // 广告审核-修改审核
    getMenu: `${BASE_URL}campaignManager/menu`, // 获取菜单接口
    logout: `${BASE_URL}campaignManager/logout`, // 登出
    login: `${BASE_URL}campaignManager/login`, // login
    register: `${BASE_URL}campaignManager/register`, // register
    fastRegister: `${BASE_URL}campaignManager/fastRegister`, // fastRegister
    modifyPwd: `${BASE_URL}campaignManager/modifyPwd`, // modifyPwd
    forgetPassword: `${BASE_URL}campaignManager/forgetPwd`, // 忘记密码(新)
    getAdvertiser: `${BASE_URL}campaignManager/getAdvertiser`, // 获取当前用户下的广告主列表
    adCreativeDetail: `${BASE_URL}campaignManager/adCreativeDetail`, // 广告AdDemo测试-获取对应adid的一系列信息
    getCampaignData: `${BASE_URL}campaignManager/getCampaignData`, // 广告AdDemo测试-获取对应adid的点击和展示数据
    getCampaignStatus: `${BASE_URL}campaignManager/getCampaignStatus`, // 广告AdDemo测试-获取对应adid或模糊搜索adName的一些召回状态
    getZipCodeList: `${BASE_URL}campaignManager/zipcodeList`, // 获取zipcode列表
    uploadZipcode: `${BASE_URL}campaignManager/uploadZipcode`, // 上传zipcode csv文件
    zipcodeTemplateDownload: `${BASE_URL}campaignManager/templete/zipcode/download`, // 下载csv模版
    // credit line
    downDomainTemplete: `${BASE_URL}campaignManager/templete/domianwhitelist/download`, // 下载csv模板
    creditLineList: `${BASE_URL}campaignManager/creditLineList`,
    creditLineDownload: `${BASE_URL}campaignManager/creditLineList/download`,
    recharge: `${BASE_URL}campaignManager/recharge`,
    rechargeRecord: `${BASE_URL}campaignManager/rechargeRecord`,
    setCreditLine: `${BASE_URL}campaignManager/setCreditLine`,
    creditLineSettingRecord: `${BASE_URL}campaignManager/creditLineSettingRecord`,
    accountStatusChange: `${BASE_URL}campaignManager/accountStatusChange`,
    modifyCreditLineNote: `${BASE_URL}campaignManager/modifyCreditLineNote`, // 修改credit line备注
    modifyRechargeNote: `${BASE_URL}campaignManager/modifyRechargeNote`, // 修改充值备注
    modifyCouponNote: `${BASE_URL}campaignManager/modifyCouponNote`, // 修改coupon备注
    addCoupon: `${BASE_URL}campaignManager/addCoupon`, // 账户增加coupon
    couponRecord: `${BASE_URL}campaignManager/couponRecord`, // 该账户相关的coupon信息
    offlineCoupon: `${BASE_URL}campaignManager/offlineCoupon`, // 下线coupon
    setProfitRate: `${BASE_URL}campaignManager/setProfitRatio`, // 设置利润率
    profitRateSettingRecord: `${BASE_URL}campaignManager/profitRatioSettingRecord`, // 查看利润率修改记录
    changeCurrency: `${BASE_URL}campaignManager/creditLineList/changeCurrency`, // 换汇
    // accountInfo: `${BASE_URL}campaignManager/accountInfo`, // 用户包列表
    offlineDown: `${BASE_URL}campaignManager/dashboardList/download`, // export
    offlineDashboardList: `${BASE_URL}campaignManager/dashboardList`, // offlineTabelData
    getAdvertiserName: `${BASE_URL}campaignManager/getAdvertiserName`, // AdvertiserNameList
    getAdvertiserNameByPage: `${BASE_URL}campaignManager/getAdvertiserNameByPage`, // AdvertiserNameList by page
    getAffiliateName: `${BASE_URL}campaignManager/getAffiliateName`, // AffiliateNameList
    getAffiliateNameByPage: `${BASE_URL}campaignManager/getAffiliateNameByPage`, // AffiliateNameList by page
    downloaddomainwhitelist: `${BASE_URL}campaignManager/downloaddomainwhitelist`,
    receivableMonthList: `${BASE_URL}campaignManager/receivableBilling/monthList`, // 获取月份列表
    receivableBillingList: `${BASE_URL}campaignManager/receivableBilling/list`, // 获取列表数据
    availableMonth: `${BASE_URL}campaignManager/receivableBilling/availableMonth`, // 获取available month
    receivableBilling: `${BASE_URL}campaignManager/receivableBilling/detail`, // 根据available month获取变动金额接口
    adjustmentAmountLog: `${BASE_URL}campaignManager/receivableBilling/adjustmentLog`, // log页面接口
    newAdjustmentAmount: `${BASE_URL}campaignManager/receivableBilling/newAdjustmentAmount`, // 修改变动金额接口
    receivableBillingListDownload: `${BASE_URL}campaignManager/receivableBilling/listDownload`, // 导出应收款列表接口
    adjustmentLogDownload: `${BASE_URL}campaignManager/receivableBilling/adjustmentLogDownload`, // 导出log接口
    // roi
    roiList: `${BASE_URL}campaign/roi/list.do`,
    roiDownload: `${BASE_URL}campaign/roi/download.do`,
    roiIncome: `${BASE_URL}campaign/roi/income.do`,
    roiPay: `${BASE_URL}campaign/roi/pay.do`,
    roiIncomeDownload: `${BASE_URL}campaign/roi/incomeDownload.do`,
    roiPayDownload: `${BASE_URL}campaign/roi/payDownload.do`,
    // login form
    applyInfo: `${BASE_URL}campaign/account/apply.do`,
    applyList: `${BASE_URL}campaign/account/list.do`,
    getApplyInfo: `${BASE_URL}campaign/account/get.do`, // 根据用户id查询是否已经填写过信息
    // 账户余额以及告警账户信息接口
    accountInfo: `${BASE_URL}campaignManager/accountInfo`,
    // 专属链接开户申请
    openAccount: `${BASE_URL}campaignManager/accountManage/apply`,
    getTokenInfo: `${BASE_URL}campaignManager/accountManage/tokenInfo`,
    bdOpenAccount: `${BASE_URL}campaignManager/assistRegister`, // BD开户链接
    /* 权限模块 --start */
    accountList: `${BASE_URL}campaignManager/accountManage/accountList`, // 账户列表
    bmList: `${BASE_URL}campaignManager/accountManage/bmList`, // BM列表
    bmListByPage: `${BASE_URL}campaignManager/accountManage/bmListByPage`, // BM分页列表
    accountListDownload: `${BASE_URL}campaignManager/accountManage/accountList/download`, // 账户列表导出
    company: `${BASE_URL}campaignManager/accountManage/company`, // 添加/编辑公司
    account: `${BASE_URL}campaignManager/accountManage/account`, // 添加/编辑账户
    generateAccountApiToken: `${BASE_URL}campaignManager/accountManage/generateAccountApiToken`, // 为账户生成ApiToken
    checkGenerateAccountApiToken: `${BASE_URL}campaignManager/accountManage/checkGenerateAccountApiToken`, // 校验账户是否符合创建ApiToken的条件
    accountApiToken: `${BASE_URL}campaignManager/accountManage/accountApiToken`, // 获取账户对应的ApiToken
    accountDetail: `${BASE_URL}campaignManager/accountManage/accountDetail`, // 账户详情
    companyDetail: `${BASE_URL}campaignManager/accountManage/companyDetail`, // 公司详情
    optionList: `${BASE_URL}campaignManager/accountManage/list`, // 公司/账户列表
    optionListByPage: `${BASE_URL}campaignManager/accountManage/listByPage`, // 公司/账户分页列表
    changeUserStatus: `${BASE_URL}campaignManager/userManage/changeUserStatus`, // 开启/禁用账户
    unauthorizedUserList: `${BASE_URL}campaignManager/userManage/unauthorizedUserList`, // 未授权用户列表
    ignoreUser: `${BASE_URL}campaignManager/userManage/ignoreUser`, // 忽略某个用户
    grant: `${BASE_URL}campaignManager/userManage/userGrant`, // 授权
    authorizedUserList: `${BASE_URL}campaignManager/userManage/authorizedUserList`, // 已授权用户列表
    userDetail: `${BASE_URL}campaignManager/userManage/userDetail`, // 用户详情
    getAccountSourceList: `${BASE_URL}campaignManager/accountManage/accountSourceList`, // 用户详情
    getAccountSourceListByPage: `${BASE_URL}campaignManager/accountManage/accountSourceListByPage`, // 客户来源分页
    getRoleList: `${BASE_URL}campaignManager/accountManage/role/list`, // 用户详情
    roleAction: `${BASE_URL}campaignManager/accountManage/role/action`, // 用户详情
    reportInfo: `${BASE_URL}campaign/reportconfig/edit.do`, // 获取发送报告信息
    saveReport: `${BASE_URL}campaign/reportconfig/save.do`, // 保存发送报告信息
    // 代理模块
    agentAccountList: `${BASE_URL}campaignManager/agentManage/agentAccountList`, // 代理账户列表
    getAgentListSelect: `${BASE_URL}campaignManager/agentManage/getAgentListSelect`, // 代理列表
    agentAddAccount: `${BASE_URL}campaignManager/agentManage/agentAddAccount`,
    getCompanyListSelect: `${BASE_URL}campaignManager/agentManage/getCompanyListSelect`, // 代理公司列表
    agentGetListByPage: `${BASE_URL}campaignManager/agentManage/agentGetListByPage`,
    agentGetUserList: `${BASE_URL}campaignManager/agentManage/getUserList`, // 代理用户列表
    isCompanyExist: `${BASE_URL}campaignManager/agentManage/isCompanyExist`, // 查询公司名是否存在
    agentUserAdd: `${BASE_URL}campaignManager/agentManage/userAdd`,
    agentUserEdit: `${BASE_URL}campaignManager/agentManage/userEdit`,
    agentUserDetail: `${BASE_URL}campaignManager/agentManage/userDetail`,
    accountGetCampaignCpcMin: `${BASE_URL}campaignManager/accountGetCampaignCpcMin`, // 获取最小cpc
    agentGetRoleDetail: `${BASE_URL}campaignManager/agentManage/getRoleDetail`, // role列表
    agentAccountStatusChange: `${BASE_URL}campaignManager/agentManage/accountStatusChange`, // 修改帐户状态
    changeAgentUserStatus: `${BASE_URL}campaignManager/agentManage/changeUserStatus`, // 修改用户状态
    accountSetCampaignCpcAgency: `${BASE_URL}campaignManager/accountSetCampaignCpcAgency`, // 代理店提交cpc接口
    /* 权限模块 --end */
    // pixel模块
    pixelList: `${BASE_URL}campaignManager/pixel/list`, // pixel列表
    pixelStatusChange: `${BASE_URL}campaignManager/pixel/statusChange`, // pixel更新状态
    pixelCreate: `${BASE_URL}campaignManager/pixel/create`, // pixel创建
    readCreate: `${BASE_URL}campaignManager/pixel/readCreate`, // READ创建
    pixelUpdate: `${BASE_URL}campaignManager/pixel/update`, // pixel更新
    pixelDetail: `${BASE_URL}campaignManager/pixel/detail`, // pixel详情
    conversionsList: `${BASE_URL}campaignManager/pixel/conversionsList`, // conversions列表
    initpixel: `${BASE_URL}campaignManager/pixel/initpixel`, // conversions列表
    pixelAccountListSelect: `${BASE_URL}campaignManager/pixel/accountListSelect`, // conversion mediago account列表
    pixelConversionsAccountListSelect: `${BASE_URL}campaignManager/pixel/conversionsAccountListSelect`, // conversion discovery account列表
    pixelConversionCreate: `${BASE_URL}campaignManager/pixel/conversionCreate`, // conversion discovery account列表
    pixelConversionsStatusChange: `${BASE_URL}campaignManager/pixel/conversionsStatusChange`, // conversion status
    pixelGetPixelCount: `${BASE_URL}campaignManager/pixel/getPixelCount`, // 根据accountId查询已配置事件信息
    getPixelLinkCount: `${BASE_URL}campaignManager/pixel/initpixel/count`, // 根据accountId查询已配置的domian信息
    addInitpixel: `${BASE_URL}campaignManager/pixel/initpixel`, // 添加pixel
    pixelConversionsCompanyListSelect: `${BASE_URL}campaignManager/pixel/companyList`, // 添加pixel

    // pixel模块 readpixel部分
    readList: `${BASE_URL}campaignManager/pixel/readList`, // READ列表
    readDetail: `${BASE_URL}campaignManager/pixel/readDetail`, // READ详情
    readStatusChange: `${BASE_URL}campaignManager/pixel/readStatusChange`, // READ更改状态
    discoveryReadCreate: `${BASE_URL}campaignManager/pixel/discoveryReadCreate`, // READ创建(D的READ)
    readAccountListSelect: `${BASE_URL}campaignManager/pixel/readAccountListSelect`, // READ可用账户列表
    sspList: `${BASE_URL}campaignManager/sspList`, // ssp列表
    setBlackWhiteList: `${BASE_URL}campaignManager/setBlackWhiteList`, // 设置黑白名单
    blackWhiteList: `${BASE_URL}campaignManager/blackWhiteList`, // 根据campaignId查询黑白名单
    setBlackPageIDFilter: `${BASE_URL}campaignManager/setBlackPageIDFilter`, // 设置pageId block
    getBlackPageIDFilter: `${BASE_URL}campaignManager/getBlackPageIDFilter`, // 根据campaignId查询pageId block
    getGroupList: `${BASE_URL}campaignManager/blackWhiteConfig/getGroupList`, // 媒体站点组select列表
    getMediaAccountList: `${BASE_URL}campaignManager/blackWhiteConfig/getMediaAccountList`, // 媒体账户select列表
    setAccountBlackWhiteConfig: `${BASE_URL}campaignManager/blackWhiteConfig/setAccountBlackWhiteConfig`, // 设置账户层级黑白名单
    setCampaignBlackWhiteConfig: `${BASE_URL}campaignManager/blackWhiteConfig/setCampaignBlackWhiteConfig`, // 设置广告活动层级黑白名单
    getBlackWhiteConfig: `${BASE_URL}campaignManager/blackWhiteConfig/getBlackWhiteConfig`, // 获取已设置的黑白名单
    getBlackWhiteConfigLog: `${BASE_URL}campaignManager/blackWhiteConfig/getBlackWhiteConfigLog`, // 获取操作记录日志
    // 充值模块
    paymentList: `${BASE_URL}campaignManager/paymentList`, // 充值查询
    createOrder: `${BASE_URL}campaignManager/createOrder`, // 创建订单
    captureOrder: `${BASE_URL}campaignManager/captureOrder`, // 捕获订单，发起扣款
    cancelOrder: `${BASE_URL}campaignManager/cancelOrder`, // 取消订单
    creditLineExport: `${BASE_URL}campaignManager/creditLine/download`, // 导出creditLine数据
    creditLineAuth: `${BASE_URL}campaignManager/creditLineAuth`, // 判断是否有coupon/creditLine
    rechargeCreditLineList: `${BASE_URL}campaignManager/rechargeCreditLineList`, // 充值列表数据
    createStripeOrder: `${BASE_URL}campaignManager/createStripeOrder`, // 单次支付接口
    cancelStripeOrder: `${BASE_URL}campaignManager/cancelStripeOrder`, // 取消支付接口
    // 初始化各个下拉框
    initSelect: `${BASE_URL}campaign/report/initSelect.do`,
    // 帮助中心相关
    qaCreate: `${BASE_URL}campaignManager/helpCenter/create`, // 创建新问题 post ?path,title,html
    normalQuery: `${BASE_URL}campaignManager/helpCenter/normal`, // 对外无权限判断处理
    qaList: `${BASE_URL}campaignManager/helpCenter`, // 查询问题列表
    qaEdit: `${BASE_URL}campaignManager/helpCenter/edit`, // 编辑问题
    qaDelete: `${BASE_URL}campaignManager/helpCenter`, // 删除问题 delete ?id
    qaImageUpload: `${BASE_URL}campaignManager/helpCenter/uploadImage`,
    // POPIN帮助中心相关
    qaDCreate: `${BASE_URL}campaignManager/popinHelpCenter/create`, // 创建新问题 post ?path,title,html
    normalDQuery: `${BASE_URL}campaignManager/popinHelpCenter/normal`, // 对外无权限判断处理
    qaDList: `${BASE_URL}campaignManager/popinHelpCenter`, // 查询问题列表
    qaDEdit: `${BASE_URL}campaignManager/popinHelpCenter/edit`, // 编辑问题
    qaDDelete: `${BASE_URL}campaignManager/popinHelpCenter`, // 删除问题 delete ?id
    qaDImageUpload: `${BASE_URL}campaignManager/popinHelpCenter/uploadImage`,
    // 数据诊断相关
    diagnosisTotalCompare: `${BASE_URL}campaign/diagnosis/totalCompare.do`, // 数据图形比较
    diagnosisList: `${BASE_URL}campaign/diagnosis/list.do`, // 表格数据
    accountSourceList: `${BASE_URL}campaign/report/accountSourceList.do`, // 客户数据来源
    diagnosisLatestDate: `${BASE_URL}campaign/diagnosis/latestDate.do`, // 数据最新更新时间
    diagnosisGetReport: `${BASE_URL}campaignManager/analysisReport`, // 自动生成报告接口
    // 抽样
    getSampleInfoList: `${BASE_URL}campaignManager/getSampleInfoList`, // 获取抽样实验列表
    sampleAction: `${BASE_URL}campaignManager/sampleInfoAction`, // 添加/编辑实验
    // 新注册
    sendEmailCheck: `${BASE_URL}campaignManager/sendEmailCheck`, // 发送邮箱验证码
    quickRegister: `${BASE_URL}campaignManager/quickRegister`, // 快速开户注册接口
    // ecpm 竞价分析
    bidAnalysisList: `${BASE_URL}campaign/ecpm/list.do`, // 获取列表
    bidAnalysisLatestDate: `${BASE_URL}campaign/ecpm/latestDate.do`, // 数据最新更新时间
    bidAnalysisSummary: `${BASE_URL}campaign/ecpm/summary.do`, // 数据最新更新时间
    // 广告位出价配置
    getPageBidList: `${BASE_URL}campaignManager/getPageBidList`, // 获取广告位列表信息
    // TODO:批量审批--url地址
    batchApproval: `${BASE_URL}`, // 批量审批
    pageBidAction: `${BASE_URL}campaignManager/pageBidAction`, // 创建或编辑广告位
    getPageBidAuditInfo: `${BASE_URL}campaignManager/getPageBidAuditInfo`, // 获取审核详情数据
    pageBidAudit: `${BASE_URL}campaignManager/auditPageBid`, // 审核
    // 可视化测试
    getNewLocalPage: `${BASE_URL}campaignManager/taboolaLandingPageGenerate`, // 生成落地页新地址
    adminEmailCode: `${BASE_URL}campaignManager/getTestEmailCode`, // 【开户验证码】测试环境在addemo页面添加获取验证码的功能
    // 电商工具
    ecommerceOPCreate: `${BASE_URL}ecommerce/op/create`, // op 创建
    ecommerceOPEdit: `${BASE_URL}ecommerce/op/edit`, // op 修改
    ecommerceOPList: `${BASE_URL}ecommerce/op/list`, // op 列表获取
    ecommerceOPDelete: `${BASE_URL}ecommerce/op`, // op 删除
    ecommerceLPList: `${BASE_URL}ecommerce/lp/list`, // 获取LP 列表
    ecommerceLPParse: `${BASE_URL}ecommerce/lp/parse`, // 解析 LP
    ecommerceLPPreview: `${BASE_URL}ecommerce/lp/preview`, // 预览 LP
    ecommerceLPCreate: `${BASE_URL}ecommerce/lp/create`, // 创建发布
    ecommerceLPDetail: `${BASE_URL}ecommerce/lp/detail`, // 详情
    commerceLPEdit: `${BASE_URL}ecommerce/lp/edit`, // 编辑
    ecommerceImageUpload: `${BASE_URL}ecommerce/creative/upload`,
    ecommerceOption: `${BASE_URL}ecommerce/option`,
    ecommerceLPDelete: `${BASE_URL}`,
    ecommerceProductDelete: `${BASE_URL}ecommerce/product/delete`, // 删除eProduct,
    ecommerceDelete: `${BASE_URL}ecommerce/lp`, // 删除LP，
    // roi报表接口
    ROIReport: `${BASE_URL}ecommerce/report`,
    // 差异率表格接口
    PaymentManagementList: `${BASE_URL}ecommerce/payment/list`,
    // 差异率选项接口
    paymentOption: `${BASE_URL}ecommerce/payment/option`,
    // 差异率导出
    paymentDownload: `${BASE_URL}ecommerce/payment/list/download`,
    // roi报表合计接口
    ecommercesummary: `${BASE_URL}ecommerce/report/ecommercesummary`,
    getProductList: `${BASE_URL}ecommerce/product/list`, // 获取产品列表
    reNameProduct: `${BASE_URL}ecommerce/product/update`, // 产品重命名
    addProduct: `${BASE_URL}ecommerce/product/create`, // 新增产品
    deleteProduct: `${BASE_URL}ecommerce/product/delete`, // 删除产品
    getDomainList: `${BASE_URL}ecommerce/domain/list`, // 获取域名列表
    addDomain: `${BASE_URL}ecommerce/domain`, // 新增产品
    updateDomain: `${BASE_URL}ecommerce/domain/update`, // 编辑域名
    getOptions: `${BASE_URL}ecommerce/option`, // 获取有权限的账户列表
    // 客户管理
    getTemplateList: `${BASE_URL}ecommerce/customer/template/list`, // 获取邮件模板列表
    getTemplateDetail: `${BASE_URL}ecommerce/customer/template/detail`, // 获取邮件模板详情
    createTemplate: `${BASE_URL}ecommerce/customer/template/create`, // 新增邮件模板
    editTemplate: `${BASE_URL}ecommerce/customer/template/edit`, // 编辑邮件模板
    editTemplateStatus: `${BASE_URL}ecommerce/customer/template/status/edit`, // 邮件模板开关
    deleteTemplate: `${BASE_URL}ecommerce/customer/template`, // 删除邮件模板,
    // 运营分析图表
    amSummary: `${BASE_URL}/campaign/amSummary.do`,

    // 消息中心
    getMessage: `${BASE_URL}campaignManager/innerMessage/messages`, // 获取未读消息i
    getMessageDetail: `${BASE_URL}campaignManager/innerMessage/detail`, // 获取详情
    getMessageList: `${BASE_URL}campaignManager/innerMessage/pages/messages`, // 获取分页
    messageStatusChange: `${BASE_URL}campaignManager/innerMessage/statusChange`, // 消息已读
    getMessagePages: `${BASE_URL}campaignManager/innerMessage/pages/messages`, // 消息分页

    // 新增月账单
    companySave: `${BASE_URL}campaignManager/monthlyFinanceBilling/companySave`, // 保存
    companyList: `${BASE_URL}campaignManager/monthlyFinanceBilling/companyList`, // companyList纬度
    monthlyFinanceBillingList: `${BASE_URL}campaignManager/monthlyFinanceBilling/accountList`, // 月账单列表
    companySubmit: `${BASE_URL}campaignManager/monthlyFinanceBilling/companySubmit`, // 月账单发布
    monthList: `${BASE_URL}campaignManager/monthlyFinanceBilling/monthList`, // 月账单时间获取
    monthCompanyExport: `${BASE_URL}campaignManager/monthlyFinanceBilling/companyExport`, // 公司纬度导出
    monthAccountExport: `${BASE_URL}campaignManager/monthlyFinanceBilling/accountExport`, // 账户纬度导出
    campaignList: `${BASE_URL}campaignManager/monthlyFinanceBilling/campaignList`, // 广告纬度
    monthlyAccountExport: `${BASE_URL}campaignManager/monthlyFinanceBilling/accountExport`, // 导出
    campaignExport: `${BASE_URL}campaignManager/monthlyFinanceBilling/campaignExport`, // 导出
    assetList: `${BASE_URL}campaignManager/monthlyFinanceBilling/assetList`, // ad纬度
    assetExport: `${BASE_URL}campaignManager/monthlyFinanceBilling/assetExport`, // 导出
    operationLog: `${BASE_URL}campaignManager/monthlyFinanceBilling/operationCompanyLog`, // 月账单操作记录
    companySaveByFile: `${BASE_URL}campaignManager/monthlyFinanceBilling/companySaveByFile`, // 批量导入月账单
    companySaveTemplate: `${BASE_URL}campaignManager/monthlyFinanceBilling/companySaveTemplate`, // 导入模版下载
    companyOriginExport: `${BASE_URL}campaignManager/monthlyFinanceBilling/companyOriginExport`, // 原始数据导出

    // aiCreativeGo
    aiGetAdTitle: `${BASE_URL}campaignManager/ai/getAdTitle`,

    // 前端通用错误上报
    reportError: `${BASE_URL}campaignManager/frontEndErrorRecord`

};